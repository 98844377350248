import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import AddReservation from '@jumbo/components/Reservation/ReservationAdd';
import ShowReservations from '@jumbo/components/Reservation/ShowReservation';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import AdminOperations from '../@jumbo/components/AdminPanel/AdminOperations';
import ForgotPasswordPage from './Auth/ForgotPassword';
import WizardPage from '../@jumbo/components/Contract/WizardPage';
import Dashboard from '../@jumbo/components/Contract/DashboardLoading';
import Markup from '../@jumbo/components/Contract/Markup';
import Master from '../@jumbo/components/MasterPanel/index';
import AllotmentUpdate from '../@jumbo/components/RoomActions/AllotmentUpdate';
import AllotmentList from '../@jumbo/components/RoomActions/AllotmentList';
import { PrivateRoute } from './Auth/PrivateRoute';
import OpenStopSale from '../@jumbo/components/RoomActions/OpenStopSale';
import LoginChooseCompany from '../@jumbo/components/Common/authComponents/ChooseCompany';
import DefinitionsLoading from '@jumbo/components/Contract/DefinitionsLoading';
import CancellationPoliciesDetail from '@jumbo/components/Contract/CancellationPoliciesDetail';
import ReservationReport from '@jumbo/components/Reports/ReservationReport';
import ContractReport from '@jumbo/components/Reports/ContractReport';
import HotelReport from '@jumbo/components/Reports/HotelReport';
import TermsConditions from '@jumbo/components/Contract/TermsConditions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Email from '@jumbo/components/Help/Email';
import SSS from '@jumbo/components/Contract/SSS';
import Profile from '@jumbo/components/Profile/Profile';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import { AuhMethods } from '../services/auth';
import CalculateReservation from '@jumbo/components/Reservation/CalculateReservation';

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: '',
    text: text,
  });
};

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { location } = rest;
  let { pathname } = location;
  let isAuth = '';
  let route_id = 0;

  const pageName = pathname.split('/').filter(i => i !== '');

  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  const { routes } = useSelector(({ routes }) => routes);

  if (pathname.startsWith('/salemanagement') || pathname === '/definitions') {
    pathname = '/product';
  }

  if (permissionsByAuthUser.length > 0 && routes.length > 0) {
    route_id = routes.findIndex(i => pathname.startsWith('/' + i.route_name));
    isAuth = permissionsByAuthUser.some(
      permission => (routes[route_id]?.id == permission?.route_id && permission?.get === 1) || permission?.get === true,
    );
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuth == true ? (
          <Component {...props} />
        ) : isAuth !== '' ? ( //isauth false olma durumunda
          (sweetAlerts(
            'warning',
            pageName[0][0].toUpperCase() + pageName[0].substring(1) + ' sayfalarına erişim yetkiniz bulununmamaktadır!',
          ),
          (<Redirect to="/dashboard" />))
        ) : (
          //isauth false ya da true dışında bir değere sahip olması durumunda
          <div></div>
        )
      }
    />
  );
};

const Routes = () => {
  const dispatch = useDispatch();

  /*  window.onbeforeunload = function() {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    localStorage.clear();
  }; */

  const { authUser } = useSelector(({ auth }) => auth);
  const { selectedCompany } = useSelector(({ auth }) => auth);

  const history = useHistory();

  const location = useLocation();

  if (
    /* !selectedCompany */ !localStorage.getItem('selectedCompany') &&
    authUser?.authority_level > 10 &&
    //authUser?.authority_level <= 30 &&
    authUser?.authority_level < 30 &&
    location.pathname === '/login'
  ) {
    return <Redirect to="/choosecompany" />;
  }
  if (
    !localStorage.getItem('selectedOffice') &&
    !localStorage.getItem('selectedCompany') &&
    authUser?.authority_level == 30 &&
    location.pathname === '/login'
  ) {
    return <Redirect to="/choosecompany" />;
  }

  if ((authUser && authUser?.authority_level !== 0 && location.pathname === '/login') || location.pathname === '/') {
    return <Redirect to="/dashboard" />;
  }

  if (
    (!authUser || authUser.authority_level === 0) &&
    location.pathname !== '/login' &&
    !location.pathname.includes('/master')
  ) {
    return <Redirect to="/login" />;
  }

  if (
    authUser &&
    !localStorage.getItem('selectedCompany') &&
    authUser?.authority_level > 10 &&
    //authUser?.authority_level <= 30 &&
    authUser?.authority_level < 30 &&
    location.pathname !== '/choosecompany'
  ) {
    //!selectedCompany
    return <Redirect to="/choosecompany" />;
  }

  if (
    !localStorage.getItem('selectedOffice') &&
    !localStorage.getItem('selectedCompany') &&
    authUser?.authority_level == 30 &&
    location.pathname !== '/choosecompany'
  ) {
    //!selectedCompany
    return <Redirect to="/choosecompany" />;
  }

  if (
    (authUser && authUser?.authority_level !== 0 && location.pathname === '/reservation') ||
    location.pathname === '/reservation/'
  ) {
    return <Redirect to="/reservation/show" />;
  }

  function ReservationFunc({ component: Component, ...rest }) {
    let { reservationId } = useParams();
    const { location } = rest;
    const { pathname } = location;
    const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
    let isAuthGet = '';
    let isAuthAdd = '';

    if (permissionsByAuthUser.length > 0) {
      isAuthGet = permissionsByAuthUser.some(permission => 4 == permission.route_id && permission.get === 1);
      isAuthAdd = permissionsByAuthUser.some(permission => 4 == permission.route_id && permission.post === 1);
    }

    if (isAuthAdd == true && authUser && authUser?.authority_level !== 0 && reservationId === 'create') {
      return <AddReservation />;
    }
    if (isAuthGet == true && authUser && authUser?.authority_level !== 0 && reservationId === 'show') {
      return <ShowReservations />;
    }
    if (isAuthGet == true && authUser && authUser?.authority_level !== 0 && reservationId === 'calculate') {
      return <CalculateReservation />;
    } else if (isAuthGet !== '' || isAuthAdd !== '') {
      sweetAlerts('warning', 'Rezervasyonda yapmak istediğiniz işlem için yetkiniz bulununmamaktadır!');
      return <Redirect to="/dashboard" />;
    } else return <div></div>;
  }

  if (
    (authUser && authUser?.authority_level !== 0 && location.pathname === '/salemanagement') ||
    location.pathname === '/salemanagement/'
  ) {
    return <Redirect to="/salemanagement/allotmentList" />;
  }

  function SaleManagementFunc() {
    let { saleMId } = useParams();
    if (authUser && authUser?.authority_level !== 0 && saleMId === 'allotmentUpdate') {
      return <AllotmentUpdate />;
    }
    if (authUser && authUser?.authority_level !== 0 && saleMId === 'openstopsale') {
      return <OpenStopSale />;
    }
    if (authUser && authUser?.authority_level !== 0 && saleMId === 'allotmentList') {
      return <AllotmentList />;
    } else {
      sweetAlerts('warning', 'Aradığınız sayfa bulunamadı!');
      return <Redirect to="/dashboard" />;
    }
  }

  if (
    (authUser && authUser?.authority_level !== 0 && location.pathname === '/contract') ||
    location.pathname === '/contract/'
  ) {
    return <Redirect to="/contract/newcontract" />;
  }

  function ContractFunc() {
    let { contractId } = useParams();
    if (authUser && authUser?.authority_level !== 0 && contractId === 'newcontract') {
      return <WizardPage />;
    }
    if (authUser && authUser?.authority_level !== 0 && contractId === 'markup') {
      return <Markup />;
    }
    if (authUser && authUser?.authority_level !== 0 && contractId === 'cancellationpolicies') {
      return <CancellationPoliciesDetail />;
    }
    if (authUser && authUser?.authority_level !== 0 && contractId === 'termsConditions') {
      return <TermsConditions />;
    } else {
      sweetAlerts('warning', 'Aradığınız sayfa bulunamadı!');
      return <Redirect to="/dashboard" />;
    }
  }
  const onLogoutClick = () => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    return <Redirect from="/logout" to="/login" />;
  };
  return (
    <Switch>
      <RestrictedRoute path="/definitions" component={DefinitionsLoading} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/reservation/:reservationId" component={ReservationFunc} />
      {/* <PrivateRoute path="/reservation/show" component={ShowReservations} />
      <PrivateRoute path="/reservation/create" component={AddReservation} /> */}
      <RestrictedRoute path="/salemanagement/:saleMId" component={SaleManagementFunc} />
      {/* <PrivateRoute path="/allotmentUpdate" component={AllotmentUpdate} /> 
       <PrivateRoute path="/openstopsale" component={OpenStopSale} /> 
       <PrivateRoute path="/allotmentList" component={AllotmentList} /> */}
      {/*  <PrivateRoute path="/userPermission" component={UserPermission} /> */}
      <RestrictedRoute path="/contract/:contractId" component={ContractFunc} />
      {/* <RestrictedRoute path="/newcontract" component={WizardPage} />
      <PrivateRoute path="/markup" component={Markup} />
      <PrivateRoute path="/cancellationpolicies" component={CancellationPoliciesDetail} />
      <PrivateRoute path="/termsConditions" component={TermsConditions} /> */}
      <PrivateRoute path="/signup" component={Register} />
      <PrivateRoute path="/forgot-password" component={ForgotPasswordPage} />
      <PrivateRoute path="/admin" component={AdminOperations} />
      <PrivateRoute path="/reservationReport" component={ReservationReport} />
      <PrivateRoute path="/contractReport" component={ContractReport} />
      <PrivateRoute path="/hotelReport" component={HotelReport} />
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute
        path="/whatsappsupport"
        component={() => {
          window.open('https://wa.me/+908507243375');
          return <Redirect to="/dashboard" />;
        }}
      />
      <Route path="/sendmail" component={Email} />
      <Route path="/sss" component={SSS} />
      <Route path="/choosecompany" component={LoginChooseCompany} />
      <Route path="/master" component={Master} />
      <Route path="/login" component={Login} />
      <Route from="/logout" component={onLogoutClick} />

      <Route component={Error404} />
      <Redirect from="*" to="/dashboard" />
    </Switch>
  );
};

export default Routes;
