import {
  GET_CANCELLATION_POLICIES,
  ADD_CANCELLATION_POLICIES,
  UPDATE_CANCELLATION_POLICIES,
  DELETE_CANCELLATION_POLICIES,
  ADD_CANCELLATION_POLICIES_DETAIL,
  UPDATE_CANCELLATION_POLICIES_DETAIL,
  DELETE_CANCELLATION_POLICIES_DETAIL,
  GET_CONTRACT_CANCELLATION_POLICIES,
  ADD_CONTRACT_CANCELLATION_POLICIES,
  UPDATE_CONTRACT_CANCELLATION_POLICIES,
  DELETE_CONTRACT_CANCELLATION_POLICIES,
} from './types';

//Cancellation Policies
export const getCancellationPolicies = res => dispatch => {
  dispatch({ type: GET_CANCELLATION_POLICIES, payload: res.data.data });
};

export const addCancellationPolicies = res => dispatch => {
  dispatch({ type: ADD_CANCELLATION_POLICIES, payload: res.data.data });
};

export const updateCancellationPolicies = res => dispatch => {
  dispatch({ type: UPDATE_CANCELLATION_POLICIES, payload: res.data.data });
};

export const deleteCancellationPolicies = res => dispatch => {
  dispatch({ type: DELETE_CANCELLATION_POLICIES, payload: res.data.data });
};

//Cancellation Policis Detail
export const addCancellationPoliciesDetail = res => dispatch =>
  dispatch({
    type: ADD_CANCELLATION_POLICIES_DETAIL,
    payload: res.data.data,
  });

export const updateCancellationPoliciesDetail = res => dispatch =>
  dispatch({
    type: UPDATE_CANCELLATION_POLICIES_DETAIL,
    payload: res.data.data,
  });

export const deleteCancellationPoliciesDetail = res => dispatch =>
  dispatch({
    type: DELETE_CANCELLATION_POLICIES_DETAIL,
    payload: res.data.data,
  });

//Contract Cancellation Policies
export const getContractCancellationPolicies = res => dispatch => {
  dispatch({ type: GET_CONTRACT_CANCELLATION_POLICIES, payload: res.data.data });
};

export const addContractCancellationPolicies = res => dispatch =>
  dispatch({
    type: ADD_CONTRACT_CANCELLATION_POLICIES,
    payload: res.data.data,
  });

export const updateContractCancellationPolicies = res => dispatch =>
  dispatch({
    type: UPDATE_CONTRACT_CANCELLATION_POLICIES,
    payload: res.data.data,
  });

export const deleteContractCancellationPolicies = res => dispatch =>
  dispatch({
    type: DELETE_CONTRACT_CANCELLATION_POLICIES,
    payload: res.data.data,
  });
