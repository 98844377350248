import React from 'react';
import { ArrowForward, Dashboard, People, LibraryBooks, AccountCircle, Edit, Settings, HomeWork } from '@material-ui/icons';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import IntlMessages from '../../../utils/IntlMessages';
import DescriptionIcon from '@mui/icons-material/Description';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import Swal from 'sweetalert2';

const logoutFunc = () => {
  Swal.fire({
    title: 'Are you sure you want to log out?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes!',
    cancelButtonText: 'No!',
    reverseButtons: true,
  }).then(result => {
    if (result.isConfirmed) {
      localStorage.removeItem('token');
      localStorage.removeItem('selectedCompany');
      localStorage.removeItem('userCompanies');
      localStorage.removeItem('selectedOffice');
      localStorage.removeItem('userOffices');
      return (window.location = '/login');
    }
  });
};
export const sidebarNavs = [
  {
    name: <IntlMessages id="profile" />,
    icon: <PersonIcon />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="profile" />,
        type: 'item',
        icon: <PersonIcon />,
        link: '/profile',
      },
      {
        name: <IntlMessages id="logout" />,
        type: 'item',
        icon: <LogoutIcon />,
        link: '/logout',
        onClickFunc: logoutFunc,
      },
    ],
  },

  {
    name: <IntlMessages id="admin.operations" />,
    icon: <People />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="admin.operations" />,
        type: 'item',
        icon: <People />,
        link: '/admin',
      },
    ],
  },
  {
    name: <IntlMessages id="dashboard" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="dashboard" />,
        type: 'item',
        icon: <Dashboard />,
        link: '/dashboard',
      },
    ],
  },
  {
    name: <IntlMessages id="product" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="definitions" />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/definitions',
      },
      {
        name: <IntlMessages id="sale.management" />,
        type: 'collapse',
        icon: <Settings />,
        children: [
          {
            name: <IntlMessages id="allotment.update" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/salemanagement/allotmentUpdate',
          },
          {
            name: <IntlMessages id="open.stop.sale" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/salemanagement/openstopsale',
          },
          {
            name: <IntlMessages id="allotment.list" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/salemanagement/allotmentList',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="contract" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="contract" />,
        type: 'collapse',
        icon: <Edit />,
        children: [
          {
            name: <IntlMessages id="new.contract" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/newcontract',
          },
          {
            name: <IntlMessages id="markup.management" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/markup',
          },
          {
            name: <IntlMessages id="cancellation.policies" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/cancellationpolicies',
          },
          {
            name: <IntlMessages id="terms.and.conditions" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/termsConditions',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="reservations" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="reservation.operations" />,
        type: 'collapse',
        icon: <LocalLibraryIcon />,
        children: [
          {
            name: <IntlMessages id="new.reservation" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservation/create',
          },
          {
            name: <IntlMessages id="show.reservation" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservation/show',
          },
          {
            name: <IntlMessages id="calculate.reservation" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservation/calculate',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="reports.operations" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="reports.operations" />,
        type: 'collapse',
        icon: <DescriptionIcon />,
        children: [
          {
            name: <IntlMessages id="reservation.report" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservationReport',
          },
          {
            name: <IntlMessages id="contract.report" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contractReport',
          },
          {
            name: <IntlMessages id="hotel.report" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/hotelReport',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="help" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="help" />,
        type: 'collapse',
        icon: <SupportAgentIcon />,
        children: [
          {
            name: <IntlMessages id="whatsapp.support" />,
            type: 'item',
            icon: <WhatsAppIcon />,
            link: '/whatsappsupport',
          },
          {
            name: <IntlMessages id="email" />,
            type: 'item',
            icon: <LocalPostOfficeIcon />,
            link: '/sendmail',
          },
          {
            name: <IntlMessages id="sss" />,
            type: 'item',
            icon: <QuestionMarkIcon />,
            link: '/sss',
          },
        ],
      },
    ],
  },
];

export const sidebarNavsOfficeUser = [
  {
    name: <IntlMessages id="profile" />,
    icon: <PersonIcon />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="profile" />,
        type: 'item',
        icon: <PersonIcon />,
        link: '/profile',
      },
      {
        name: <IntlMessages id="logout" />,
        type: 'item',
        icon: <LogoutIcon />,
        link: '/logout',
        onClickFunc: logoutFunc,
      },
    ],
  },
  {
    name: <IntlMessages id="dashboard" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="dashboard" />,
        type: 'item',
        icon: <Dashboard />,
        link: '/dashboard',
      },
    ],
  },
  {
    name: <IntlMessages id="product" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="definitions" />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/definitions',
      },
      {
        name: <IntlMessages id="sale.management" />,
        type: 'collapse',
        icon: <Settings />,
        children: [
          {
            name: <IntlMessages id="allotment.update" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/salemanagement/allotmentUpdate',
          },
          {
            name: <IntlMessages id="open.stop.sale" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/salemanagement/openstopsale',
          },
          {
            name: <IntlMessages id="allotment.list" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/salemanagement/allotmentList',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="contract" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="contract" />,
        type: 'collapse',
        icon: <Edit />,
        children: [
          {
            name: <IntlMessages id="new.contract" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/newcontract',
          },
          {
            name: <IntlMessages id="markup.management" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/markup',
          },
          {
            name: <IntlMessages id="cancellation.policies" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/cancellationpolicies',
          },
          {
            name: <IntlMessages id="terms.and.conditions" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/termsConditions',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="reservations" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="reservation.operations" />,
        type: 'collapse',
        icon: <LocalLibraryIcon />,
        children: [
          {
            name: <IntlMessages id="new.reservation" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservation/create',
          },
          {
            name: <IntlMessages id="show.reservation" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservation/show',
          },
          {
            name: <IntlMessages id="calculate.reservation" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservation/calculate',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="Reports" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="reports.operations" />,
        type: 'collapse',
        icon: <DescriptionIcon />,
        children: [
          {
            name: <IntlMessages id="reservation.report" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservationReport',
          },
          {
            name: <IntlMessages id="contract.report" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contractReport',
          },
          {
            name: <IntlMessages id="hotel.report" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/hotelReport',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="bot.operations" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="bot.operations" />,
        type: 'collapse',
        icon: <SettingsEthernetIcon />,
        children: [
          {
            name: <IntlMessages id="operator" />,
            type: 'item',
            icon: <BackupTableIcon />,
            link: '/operatorcredential',
          },
          {
            name: <IntlMessages id="hotel.match" />,
            type: 'item',
            icon: <LocationCityIcon />,
            link: '/hotelmatch',
          },
          {
            name: <IntlMessages id="room.match" />,
            type: 'item',
            icon: <LocalLibraryIcon />,
            link: '/roommatch',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="help" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="help" />,
        type: 'collapse',
        icon: <SupportAgentIcon />,
        children: [
          {
            name: <IntlMessages id="whatsapp.support" />,
            type: 'item',
            icon: <WhatsAppIcon />,
            link: '/whatsappsupport',
          },
          {
            name: <IntlMessages id="email" />,
            type: 'item',
            icon: <LocalPostOfficeIcon />,
            link: '/sendmail',
          },
          {
            name: <IntlMessages id="SSS" />,
            type: 'item',
            icon: <QuestionMarkIcon />,
            link: '/sss',
          },
        ],
      },
    ],
  },
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id="profile" />,
    icon: <PersonIcon />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="profile" />,
        type: 'item',
        icon: <PersonIcon />,
        link: '/profile',
      },
      {
        name: <IntlMessages id="logout" />,
        type: 'item',
        icon: <LogoutIcon />,
        link: '/logout',
        onClickFunc: logoutFunc,
      },
    ],
  },
  {
    name: <IntlMessages id="admin.operations" />,
    icon: <People />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="admin.operations" />,
        type: 'item',
        icon: <People />,
        link: '/admin',
      },
    ],
  },
  {
    name: <IntlMessages id="dashboard" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="dashboard" />,
        type: 'item',
        icon: <Dashboard />,
        link: '/dashboard',
      },
    ],
  },
  {
    name: <IntlMessages id="product" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="definitions" />,
        type: 'collapse',
        icon: <LibraryBooks />,
        link: '/definitions',
      },
      {
        name: <IntlMessages id="sale.management" />,
        type: 'collapse',
        icon: <Settings />,
        children: [
          {
            name: <IntlMessages id="allotment.update" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/salemanagement/allotmentUpdate',
          },
          {
            name: <IntlMessages id="open.stop.sale" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/salemanagement/openstopsale',
          },
          {
            name: <IntlMessages id="allotment.list" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/salemanagement/allotmentList',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="contract" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="contract" />,
        type: 'collapse',
        icon: <Edit />,
        children: [
          {
            name: <IntlMessages id="new.contract" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/newcontract',
          },
          {
            name: <IntlMessages id="markup.management" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/markup',
          },
          {
            name: <IntlMessages id="cancellation.policies" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/cancellationpolicies',
          },
          {
            name: <IntlMessages id="terms.and.conditions" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/termsConditions',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="reservations" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="reservation.operations" />,
        type: 'collapse',
        icon: <LocalLibraryIcon />,
        children: [
          {
            name: <IntlMessages id="new.reservation" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservation/create',
          },
          {
            name: <IntlMessages id="show.reservation" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservation/show',
          },
          {
            name: <IntlMessages id="calculate.reservation" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservation/calculate',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="Reports" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="reports.operations" />,
        type: 'collapse',
        icon: <DescriptionIcon />,
        children: [
          {
            name: <IntlMessages id="reservation.report" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservationReport',
          },
          {
            name: <IntlMessages id="contract.report" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contractReport',
          },
          {
            name: <IntlMessages id="hotel.report" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/hotelReport',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="bot.operations" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="bot.operations" />,
        type: 'collapse',
        icon: <SettingsEthernetIcon />,
        children: [
          {
            name: <IntlMessages id="operator" />,
            type: 'item',
            icon: <BackupTableIcon />,
            link: '/operatorcredential',
          },
          {
            name: <IntlMessages id="hotel.match" />,
            type: 'item',
            icon: <LocationCityIcon />,
            link: '/hotelmatch',
          },
          {
            name: <IntlMessages id="room.match" />,
            type: 'collapse',
            icon: <MeetingRoomIcon />,
            link: '/roommatch',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="help" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="help" />,
        type: 'collapse',
        icon: <SupportAgentIcon />,
        children: [
          {
            name: <IntlMessages id="whatsapp.support" />,
            type: 'item',
            icon: <WhatsAppIcon />,
            link: '/whatsappsupport',
          },
          {
            name: <IntlMessages id="email" />,
            type: 'item',
            icon: <LocalPostOfficeIcon />,
            link: '/sendmail',
          },
          {
            name: <IntlMessages id="SSS" />,
            type: 'item',
            icon: <QuestionMarkIcon />,
            link: '/sss',
          },
        ],
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id="profile" />,
    icon: <PersonIcon />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="profile" />,
        type: 'item',
        icon: <PersonIcon />,
        link: '/profile',
      },
      {
        name: <IntlMessages id="logout" />,
        type: 'item',
        icon: <LogoutIcon />,
        link: '/logout',
        onClickFunc: logoutFunc,
      },
    ],
  },
  {
    name: <IntlMessages id="admin.operations" />,
    icon: <People />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="admin.operations" />,
        type: 'collapse',
        icon: <People />,
        children: [
          {
            name: <IntlMessages id="new.company" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/dashboard',
          },
          {
            name: <IntlMessages id="new.office" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/dashboard',
          },
          {
            name: <IntlMessages id="new.user" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/dashboard',
          },
          {
            name: <IntlMessages id="new.operator" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/dashboard',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="dashboard" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="dashboard" />,
        type: 'item',
        icon: <Dashboard />,
        link: '/dashboard',
      },
    ],
  },
  {
    name: <IntlMessages id="product" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="definitions" />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/definitions',
      },
      {
        name: <IntlMessages id="sale.management" />,
        type: 'collapse',
        icon: <Settings />,
        children: [
          {
            name: <IntlMessages id="allotment.update" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/salemanagement/allotmentUpdate',
          },
          {
            name: <IntlMessages id="open.stop.sale" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/salemanagement/openstopsale',
          },
          {
            name: <IntlMessages id="allotment.list" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/salemanagement/allotmentList',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="contract" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="contract" />,
        type: 'collapse',
        icon: <Edit />,
        children: [
          {
            name: <IntlMessages id="new.contract" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/newcontract',
          },
          {
            name: <IntlMessages id="markup.management" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/markup',
          },
          {
            name: <IntlMessages id="cancellation.policies" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/cancellationpolicies',
          },
          {
            name: <IntlMessages id="terms.and.conditions" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contract/termsConditions',
          },
        ],
      },
    ],
  },

  {
    name: <IntlMessages id="reservations" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="reservation.operations" />,
        type: 'collapse',
        icon: <LocalLibraryIcon />,
        children: [
          {
            name: <IntlMessages id="new.reservation" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservation/create',
          },
          {
            name: <IntlMessages id="show.reservation" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservation/show',
          },
          {
            name: <IntlMessages id="calculate.reservation" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservation/calculate',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="Reports" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="reports.operations" />,
        type: 'collapse',
        icon: <DescriptionIcon />,
        children: [
          {
            name: <IntlMessages id="reservation.report" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reservationReport',
          },
          {
            name: <IntlMessages id="contract.report" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/contractReport',
          },
          {
            name: <IntlMessages id="hotel.report" />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/hotelReport',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="bot.operations" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="bot.operations" />,
        type: 'collapse',
        icon: <Edit />,
        children: [
          {
            name: <IntlMessages id="operator" />,
            type: 'item',
            icon: <BackupTableIcon />,
            link: '/operatorcredential',
          },
          {
            name: <IntlMessages id="hotel.match" />,
            type: 'item',
            icon: <LibraryBooks />,
            link: '/hotelmatch',
          },
          {
            name: <IntlMessages id="room.match" />,
            type: 'item',
            icon: <MeetingRoomIcon />,
            link: '/roommatch',
          },
        ],
      },
    ],
  },
  {
    name: <IntlMessages id="help" />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="help" />,
        type: 'collapse',
        icon: <SupportAgentIcon />,
        children: [
          {
            name: <IntlMessages id="whatsapp.support" />,
            type: 'item',
            icon: <WhatsAppIcon />,
            link: '/whatsappsupport',
          },
          {
            name: <IntlMessages id="email" />,
            type: 'item',
            icon: <LocalPostOfficeIcon />,
            link: '/sendmail',
          },
          {
            name: <IntlMessages id="SSS" />,
            type: 'item',
            icon: <QuestionMarkIcon />,
            link: '/sss',
          },
        ],
      },
    ],
  },
];
export const masterSidebarNavs = [
  {
    name: <IntlMessages id="profile" />,
    icon: <PersonIcon />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="logout" />,
        type: 'item',
        icon: <LogoutIcon />,
        link: '/master/logout',
        onClickFunc: logoutFunc,
      },
    ],
  },
  {
    name: <IntlMessages id="master.operations" />,
    icon: <People />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id="company" />,
        type: 'item',
        icon: <HomeWork />,
        link: '/master/company',
      },
      {
        name: <IntlMessages id="users" />,
        type: 'item',
        icon: <People />,
        link: '/master/user',
      },
      {
        name: <IntlMessages id="logger" />,
        type: 'item',
        icon: <SettingsIcon />,
        link: '/master/logger',
      },
    ],
  },
];
