import sendLogService from '../Logger.js';

//SEND USER INFO FROM LOGGER API
export function auth(user) {
  sendLogService.constructor(user);
}

//SEND USER INFO FOR LOGIN
export function authLogin(data) {
  sendLogService.sendLogin(data);
}

//SEND USER INFO FOR LOGOUT
export function authLogout(data, user) {
  sendLogService.sendLogout(data, user);
}
