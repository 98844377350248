import React, { useState, useEffect, lazy, Suspense } from 'react';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import AddDialog from './AdminCompanyDialog/AddCompanyDialog';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { CircularProgress, TableBody, TableCell, TableHead, TableRow, TablePagination } from '@material-ui/core';
import {
  authUserMemo,
  countryMemo,
  regionsMemo,
  sub_cityMemo,
  cityMemo,
  loginUser,
  usersObject,
  officeMemo,
  companyData,
} from 'redux/selector/companyHasOfficeSelector';
import { deleteAdminCompanyService } from 'services/company';
import Swal from 'sweetalert2';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';

const UpdateDialog = lazy(() => import('./AdminCompanyDialog/UpdateCompanyDialog'));

const CustomTablePagination = props => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };
  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

CustomTablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function Company({
  companyState,
  countryCache,
  cityCache,
  divisionCache,
  divisionCityCache,
  subCityCache,
  setCountryCache,
  setDivisionCache,
  setDivisionCityCache,
  setCityCache,
  setSubCityCache,
}) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  /*   const [oldRegionID, setOldRegionID] = useState();
  const [oldCountryID, setOldCountryID] = useState();
  const [oldCityID, setOldCityID] = useState(); */
  const companyOrEnterprise = useSelector(authUserMemo);
  const countryState = useSelector(countryMemo);
  const regionsState = useSelector(regionsMemo);
  const subCityState = useSelector(sub_cityMemo);
  const cityState = useSelector(cityMemo);
  const userData = useSelector(usersObject);
  const authUserData = useSelector(loginUser);
  const officesObject = useSelector(officeMemo);
  const stateCompany = useSelector(companyData);

  useEffect(() => {
    let userCompanyData = {};
    if (authUserData.authority_level == 20) {
      if (userData[authUserData.id]?.pivot_office && stateCompany) {
        userData[authUserData.id].pivot_office.map(officeID => {
          if (!userCompanyData[officesObject[officeID].company_id]) {
            userCompanyData[officesObject[officeID].company_id] = stateCompany[officesObject[officeID].company_id];
          }
        });
        setCompaniesData(Object.values(userCompanyData));
      }
    } else {
      setCompaniesData(companyState);
    }
  }, [userData, companyState]);

  const deleteFunction = deleteCompany => {
    Swal.fire({
      title: intl.formatMessage({ id: 'are.you.sure?' }),
      text: intl.formatMessage(
        {
          id: 'you.want.to.delete.company',
        },
        { name: deleteCompany.name },
      ) /* 'You want to delete ' + deleteCompany.name + ' company?', */,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: intl.formatMessage({ id: 'cancel' }),
      confirmButtonText: intl.formatMessage({ id: 'yes' }),
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteAdminCompanyService(deleteCompany));
        Swal.fire('Deleted!', intl.formatMessage({ id: 'company.has.been.deleted' }), 'success');
      }
    });
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companiesData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div>
      {openAddDialog ? (
        <AddDialog
          open={openAddDialog}
          setOpen={setOpenAddDialog}
          countryCache={countryCache}
          divisionCache={divisionCache}
          divisionCityCache={divisionCityCache}
          cityCache={cityCache}
          subCityCache={subCityCache}
          setCountryCache={setCountryCache}
          setDivisionCache={setDivisionCache}
          setDivisionCityCache={setDivisionCityCache}
          setCityCache={setCityCache}
          setSubCityCache={setSubCityCache}
          /*           setOldRegionID={setOldRegionID}
          oldRegionID={oldRegionID}
          setOldCountryID={setOldCountryID}
          oldCountryID={oldCountryID}
          setOldCityID={setOldCityID}
          oldCityID={oldCityID} */
        />
      ) : (
        <div />
      )}
      {openUpdateDialog ? (
        <Suspense fallback={<CircularProgress />}>
          <UpdateDialog
            open={openUpdateDialog}
            setOpen={setOpenUpdateDialog}
            updateData={updateData}
            countryCache={countryCache}
            divisionCache={divisionCache}
            divisionCityCache={divisionCityCache}
            cityCache={cityCache}
            subCityCache={subCityCache}
            setCountryCache={setCountryCache}
            setDivisionCache={setDivisionCache}
            setDivisionCityCache={setDivisionCityCache}
            setCityCache={setCityCache}
            setSubCityCache={setSubCityCache}
            /* setOldRegionID={setOldRegionID}
            oldRegionID={oldRegionID}
            setOldCountryID={setOldCountryID}
            oldCountryID={oldCountryID}
            setOldCityID={setOldCityID}
            oldCityID={oldCityID} */
          />
        </Suspense>
      ) : (
        <div />
      )}

      <MaterialTable
        actions={[
          {
            icon: () => (
              <Button style={{ backgroundColor: 'transparent' }} endIcon={<AddBusinessOutlinedIcon />}>
                <IntlMessages id="add.company" />
                {/*  <IconButton>
                  <AddBusinessOutlinedIcon />
                </IconButton> */}
              </Button>
            ),
            title: 'add',
            tooltip: 'Add Company',
            hidden: companyOrEnterprise == 11 || companyOrEnterprise == 20,
            isFreeAction: true,

            onClick: (event, rowData) => {
              setOpenAddDialog(true);
            },
          },
        ]}
        components={{
          Header: props => (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <IntlMessages id="company.name" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="official.title" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="tax.office" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="tax.no" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="email" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="phone" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="website" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="region" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="country" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="city" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="sub.city" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="district" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="actions" />
                  </TableCell>
                </TableRow>
              </TableHead>
            </>
          ),
          Body: props => (
            <>
              <TableBody>
                {(rowsPerPage > 0
                  ? companiesData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : companiesData
                ).map(element => {
                  return (
                    <TableRow>
                      <TableCell>{element.name}</TableCell>
                      <TableCell>{element.official_title}</TableCell>
                      <TableCell>{element.tax_office}</TableCell>
                      <TableCell>{element.tax_no}</TableCell>
                      <TableCell>{element.email ? element.email : '---'}</TableCell>
                      <TableCell>{element.phone ? element.phone : '---'}</TableCell>
                      <TableCell>{element.website ? element.website : '---'}</TableCell>
                      <TableCell>{element.region_id ? regionsState[element.region_id]?.name : '---'}</TableCell>
                      <TableCell>{element.country_id ? countryState[element.country_id]?.name : '---'}</TableCell>
                      <TableCell>{element.city_id ? cityState[element.city_id]?.name : '---'}</TableCell>
                      <TableCell>{element.subcity_id ? subCityState[element.subcity_id]?.name : '---'}</TableCell>
                      <TableCell>{element.district ? element.district : '---'}</TableCell>
                      <TableCell>
                        <Button
                          onClick={(event, rowData) => {
                            setUpdateData(element);
                            setOpenUpdateDialog(true);
                          }}>
                          <CreateOutlinedIcon />
                        </Button>
                        {element.offices.length == 0 && element.is_enterprise == 2 && (
                          <Button
                            onClick={(event, rowData) => {
                              deleteFunction(element);
                            }}>
                            <DeleteOutlineOutlinedIcon />
                          </Button>
                        )}
                      </TableCell>{' '}
                    </TableRow>
                  );
                })}
              </TableBody>
            </>
          ),
          Pagination: props => (
            <>
              <TablePagination
                style={{ display: 'flex', justifyContent: 'right' }}
                rowsPerPageOptions={[5, 10, 20, { label: 'All', value: -1 }]}
                colSpan={3}
                count={companiesData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={CustomTablePagination}
              />
            </>
          ),
        }}
        options={{
          showTitle: false,
          showSelectAllCheckbox: false,
          showTextRowsSelected: false,
          // actionsColumnIndex: -1,
          search: false,
          filtering: false,
          paging: true,
          addRowPosition: 'first',
        }}
      />
    </div>
  );
}
