import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import GridContainer from '@jumbo/components/GridContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { SingleSelect } from 'react-select-material-ui';
import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useIntl } from 'react-intl';

const HotelDetais = props => {
  const operators = useSelector(state => state.operators.operators);
  const hotels = useSelector(state => state.hotels.hotels);
  const intl = useIntl();
  const datePick = (name, date) => {
    var e = {
      target: {
        name: name,
        value: date,
        // .toLocaleDateString()
        // .split('.')
        // .reverse()
        // .join('-'),
      },
    };
    props.handleChange(e);
  };

  const gridContainerStyle = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  };

  return (
    <GridContainer style={gridContainerStyle.container}>
      <Grid item xs={2}>
        {/*************** OPERATOR SELECT ******************/}
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            <IntlMessages id="operator.name" />{' '}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            name="operator_id"
            defaultValue={null}
            value={props.reservationDetail.operator_id}
            label={intl.formatMessage({ id: 'operator.name' })}
            onChange={props.handleChange}>
            {Object.values(operators).map(operator => (
              <MenuItem value={operator.id}>{operator.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {/************************************************/}
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            <IntlMessages id="hotel.name" />{' '}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            name="hotel_id"
            value={props.reservationDetail.hotel_id}
            label={intl.formatMessage({ id: 'hotel.name' })}
            onChange={props.handleChange}>
            {Object.values(hotels).map(hotel => (
              <MenuItem value={hotel.id}>{hotel.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <KeyboardDatePicker
          name="selling_date"
          // format={localStorage.getItem('format')}
          // format={'MM/DD/YYYY'}
          autoOk="true"
          // value={props.reservationDetail.selling_date}
          // onChange={date => {
          //   datePick('selling_date', date._d);
          // }}
          format="DD/MM/YYYY"
          value={moment(props.reservationDetail.selling_date).format('YYYY-MM-DD')}
          onChange={date => {
            datePick('selling_date', moment(date._d).format('YYYY-MM-DD'));
          }}
          label={intl.formatMessage({ id: 'res.create.date' })}
          animateYearScrolling
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <KeyboardDatePicker
          name="start_date"
          disablePast
          minDate={props.reservationDetail.selling_date}
          maxDate={props.reservationDetail.end_date}
          // format={localStorage.getItem('format')}
          // format={'MM/DD/YYYY'}
          autoOk="true"
          // value={props.reservationDetail.start_date}
          // onChange={date => {
          // datePick('start_date', date._d);
          // }}
          format="DD/MM/YYYY"
          value={moment(props.reservationDetail.start_date).format('YYYY-MM-DD')}
          onChange={date => {
            datePick('start_date', moment(date._d).format('YYYY-MM-DD'));
          }}
          label={intl.formatMessage({ id: 'res.check.in.date' })}
          animateYearScrolling
          InputLabelProps={{
            shrink: true,
          }}
        />
        {/* <KeyboardDatePicker
          name="start_date"
          // format={localStorage.getItem('format')}
           format={'MM/DD/YYYY'}
          label="Res. Start Date"
          value={props.reservationDetail.start_date}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={date => {

            datePick('start_date', date._d.toDateString());
          }}
        /> */}
      </Grid>
      <Grid item xs={2}>
        <KeyboardDatePicker
          name="end_date"
          disablePast
          minDate={props.reservationDetail.start_date}
          // format={localStorage.getItem('format')}
          // format={'MM/DD/YYYY'}
          autoOk="true"
          // value={props.reservationDetail.end_date}
          // onChange={date => {
          // datePick('end_date', date._d);
          // }}
          format="DD/MM/YYYY"
          value={moment(props.reservationDetail.end_date).format('YYYY-MM-DD')}
          onChange={date => {
            datePick('end_date', moment(date._d).format('YYYY-MM-DD'));
          }}
          label={intl.formatMessage({ id: 'res.check.out.date' })}
          animateYearScrolling
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </GridContainer>
  );
};

export default HotelDetais;
