import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import GridContainer from '@jumbo/components/GridContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box, Button, Divider, MenuItem, TextField, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

const numbers = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
];

const RoomSystem = props => {
  const contract_rooms = useSelector(state => state.contract.contract_room);
  const allRoomTypes = useSelector(state => state.rooms.rooms.roomType);
  const [roomDetails, setRoomDetails] = useState({
    customers: [],
  });
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState({});
  /* const [currentRoomCode, setRoomCode] = useState() */

  const handleChange = e => {
    const roomCode = rooms.map(val => {
      return val.room_code;
    });

    /*  let roomId = Number(e.target.value);

    let room_Code = roomCode[roomId - 1];

    setRoomCode(room_Code)
    console.log(room_Code); */

    if (e.target.name === 'room_id') {
      setSelectedRoom(rooms.find(room => room.id === e.target.value));
    }
    const { name, value } = e.target;
    // Set values
    setRoomDetails(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (
      (roomDetails.room_id !== undefined) &
      (roomDetails.adult !== undefined) &
      (roomDetails.child !== undefined) &
      (roomDetails.infant !== undefined)
    ) {
      props.handleRoomAdd(props.no, roomDetails);
    }
  }, [roomDetails]);
  useEffect(() => {
    if (props.selectedContract !== null) {
      var contract_room_types_with_code = [];
      props.selectedContract.contract_room.forEach(room => {
        var newRoom = { ...contract_rooms[room] };
        console.log(allRoomTypes);
        newRoom['room_code'] = allRoomTypes[newRoom.room_id].code;
        contract_room_types_with_code.push(newRoom);
      });
      setRooms(contract_room_types_with_code);
    } else {
      setRooms(Object.values(allRoomTypes));
    }
  }, [props.selectedContract]);

  const handleDeleteRoom = () => {
    props.setRoomCount(prev => prev - 1);
    props.setRoomTab(prev => prev - 1);
    var oldRooms = props.rooms;
    delete oldRooms[props.no];
    var oldKeys = Object.keys(oldRooms);
    var newRooms = {};
    oldKeys.forEach((key, index) => {
      newRooms[index + 1] = oldRooms[key];
    });
    props.setReservationDetail(prev => ({
      ...prev,
      rooms: newRooms,
    }));
  };

  return (
    <GridContainer direction="row" alignItems="center">
      <Grid item xs={1} justifyContent="center" alignItems="center">
        <Box fullWidth style={{ paddingTop: '15px' }}>
          <Typography style={{ fontSize: 1 + 'rem', color: '#33658a' }}>No :{props.no}</Typography>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <TextField id="room" select label="Room" name="room_id" fullWidth onChange={handleChange}>
          {rooms.map(option => (
            <MenuItem key={option.id} value={option.id}>
              {props.selectedContract !== null ? option.room_code + ' - ' + option.agency_room_name : option.code}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="adult"
          select
          label="Adult"
          name="adult"
          fullWidth
          onChange={handleChange}
          disabled={!roomDetails.room_id ? true : false}>
          {props.selectedContract !== null
            ? numbers.map((option, index) => {
                return selectedRoom?.max_adult >= index && selectedRoom?.min_adult <= index ? (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ) : null;
              })
            : numbers.map((option, index) => {
                if (index !== 0) {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                }
              })}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="child"
          select
          label="Child"
          name="child"
          fullWidth
          onChange={handleChange}
          disabled={!roomDetails.room_id ? true : false}>
          {props.selectedContract !== null
            ? numbers.map((option, index) => {
                return selectedRoom?.min_child >= index && selectedRoom?.min_child <= index ? (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ) : null;
              })
            : numbers.map((option, index) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="infant"
          select
          label="Infant"
          name="infant"
          fullWidth
          onChange={handleChange}
          disabled={!roomDetails.room_id ? true : false}>
          {props.selectedContract !== null
            ? numbers.map((option, index) => {
                return selectedRoom?.max_infant >= index && 0 <= index ? (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ) : null;
              })
            : numbers.map((option, index) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
        </TextField>
      </Grid>
      <Grid item xs={2} style={{ paddingTop: '15px' }}>
        {props.no !== 1 ? (
          <Button variant="contained" style={{ color: 'white', backgroundColor: 'red' }} onClick={handleDeleteRoom}>
            <IntlMessages id="delete" />
          </Button>
        ) : null}
      </Grid>
      <Divider />
    </GridContainer>
  );
};

export default RoomSystem;
