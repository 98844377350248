import { GET_EXTRA, ADD_EXTRA, UPDATE_EXTRA, DELETE_EXTRA } from './types';

export const getExtras = res => dispatch => {
  dispatch({
    type: GET_EXTRA,
    payload: res.data.data,
  });
};

export const addExtras = res => dispatch =>
  dispatch({
    type: ADD_EXTRA,
    payload: res.data.data,
  });

export const updateExtras = res => dispatch =>
  dispatch({
    type: UPDATE_EXTRA,
    payload: res.data.data,
  });

export const deleteExtras = res => dispatch =>
  dispatch({
    type: DELETE_EXTRA,
    payload: res.data.data,
  });
