import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Block, CheckCircleOutline, Delete, Edit, Mail, MoreHoriz, Visibility } from '@material-ui/icons';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CmtDropdownMenu from '../../../../../@coremat/CmtDropdownMenu';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const getUserActions = user => {
  const actions = [{ action: 'edit', label: <IntlMessages id="edit" />, icon: <Edit /> }];
  /* { action: 'view', label: 'View', icon: <Visibility /> },
   
    { action: 'email', label: 'Email', icon: <Mail /> },
  

  if (user.status === 'active') {
    actions.push({ action: 'suspend', label: 'Suspend', icon: <Block /> });
  } else {
    actions.push({
      action: 'activate',
      label: 'Reactivate',
      icon: <CheckCircleOutline />,
    });
  }
 */
  actions.push({ action: 'delete', label: <IntlMessages id="delete" />, icon: <Delete /> });
  return actions;
};

const UserListRow = ({ row, onUserEdit, onUserDelete, onUserView }) => {
  const classes = useStyles();

  const onUserMenuClick = menu => {
    /*   if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'email') {
    } else if (menu.action === 'suspend') {
    } else if (menu.action === 'activate') {
    } */
    if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const userActions = getUserActions(row);
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
      <TableCell padding="checkbox" />

      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Box display="flex" alignItems="center">
          <div>
            <Typography className={classes.titleRoot} component="div" variant="h4">
              {row.fullname || ''}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.owned_company.name ?? ''}</TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<MoreHoriz />} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
