import axios from "axios";

const token = localStorage.getItem("token");
export default axios.create({
  baseURL: `https://testb2bapi.mundosai.com/api/`,
  headers: {
    "Content-Type": "application/json"
    // Authorization: `Bearer ${token}`,
  }
});
