import React, { useEffect, useState, useRef } from 'react';
import { Button, TextField } from '@material-ui/core';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { Container, Row } from 'react-grid-system';
import { Grid, CardContent } from '@material-ui/core';
import CmtCard from '@coremat/CmtCard';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, Space } from 'antd';
import { getHotelService } from 'services/hotels';
import { getContractService } from 'services/contract';
import { getMarkupsService } from 'services/markup';
import { getOperatorService } from 'services/operator';
import { getMarketService } from 'services/market';
import { getCurrenciesService } from 'services/currency';
import { getSeasonService } from 'services/season';
import { getRegionsService } from 'services/destinations';
import { getBoardsService } from 'services/boards';
import { getRateTypesService } from 'services/rateType';
import { getAllotmentTypeService } from 'services/allotmentType';
import { getAdminCompaniesService } from 'services/company';
import { getOnSaleContractService } from 'services/onSaleContract';
import MaterialTable from 'material-table';
import moment from 'moment';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  selectBoxRoot: {
    marginBottom: 20,
    '& .MuiOutlinedInput-input': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400],
    },
  },
  button: {
    height: '40px !important',
  },
}));

export default function ContractReport() {
  const { authUser } = useSelector(({ auth }) => auth);
  const { selectedCompany } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const [status, setStatus] = useState([
    { name: 'DRAFT', id: 1 },
    { name: 'CONFIRM', id: 2 },
    { name: 'SIGNED', id: 3 },
    { name: 'ON SALE', id: 4 },
    { name: 'REVISED', id: 5 },
  ]);
  const [syncStatus, setSyncStatus] = useState([
    { name: 'SUCCESS', id: 1 },
    { name: 'ERROR', id: 2 },
  ]);
  const [filterState, setFilterState] = useState({
    hotel_id: '',
    region_id: '',
    operators: '',
    season_id: '',
    currency: '',
    contract_statuses_id: '',
    sync: '',
    user_id: '',
    start_date: '',
    end_date: '',
  });
  useEffect(async () => {
    await dispatch(getContractService(authUser.authority_level, localStorage.getItem('selectedCompany')));
    await dispatch(getOnSaleContractService());
    await dispatch(getRegionsService());
    await dispatch(getAdminCompaniesService());
    await dispatch(getBoardsService());
    await dispatch(getHotelService());
    await dispatch(getSeasonService());
    await dispatch(getMarketService());
    await dispatch(getMarkupsService());
    await dispatch(getOperatorService());
    await dispatch(getCurrenciesService());
    await dispatch(getRateTypesService());
    await dispatch(getAllotmentTypeService());
  }, [dispatch]);
  const dispatch = useDispatch();
  const intl = useIntl();

  const { contracts } = useSelector(({ contract }) => contract);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { regions } = useSelector(({ destinations }) => destinations);
  const { operators } = useSelector(({ operators }) => operators);
  const { seasons } = useSelector(({ seasons }) => seasons);
  const { currencies } = useSelector(({ currencies }) => currencies);
  const { entities } = useSelector(({ companyHasOffice }) => companyHasOffice);
  const { allotmentType } = useSelector(({ allotmentType }) => allotmentType);
  const { contract_has_board } = useSelector(({ contract }) => contract);
  const { boards } = useSelector(({ boards }) => boards);
  const { markets } = useSelector(({ markets }) => markets);
  const { onSaleContract } = useSelector(({ onSaleContract }) => onSaleContract);
  const markup = useSelector(state => state.markups.markups);

  const users = [];
  Object.values(entities.users).map(user => users.push(user));
  const filterHandleChange = e => {
    const { name, value } = e;
    setFilterState(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  const [contractData, setContractData] = useState([]);
  const filterSelected = () => {
    if (contracts !== undefined || null) {
      let allContracts = Object.values(contracts).map(contract => {
        let contractHotel = hotels[contract.hotel_id];
        let newContract = {
          ...contract,
          region_id: contractHotel.region_id,
          product_name: contractHotel.name,
          star_rating: contractHotel.star_rating + ' Star',
          type_of_product: contractHotel.product_type.code,
          season_name: seasons[contract.season_id].code,
          operator_string: (function() {
            let operator_string = '';
            contract.operators.forEach(operator => {
              operator_string += operators[operator].code + ' ';
            });
            return operator_string;
          })(),
          region_name: (function() {
            let region_string = '';
            regions.forEach(region => {
              if (region.id === contractHotel.region_id) {
                region_string += region.name + ' ';
              }
            });
            return region_string;
          })(),

          market_string: (function() {
            let market_string = '';
            contract.market.forEach(market => {
              market_string += markets[market].code + ' ';
            });
            return market_string;
          })(),
          contract_status: status.find(st => st.id === contract.contract_statuses_id).name,
          sync_name: contract.sync === '1' ? 'SUCCESS' : 'ERROR',
          markup: (function() {
            let markup_string = '';
            Object.values(onSaleContract).forEach(onSale =>
              onSale.contract_id === contract.id ? (markup_string += markup[onSale.markup_id].code + ' ') : '',
            );
            return markup_string;
          })(),

          board_string: (function() {
            let board_string = '';
            contract.contract_has_board.forEach(board => {
              board_string += boards[contract_has_board[board].board_id].code + ' ';
            });
            return board_string;
          })(),
          allotment_name: allotmentType[contract.allotment_type_id].name,
          currency_name: currencies[contract.currency].code,
          user_name: entities.users[contract.user_id].fullname,
        };
        return newContract;
      });
      Object.keys(filterState).forEach(filterKey => {
        if (filterState[filterKey] !== '') {
          if (filterKey != 'operators' && filterKey !== 'start_date' && filterKey !== 'end_date') {
            allContracts = allContracts.filter(contract => contract[filterKey] == filterState[filterKey]);
          } else if (filterKey === 'start_date' || filterKey === 'end_date') {
            allContracts = allContracts.filter(contract =>
              moment(contract[filterKey]).isBetween(filterState[filterKey].from, filterState[filterKey].to, undefined, '[]'),
            );
          } else {
            allContracts = allContracts.filter(contract => contract.operators.includes(filterState.operators));
          }
        }
      });
      setContractData(allContracts);
    }
  };
  const handleChangeStart = (name, value) => {
    setFilterState({ ...filterState, start_date: { from: value[0], to: value[1] } });
  };
  const handleChangeEnd = (name, value) => {
    setFilterState({ ...filterState, end_date: { from: value[0], to: value[1] } });
  };

  const tableColumns = [
    {
      title: <IntlMessages id="product.name" />,
      field: 'product_name',
    },
    {
      title: <IntlMessages id="star.rating" />,
      field: 'star_rating',
    },
    {
      title: <IntlMessages id="type.of.product" />,
      field: 'type_of_product',
    },
    {
      title: <IntlMessages id="season.name" />,
      field: 'season_name',
    },
    {
      title: <IntlMessages id="operator" />,
      field: 'operator_string',
    },
    {
      title: <IntlMessages id="region" />,
      field: 'region_name',
    },
    {
      title: <IntlMessages id="market" />,
      field: 'market_string',
    },
    {
      title: <IntlMessages id="start.date" />,
      field: 'start_date',
    },
    {
      title: <IntlMessages id="end.date" />,
      field: 'end_date',
    },
    {
      title: <IntlMessages id="created.date" />,
      field: 'created_date',
    },
    {
      title: <IntlMessages id="signed.date" />,
      field: 'signed_date',
    },
    {
      title: <IntlMessages id="published.date" />,
      field: 'published_date',
    },
    {
      title: <IntlMessages id="contract.status" />,
      field: 'contract_status',
    },
    {
      title: <IntlMessages id="sync" />,
      field: 'sync_name',
    },
    {
      title: <IntlMessages id="markup" />,
      field: 'markup',
    },
    {
      title: <IntlMessages id="base.board" />,
      field: 'board_string',
    },
    {
      title: <IntlMessages id="allotment.type" />,
      field: 'allotment_name',
    },
    {
      title: <IntlMessages id="currency" />,
      field: 'currency_name',
    },
    {
      title: <IntlMessages id="user" />,
      field: 'user_name',
    },
  ];
  const { RangePicker } = DatePicker;
  const tableRef = useRef();
  const downloadCsv = (data, fileName) => {
    const finalFileName = fileName.endsWith('.csv') ? fileName : `Contract Report.csv`;
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
    a.setAttribute('download', finalFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <div className={classes.selectBoxRoot}>
      <CmtCard>
        <CardContent>
          <PageContainer>
            <Container>
              <Row xs="fixed">
                <Grid container spacing={10}>
                  <Grid item sm={3}>
                    <IntlMessages id="contract.start.date" />
                    <Space direction="vertical" size={12}>
                      <RangePicker placeholder={['from', 'to']} onChange={handleChangeStart} />
                    </Space>
                  </Grid>
                  <Grid item sm={3}>
                    <IntlMessages id="contract.end.date" />
                    <Space direction="vertical" size={12}>
                      <RangePicker placeholder={['from', 'to']} onChange={handleChangeEnd} />
                    </Space>
                  </Grid>
                  <Grid item sm={2}>
                    <Autocomplete
                      options={Object.values(hotels)}
                      onChange={(e, value) => {
                        let event = {
                          name: 'hotel_id',
                          value: value != null ? value.id : '',
                        };
                        filterHandleChange(event);
                      }}
                      getOptionLabel={option => option.name}
                      renderInput={params => (
                        <TextField
                          {...params}
                          required
                          placeholder={intl.formatMessage({ id: 'product' })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <Autocomplete
                      options={Object.values(regions)}
                      onChange={(e, value) => {
                        let event = {
                          name: 'region_id',
                          value: value != null ? value.id : '',
                        };
                        filterHandleChange(event);
                      }}
                      getOptionLabel={option => option.name}
                      renderInput={params => (
                        <TextField
                          {...params}
                          required
                          placeholder={intl.formatMessage({ id: 'region' })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <Autocomplete
                      options={Object.values(operators)}
                      onChange={(e, value) => {
                        let event = {
                          name: 'operators',
                          value: value != null ? value.id : '',
                        };
                        filterHandleChange(event);
                      }}
                      getOptionLabel={option => option.name}
                      renderInput={params => (
                        <TextField
                          {...params}
                          required
                          placeholder={intl.formatMessage({ id: 'operator' })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item sm={2}>
                      <Autocomplete
                        options={Object.values(seasons)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'season_id',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({ id: 'season' })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <Autocomplete
                        options={Object.values(currencies)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'currency',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.code}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({ id: 'currency' })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <Autocomplete
                        options={status}
                        onChange={(e, value) => {
                          let event = {
                            name: 'contract_statuses_id',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({ id: 'contract.status' })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <Autocomplete
                        options={syncStatus}
                        onChange={(e, value) => {
                          let event = {
                            name: 'sync',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({ id: 'sync' })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <Autocomplete
                        options={users}
                        onChange={(e, value) => {
                          let event = {
                            name: 'user_id',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option?.fullname}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({ id: 'user' })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <Button variant="outlined" className={classes.button} onClick={() => filterSelected()}>
                        <IntlMessages id="filter" />
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item sm={12}>
                    <MaterialTable
                      tableRef={tableRef}
                      columns={tableColumns}
                      data={contractData}
                      title=""
                      options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30, 40],
                        actionsColumnIndex: -1,
                        tableLayout: 'auto',
                        search: false,
                        editCellStyle: '10px',
                        addRowPosition: 'first',
                        exportButton: {
                          csv: true,
                          pdf: false,
                        },
                        exportCsv: (columns, data) => {
                          const headerRow = columns.map(col => {
                            if (typeof col.title === 'object') {
                              return col.title.props.text;
                            }
                            return col.title;
                          });
                          const dataRows = data.map(({ tableData, ...row }) => [
                            row.product_name,
                            row.star_rating,
                            row.type_of_product,
                            row.season_name,
                            row.operator_string,
                            row.region_name,
                            row.market_string,
                            row.start_date,
                            row.end_date,
                            row.created_date,
                            row.signed_date,
                            row.publish_date,
                            row.contract_status,
                            row.sync_name,
                            row.markup,
                            row.board_string,
                            row.allotment_name,
                            row.currency_name,
                            row.user_name,
                          ]);
                          const { exportDelimiter } = tableRef.current.props.options;
                          const delimiter = exportDelimiter ? exportDelimiter : ',';
                          const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join('\n');

                          const csvFileName = tableRef.current.props.title;

                          downloadCsv(csvContent, csvFileName);
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Row>
            </Container>
          </PageContainer>
        </CardContent>
      </CmtCard>
    </div>
  );
}
