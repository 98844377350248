import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { Container, Row } from 'react-grid-system';
import { Grid, CardContent } from '@material-ui/core';
import CmtCard from '@coremat/CmtCard';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, Space } from 'antd';
import { getHotelService } from 'services/hotels';
import { getOperatorService } from 'services/operator';
import { getMarketService } from 'services/market';
import { getReservationsService } from 'services/reservation';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';
import { getAllCountriesService, getRegionsService } from 'services/destinations';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtAvatar from '@coremat/CmtAvatar';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCardFooter from '@coremat/CmtCard/CmtCardFooter';
import CalculatedResCard from './CalculatedResCard';

const useStyles = makeStyles(theme => ({
  selectBoxRoot: {
    marginBottom: 20,
    '& .MuiOutlinedInput-input': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400],
    },
  },
  button: {
    height: '40px !important',
  },
}));

export default function CalculateReservation() {
  const dispatch = useDispatch();
  useEffect(async () => {
    await dispatch(getOperatorService());
    await dispatch(getMarketService());
    await dispatch(getReservationsService());
    await dispatch(getHotelService());
    await dispatch(getRegionsService());
    await dispatch(getAllCountriesService());
  }, [dispatch]);
  const { operators } = useSelector(({ operators }) => operators);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { markets } = useSelector(({ markets }) => markets);
  const { regions } = useSelector(({ destinations }) => destinations);
  const { allCountries } = useSelector(({ destinations }) => destinations);

  const classes = useStyles();
  const [filterState, setFilterState] = useState({
    country_id: '',
    region_id: '',
    operator_id: '',
    market_id: '',
    hotel_id: '',
    dates: '',
    adult_num: '',
    child_num: '',
  });
  const [filterFlag, setFilterFlag] = useState(false);

  const { RangePicker } = DatePicker;
  const handleChangeStart = (name, value) => {
    setFilterState({ ...filterState, dates: { from: value[0], to: value[1] } });
  };
  const filterHandleChange = e => {
    const { name, value } = e;
    setFilterState(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  const filterSelected = () => {
    setFilterFlag(true);
  };
  const intl = useIntl();
  const getCalculatedData = [
    {
      /*     total: 499.20000000000005,
    bs_total: 96,
    extra: 262.5,
    extra_O: 0,
    extra_Discount: 26.25,
    markup: 17.85,*/
      offer_discount: 79.52000000000001,
      net_total: 595.2,
      net_extra: 236.25,
      net: 751.9300000000001,

      hotel_id: 1,
      region_id: 2,
      country_id: 134,
      hotel_name: 'LÖWE HOTEL',
      region_name: 'Europe',
      country_name: 'TURKEY',
      gross_price: 876,
      net_price: 933,
      discount: 24,
    },
    {
      offer_discount: 719.52000000000001,
      net_total: 1595.2,
      net_extra: 1236.25,
      net: 1751.9300000000001,

      hotel_id: 1,
      region_id: 2,
      country_id: 134,
      hotel_name: 'LÖWE HOTEL',
      region_name: 'Europe',
      country_name: 'TURKEY',
      gross_price: 676,
      net_price: 1333,
      discount: 8,
    },
    {
      offer_discount: 2719.52000000000001,
      net_total: 21595.2,
      net_extra: 21236.25,
      net: 21751.9300000000001,

      hotel_id: 1,
      region_id: 2,
      country_id: 134,
      hotel_name: 'LÖWE HOTEL',
      region_name: 'Europe',
      country_name: 'TURKEY',
      gross_price: 896,
      net_price: 1420,
      discount: 22,
    },
  ];
  return (
    <div className={classes.selectBoxRoot}>
      <CmtCard>
        <CardContent>
          <PageContainer>
            <Container>
              <Row xs="fixed">
                <Grid container spacing={9}>
                  <Grid container spacing={9}>
                    <Grid item sm={3}>
                      <IntlMessages id="check.in.check.out.range" />
                      <Space direction="vertical" size={12}>
                        <RangePicker placeholder={['from', 'to']} onChange={handleChangeStart} />
                      </Space>
                    </Grid>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(hotels)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'hotel_id',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({ id: 'product' })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(regions)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'region_id',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({ id: 'region' })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={allCountries}
                        onChange={(e, value) => {
                          let event = {
                            name: 'country_id',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({ id: 'country' })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <TextField
                        id="outlined-basic"
                        name="adult_num"
                        label={<IntlMessages id="adult.num" />}
                        variant="outlined"
                        onChange={(e, value) => {
                          let event = {
                            name: 'adult_num',
                            value: e.target.value,
                          };
                          filterHandleChange(event);
                        }}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <TextField
                        id="outlined-basic"
                        name="child_num"
                        label={<IntlMessages id="child.num" />}
                        variant="outlined"
                        onChange={(e, value) => {
                          let event = {
                            name: 'child_num',
                            value: e.target.value,
                          };
                          filterHandleChange(event);
                        }}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(operators)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'operator_id',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({ id: 'operator' })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(markets)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'market_id',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({ id: 'market' })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <Button variant="outlined" className={classes.button} onClick={() => filterSelected()}>
                        <IntlMessages id="search" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Row>
            </Container>
          </PageContainer>
        </CardContent>
      </CmtCard>
      <div>
        {filterFlag
          ? getCalculatedData.map(element => {
              return <CalculatedResCard calculatedObj={element} />;
            })
          : null}
      </div>
    </div>
  );
}
