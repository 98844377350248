import React, { useState, useEffect, Suspense } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { purple, red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Autocomplete from '@mui/material/Autocomplete';
import AppTextInput from '../../Common/formElements/AppTextInput';
import GridContainer from '../../GridContainer';
import { useDispatch, useSelector } from 'react-redux';
import {
  normalizeCity,
  normalizeRegion,
  normalizeSubCity,
  normalizeCountry,
  normalizeDivisions,
} from 'redux/selector/destinationSelector';
import {
  getRegionsService,
  getCountriesService,
  getCitiesService,
  getSubCitiesService,
  getDivisionCitiesService,
  getDivisionsService,
} from 'services/destinations';
import { updateAdminCompanyService } from 'services/company';
import Update from '@mui/icons-material/Update';
import { requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import NumberFormat from 'react-number-format';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import CmtList from '../../../../@coremat/CmtList';
import { CircularProgress } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';

const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  option: {
    fontSize: 15,
  },
}));

const themeCancel = createTheme({
  palette: {
    primary: red,
  },
});

function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);

  const onNumberChange = number => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return (
    <NumberFormat
      {...other}
      onValueChange={e => {
        if (e.value.length <= 10) {
          onNumberChange(e);
        }
      }}
      value={phoneNumber}
      format="(###) ###-#### "
    />
  );
}

export default function UpdateEnterpriseDialog({
  open,
  setOpen,
  updateData,
  countryCache,
  divisionCache,
  cityCache,
  subCityCache,
  setCountryCache,
  setDivisionCache,
  setCityCache,
  setSubCityCache,
  /*   setOldRegionID,
  oldRegionID,
  setOldCountryID,
  oldCountryID,
  setOldCityID,
  oldCityID, */
}) {
  const theme = createTheme({
    palette: {
      primary: purple,
    },
  });
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { error_message } = useSelector(({ companyHasOffice }) => companyHasOffice.entities);
  const { success_message } = useSelector(({ companyHasOffice }) => companyHasOffice.entities);

  const normCountriesState = useSelector(normalizeCountry);
  const normRegionsState = useSelector(normalizeRegion);
  const normDivisionsState = useSelector(normalizeDivisions);
  const normCitiesState = useSelector(normalizeCity);
  const normSubCitiesState = useSelector(normalizeSubCity);



  const [email, setEmail] = useState(updateData.email);
  const [name, setName] = useState(updateData.name);
  const [companyId, setCompanyId] = useState(updateData.id);
  const [phones, setPhones] = useState([{ phone: updateData.phone ? updateData.phone : '' }]);
  const [country, setCountry] = useState(updateData.country_id ? updateData.country_id : '');
  const [region, setRegion] = useState(updateData.region_id ? updateData.region_id : '');
  const [division, setDivision] = useState(updateData.division_id ? updateData.division_id : '');
  const [city, setCity] = useState(updateData.city_id ? updateData.city_id : '');
  const [subcity, setSubcity] = useState(updateData.subcity_id ? updateData.subcity_id : '');
  const [district, setDistrict] = useState(updateData.district);
  const [emailError, setEmailError] = useState('');
  const [officialTitle, setOfficialTitle] = useState(updateData.official_title);
  const [taxNo, setTaxNo] = useState(updateData.tax_no);
  const [taxOffice, setTaxOffice] = useState(updateData.tax_office);
  const [errorName, setErrorName] = useState('');
  const [errorOfficialTitle, setErrorOfficialTitle] = useState('');
  const [errorTaxNo, setErrorTaxNo] = useState('');
  const [errorTaxOffice, setErrorTaxOffice] = useState('');
  const [errorRegion, setErrorRegion] = useState('');
  const [errorCountry, setErrorCountry] = useState('');
  const [save, setSave] = useState(false);
  const [controlRegion, setControlRegion] = useState(false);
  const [controlCountry, setControlCountry] = useState(false);
  const [controlDivision, setControlDivision] = useState(false);
  const [controlCity, setControlCity] = useState(false);
  const [changeRegion, setChangeRegion] = useState(false);
  const [changeCountry, setChangeCountry] = useState(false);
  const [changeDivision, setChangeDivision] = useState(false);

  const [changeCity, setChangeCity] = useState(false);

  useEffect(() => {
    dispatch(getRegionsService());
    if (updateData.region_id) {
      dispatch(getCountriesService(['region_id', updateData.region_id]));
    }
    if (updateData.country_id) {
      dispatch(getCitiesService(['country_id', updateData.country_id]));
    }
    if (updateData?.division_id && updateData?.country_id) {
      dispatch(getDivisionsService(['country_id', updateData.country_id]));
    }
    if (updateData.city_id) {
      dispatch(getSubCitiesService(['city_id', updateData.city_id]));
    }
  }, [dispatch]);

  useEffect(() => {
    // && (!oldRegionID || normCountriesState[0]?.region_id != oldRegionID)
    if ((controlRegion && region) || changeRegion) {
      setCountryCache({ ...countryCache, [region]: normCountriesState });
      setControlRegion(false);
      setChangeRegion(false);
    } else {
      setControlRegion(true);
    }
  }, [normCountriesState]);

  useEffect(() => {
    if (country && normCountriesState.find(countryState => countryState.id == country)?.has_division == 1) {
      setDivisionCache({ ...divisionCache, [country]: normDivisionsState });
    }
  }, [normDivisionsState]);



  useEffect(() => {
    // && (!oldCountryID || normCitiesState[0]?.country_id != oldCountryID)
    if ((controlCountry && country) || changeCountry) {
      setCityCache({ ...cityCache, [country]: normCitiesState });
      setControlCountry(false);
      setChangeCountry(false);
    } else {
      setControlCountry(true);
    }
  }, [normCitiesState]);

  useEffect(() => {
    //&& (!oldCityID || normSubCitiesState[0]?.city_id != oldCityID)
    if ((controlCity && city) || changeCity) {
      setSubCityCache({ ...subCityCache, [city]: normSubCitiesState });
      setControlCity(false);
      setChangeCity(false);
    } else {
      setControlCity(true);
    }
  }, [normSubCitiesState]);

  const onPhoneNoAdd = (number, index) => {
    const updatedList = [...phones];
    updatedList[index].phone = number;
    setPhones(updatedList);
  };

  const updateCompanyModal = updatedCompany => {
    Swal.fire({
      title: intl.formatMessage({ id: 'are.you.sure?' }),
      text: intl.formatMessage(
        {
          id: 'you.want.to.update.enterprise',
        },
        { name: updatedCompany },
      ),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({ id: 'cancel' }),
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: intl.formatMessage({ id: 'yes.update.it.' }),
    }).then(result => {
      if (result.isConfirmed) {
        onSubmitClick();
      }
    });
  };
  const onSubmitClick = () => {
    const enterprise = {
      email,
      name,
      companyId,
      phone: phones[0].phone,
      country,
      region,
      city,
      division,
      subcity,
      district,
      officialTitle,
      taxNo,
      taxOffice,
      is_enterprise: updateData.is_enterprise,
    };
    if (!name) {
      setErrorName(requiredMessage);
    }
    if (!email) {
      setEmailError(requiredMessage);
    }
    if (!officialTitle) {
      setErrorOfficialTitle(requiredMessage);
    }
    if (!taxNo) {
      setErrorTaxNo(requiredMessage);
    }
    if (!taxOffice) {
      setErrorTaxOffice(requiredMessage);
    }
    if (!region) {
      setErrorRegion(requiredMessage);
    }
    if (!country) {
      setErrorCountry(requiredMessage);
    }
    if (officialTitle && taxNo && taxOffice && name) {
      dispatch(updateAdminCompanyService(enterprise));
      setSave(true);
      /* setOpen(false);
      MySwal.fire('Success', 'Permission has been updated', 'success'); */
    }
  };
  const onCancelClick = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      officialTitle &&
      taxNo &&
      taxOffice &&
      name &&
      email &&
      error_message == '' &&
      success_message == 'success' &&
      region &&
      country &&
      save
    ) {
      MySwal.fire('Success', intl.formatMessage({ id: 'enterprise.has.been.updated' }), 'success');
      setSave(false);
      setName('');
      setTaxNo('');
      setTaxOffice('');
      setEmail('');
      setOfficialTitle('');
      setOpen(false);
    } else {
      if (error_message.country && save) {
        setErrorCountry(error_message.country[0]);
      }
      if (error_message.email && save) {
        setEmailError(error_message.email[0]);
      }
    }
  }, [error_message, success_message]);

  return (
    <Dialog open={open} className={classes.dialogRoot} disableEscapeKeyDown maxWidth="sm" fullWidth="on">
      <DialogTitle className={classes.dialogTitleRoot}> Update {updateData.name} Enterprise</DialogTitle>
      <DialogContent dividers>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            required
            variant="outlined"
            label={<IntlMessages id="company.name" />}
            value={name}
            helperText={errorName}
            onChange={e => {
              if (e.target.value.trim().length != 0) {
                setName(e.target.value);
                setErrorName('');
              } else {
                setErrorName(requiredMessage);
                setName('');
              }
            }}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            required
            variant="outlined"
            label={<IntlMessages id="official.title" />}
            value={officialTitle}
            onChange={e => {
              if (e.target.value.trim().length != 0) {
                setOfficialTitle(e.target.value);
                setErrorOfficialTitle('');
              } else {
                setErrorOfficialTitle(requiredMessage);
                setOfficialTitle('');
              }
            }}
            helperText={errorOfficialTitle}
          />
        </Box>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              variant="outlined"
              required
              label={<IntlMessages id="tax.office" />}
              value={taxOffice}
              onChange={e => {
                if (e.target.value.trim().length != 0) {
                  setTaxOffice(e.target.value);
                  setErrorTaxOffice('');
                } else {
                  setErrorTaxOffice(requiredMessage);
                  setTaxOffice('');
                }
              }}
              helperText={errorTaxOffice}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              variant="outlined"
              required
              label={<IntlMessages id="tax.no" />}
              value={taxNo}
              onChange={e => {
                if (e.target.value.trim().length != 0) {
                  setTaxNo(e.target.value);
                  setErrorTaxNo('');
                } else {
                  setErrorTaxNo(requiredMessage);
                  setTaxNo('');
                }
              }}
              helperText={errorTaxNo}
            />
          </Grid>
        </GridContainer>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            required
            variant="outlined"
            label={<IntlMessages id="email" />}
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              setEmailError('');
            }}
            helperText={emailError}
          />
        </Box>

        <CmtList
          data={phones}
          renderRow={(item, index) => (
            <Box mb={{ xs: 6, md: 5 }}>
              <Suspense fallback={<CircularProgress />}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label={<IntlMessages id="phone" />}
                  onChange={number => onPhoneNoAdd(number, index)}
                  InputProps={{
                    inputComponent: PhoneNumberInput,
                    inputProps: { value: item.phone },
                  }}
                />
              </Suspense>
            </Box>
          )}
        />
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="country-select-demo"
              options={normRegionsState}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              value={
                region !== '' && normRegionsState.length !== 0
                  ? normRegionsState[normRegionsState.findIndex(i => i.id === region)]
                  : null
              }
              getOptionLabel={option => option.name}
              onChange={(e, value) => {
                if (value) {
                  if (!countryCache[value.id]) {
                    dispatch(getCountriesService(['region_id', value.id]));
                  }
                  setRegion(value.id);
                  setErrorRegion('');
                } else {
                  setRegion('');
                  setErrorRegion(requiredMessage);
                }
                setChangeRegion(true);
                setCity('');
                setSubcity('');
                setDistrict('');
                setCountry('');
              }}
              renderInput={params => (
                <AppTextInput
                  required
                  {...params}
                  label={<IntlMessages id="choose.a.region" />}
                  helperText={errorRegion}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {countryCache[region] && countryCache[region].length != 0 && (
              <Autocomplete
                id="country-select-demo"
                options={countryCache[region]}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                value={
                  country !== '' && countryCache[region].length !== 0
                    ? countryCache[region][
                        countryCache[region].findIndex(i => i.id === country)
                      ] /* normCountriesState[normCountriesState.findIndex(i => i.id === country)] */
                    : null
                }
                getOptionLabel={option => option.name}
                onChange={(e, value) => {
                  if (value) {
                    if (!cityCache[value.id] && normCountriesState.find(i => i.id === value.id).has_division == 0) {
                      dispatch(getCitiesService(['country_id', value.id]));
                    } else if (normCountriesState.find(i => i.id === value.id).has_division == 1) {
                      dispatch(getDivisionsService(['country_id', value.id]));
                    }
                    setCountry(value.id);
                    setErrorCountry('');
                  } else {
                    setCountry('');
                    setErrorCountry(requiredMessage);
                  }
                  setChangeCountry(true);
                  setCity('');
                  setSubcity('');
                  setDistrict('');
                }}
                renderInput={params => (
                  <AppTextInput
                    {...params}
                    required
                    label={<IntlMessages id="choose.a.country" />}
                    helperText={errorCountry}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </Grid>
        </GridContainer>
        <GridContainer style={{ marginBottom: 12 }}>
          {normCountriesState.find(i => i.id === country)?.has_division == 1 && (
            <>
              {divisionCache[country] && divisionCache[country].length != 0 && (
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="country-select-demo"
                    options={divisionCache[country]}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={option => option.name}
                    value={
                      division !== '' && divisionCache[country].length !== 0
                        ? divisionCache[country][divisionCache[country].findIndex(i => i.id == division)]
                        : null
                    }
                    onChange={(e, value) => {
                      if (value) {
                        dispatch(getDivisionCitiesService(['division_id', value.id]));

                        setDivision(value.id);
                      } else {
                        setDivision('');
                      }
                      setChangeDivision(true);
                      setCity('');
                      setSubcity('');
                      setDistrict('');
                    }}
                    renderInput={params => (
                      <AppTextInput
                        {...params}
                        label={<IntlMessages id="choose.a.state" />}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {division && normCitiesState.length != 0 && (
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="country-select-demo"
                    options={normCitiesState}
                    classes={{
                      option: classes.option,
                    }}
                    value={
                      city !== '' && normCitiesState.length !== 0
                        ? normCitiesState[normCitiesState.findIndex(i => i.id === city)]
                        : null
                    }
                    autoHighlight
                    getOptionLabel={option => option.name}
                    onChange={(e, value) => {
                      if (value) {
                        dispatch(getSubCitiesService(['city_id', value.id]));
                        setCity(value.id);
                      } else {
                        setCity('');
                      }
                      setChangeCity(true);
                      setSubcity('');
                      setDistrict('');
                    }}
                    renderInput={params => (
                      <AppTextInput
                        {...params}
                        label={<IntlMessages id="choose.a.city" />}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
            </>
          )}
          {normCountriesState.find(i => i.id === country)?.has_division == 0 && (
            <>
              <Grid item xs={12} sm={6}>
                {cityCache[country] && cityCache[country].length != 0 && (
                  <Autocomplete
                    id="country-select-demo"
                    options={cityCache[country]}
                    classes={{
                      option: classes.option,
                    }}
                    value={
                      city !== '' && cityCache[country].length !== 0
                        ? cityCache[country][cityCache[country].findIndex(i => i.id === city)]
                        : null
                    }
                    autoHighlight
                    getOptionLabel={option => option.name}
                    onChange={(e, value) => {
                      if (value) {
                        if (!subCityCache[value.id]) {
                          dispatch(getSubCitiesService(['city_id', value.id]));
                        }
                        setCity(value.id);
                      } else {
                        setCity('');
                      }
                      setChangeCity(true);
                      setSubcity('');
                      setDistrict('');
                    }}
                    renderInput={params => (
                      <AppTextInput {...params} label={<IntlMessages id="choose.a.city" />} variant="outlined" />
                    )}
                  />
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            {subCityCache[city] && subCityCache[city].length != 0 && (
              <Autocomplete
                id="country-select-demo"
                options={subCityCache[city]}
                classes={{
                  option: classes.option,
                }}
                value={
                  subcity !== '' && subCityCache[city].length !== 0
                    ? subCityCache[city][subCityCache[city].findIndex(i => i.id === subcity)]
                    : null
                }
                autoHighlight
                getOptionLabel={option => option.name}
                onChange={(e, value) => {
                  if (value) {
                    setSubcity(value.id);
                    setDistrict('');
                  } else {
                    setSubcity('');
                    setDistrict('');
                  }
                }}
                renderInput={params => (
                  <AppTextInput {...params} label={<IntlMessages id="choose.a.sub.city" />} variant="outlined" />
                )}
              />
            )}
          </Grid>
        </GridContainer>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            {subcity && (
              <AppTextInput
                variant="outlined"
                label={<IntlMessages id="choose.a.district" />}
                value={district}
                onChange={e => setDistrict(e.target.value)}
              />
            )}
          </Grid>
        </GridContainer>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <ThemeProvider theme={themeCancel}>
            <Button variant="contained" color="primary" onClick={onCancelClick}>
              <IntlMessages id="cancel" />
            </Button>
          </ThemeProvider>
          <Box ml={2}>
            <ThemeProvider theme={theme}>
              <Button variant="contained" color="primary" startIcon={<Update />} onClick={() => updateCompanyModal(name)}>
                <IntlMessages id="update" />
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
