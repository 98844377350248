import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Autocomplete from '@mui/material/Autocomplete';
import AppTextInput from '../../Common/formElements/AppTextInput';
import GridContainer from '../../GridContainer';
import { addAdminCompanyService } from 'services/company';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRegionsService,
  getCountriesService,
  getCitiesService,
  getSubCitiesService,
  getDivisionsService,
  getDivisionCitiesService,
} from 'services/destinations';
import {
  normalizeCity,
  normalizeRegion,
  normalizeSubCity,
  normalizeCountry,
  normalizeDivisions,
} from 'redux/selector/destinationSelector';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import SaveIcon from '@mui/icons-material/Save';
import { requiredMessage, emailNotValid } from '../../../../@jumbo/constants/ErrorMessages';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import CmtList from '../../../../@coremat/CmtList';
import { PhoneFormatCustom } from '../../Common/NumberFormat/NumberFormat';
import { TextField } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';

const MySwal = withReactContent(Swal);
const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  option: {
    fontSize: 15,
    /* '& > span': {
      marginRight: 10,
      fontSize: 18,
    }, */
  },
  form: {
    '& .MuiFormHelperText-contained': {
      color: '#f44336',
    },
    '& .MuiFormHelperText-root': {
      color: '#f44336',
    },
  },
}));

const themeCancel = createTheme({
  palette: {
    primary: red,
  },
});

// function PhoneNumberInput({ onChange, value, ...other }) {
//   const [phoneNumber, setPhoneNumber] = useState('');

//   useEffect(() => {
//     if (!phoneNumber && value) {
//       setTimeout(() => {
//         setPhoneNumber(value);
//       }, 300);
//     } else if (!value) {
//       setPhoneNumber('');
//     }
//   }, [phoneNumber, value]);

//   const onNumberChange = number => {
//     setPhoneNumber(number.formattedValue);
//     onChange(number.formattedValue);
//   };

//   return <NumberFormat {...other} onValueChange={onNumberChange} value={phoneNumber} format="(###) ###-####" />;
// }

export default function AddCompanyDialog({
  open,
  setOpen,
  countryCache,
  divisionCache,
  divisionCityCache,
  cityCache,
  subCityCache,
  setCountryCache,
  setDivisionCache,
  setDivisionCityCache,
  setCityCache,
  setSubCityCache,
  /*   setOldRegionID,
  oldRegionID,
  setOldCountryID,
  oldCountryID,
  setOldCityID,
  oldCityID, */
}) {
  const theme = createTheme({
    palette: {
      primary: green,
    },
  });

  const dispatch = useDispatch();
  const classes = useStyles();

  const { error_message } = useSelector(({ companyHasOffice }) => companyHasOffice.entities);
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const { success_message } = useSelector(({ companyHasOffice }) => companyHasOffice.entities);

  const regions = useSelector(normalizeRegion);
  const countries = useSelector(normalizeCountry);
  const cities = useSelector(normalizeCity);
  const subCities = useSelector(normalizeSubCity);
  const divisions = useSelector(normalizeDivisions);

  const [errorWebsite, setErrorWebsite] = useState('');

  const [companyData, setCompanyData] = useState({
    name: '',
    email: '',
    phone: '',
    website: '',
    official_title: '',
    tax_no: '',
    tax_office: '',
    companyId: '',
  });
  const [first, setfirst] = useState({ name: '' });
  const [locationData, setLocationData] = useState({
    region: null,
    country: null,
    division: null,
    city: null,
    subcity: null,
    district: null,
  });
  const [errorData, setErrorData] = useState({
    name: '',
    email: '',
    phone: '',
    website: '',
    official_title: '',
    tax_no: '',
    tax_office: '',
    companyId: '',
    region: '',
    country: '',
    division: '',
    city: '',
    subcity: '',
  });

  // Website Validation
  var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);

  const onSubmitClick = () => {
    const regionId = locationData.region ? locationData.region.id : '';
    const countryId = locationData.country ? locationData.country.id : '';
    const cityId = locationData.city ? locationData.city.id : '';
    const subCityId = locationData.subcity ? locationData.subcity.id : '';
    const divisionId = locationData.division ? locationData.division.id : '';

    const company = {
      email: companyData.email,
      name: companyData.name,
      website: companyData.website,
      phone: companyData.phone,
      region: regionId,
      country: countryId,
      city: cityId,
      subcity: subCityId,
      division: divisionId,
      district: locationData.district,
      officialTitle: companyData.official_title,
      taxNo: companyData.taxNo,
      taxOffice: companyData.tax_office,
    };

    if (!companyData.name) {
      setErrorData({ ...errorData, name: requiredMessage });
    }
    if (!companyData.official_title) {
      setErrorData({ ...errorData, official_title: requiredMessage });
    }
    if (!companyData.taxNo) {
      setErrorData({ ...errorData, tax_no: requiredMessage });
    }
    if (!companyData.email) {
      setErrorData({ ...errorData, email: requiredMessage });
    }

    if (!companyData.website.match(regex) && companyData.website) {
      setErrorData({ ...errorData, website: 'Please enter valid website adress' });
    }

    if (!companyData.tax_office) {
      setErrorData({ ...errorData, tax_office: requiredMessage });
    }
    if (!locationData.region) {
      setErrorData({ ...errorData, region: requiredMessage });
    }
    if (!locationData.country) {
      setErrorData({ ...errorData, country: requiredMessage });
    }
    if (locationData.region && locationData.country) {
      setErrorData({ ...errorData, region: '', country: '' });
    }

    if (companyData.phone && companyData.phone.length < 18) {
      setErrorData({ ...errorData, phone: 'Please enter valid phone number' });
    } else if (
      companyData.official_title &&
      companyData.taxNo &&
      companyData.tax_office &&
      companyData.name &&
      companyData.email &&
      locationData.region &&
      locationData.country
    ) {
      if (
        companyData.phone
          ? companyData.phone.length >= 18
          : true && companyData.website
          ? companyData.website.match(regex)
          : true
      ) {
        dispatch(addAdminCompanyService(company));
      }
    }
  };

  const onCancelClick = () => {
    /*     setOldRegionID(region);
    setOldCountryID(country);
    setOldCityID(city); */
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getRegionsService());
  }, []);
  console.log(locationData);

  // useEffect(() => {
  //   if (locationData.region) {
  //     dispatch(getCountriesService(['region_id', locationData.region.id]));
  //   }
  // }, [locationData.region]);

  // useEffect(() => {
  //   if (locationData.country && locationData.country.has_division === 0) {
  //     dispatch(getCitiesService(['country_id', locationData.country.id]));
  //   } else if (locationData.country && locationData.country.has_division === 1) {
  //     dispatch(getDivisionsService(['country_id', locationData.country.id]));
  //   }
  // }, [locationData.country]);

  // useEffect(() => {
  //   if (locationData.division) {
  //     dispatch(getDivisionCitiesService(['division_id', locationData.division.id]));
  //   }
  // }, [locationData.division]);

  // useEffect(() => {
  //   if (locationData.city) {
  //     dispatch(getSubCitiesService(['city_id', locationData.city.id]));
  //   }
  // }, [locationData.city]);

  // DESTINATION CACHES

  // useEffect(() => {
  //   if (region /* && (!oldRegionID || countriesState[0]?.region_id != oldRegionID) */) {
  //     setCountryCache({ ...countryCache, [region]: countriesState });
  //   }
  // }, [countriesState]);

  // useEffect(() => {
  //   if (country && countriesState.find(countryState => countryState.id == country)?.has_division === 0) {
  //     setCityCache({ ...cityCache, [country]: citiesState });
  //   }
  //   if (division && countriesState.find(countryState => countryState.id == country)?.has_division === 1) {
  //     setDivisionCityCache({ ...divisionCityCache, [country]: { ...divisionCityCache[country], [division]: citiesState } });
  //   }
  // }, [citiesState]);

  // useEffect(() => {
  //   if (country && countriesState.find(countryState => countryState.id == country)?.has_division === 1) {
  //     setDivisionCache({ ...divisionCache, [country]: divisionState });
  //   }
  // }, [divisionState]);

  // useEffect(() => {
  //   if (city /* && (!oldCityID || subCitiesState[0]?.city_id != oldCityID) */) {
  //     setSubCityCache({ ...subCityCache, [city]: subCitiesState });
  //   }
  // }, [subCitiesState]);

  const newCompanyAlert = () => {
    Swal.fire({
      title: intl.formatMessage({ id: 'company.successfully.added' }),
      text: intl.formatMessage({ id: 'do.you.want.to.add.new.company' }),
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: intl.formatMessage({ id: 'yes' }),
      cancelButtonText: intl.formatMessage({ id: 'no' }),
    }).then(result => {
      if (result.isConfirmed) {
        setCompanyData({
          name: '',
          official_title: '',
          tax_no: '',
          email: '',
          website: '',
          phone: '',
          tax_office: '',
        });
        setLocationData({
          region: '',
          country: '',
          city: '',
          subcity: '',
          division: '',
          district: '',
        });

        /*         setOldRegionID(region);
        setOldCountryID(country);
        setOldCityID(city); */
      } else {
        setOpen(false);
        /*         setOldRegionID(region);
        setOldCountryID(country);
        setOldCityID(city); */
      }
    });
  };

  useEffect(() => {
    if (
      companyData.official_title &&
      companyData.taxNo &&
      companyData.tax_office &&
      companyData.name &&
      companyData.email &&
      error_message == '' &&
      success_message == 'success' &&
      locationData.region &&
      locationData.country
    ) {
      newCompanyAlert();
    } else {
      if (error_message.country) {
        setErrorData({ ...errorData, country: `${error_message.country[0]} Please add company as office` });
      }
      if (error_message.email) {
        setErrorData({ ...errorData, email: error_message.email[0] });
      }
    }
  }, [error_message, success_message]);
  // const onPhoneNoAdd = (number, index) => {
  //   const updatedList = [...phones];

  //   updatedList[index].phone = number;
  //   setPhones(updatedList);
  // };
  const intl = useIntl();
  const handleChange = name => event => {
    setCompanyData({ ...companyData, [name]: event.target.value });
    setErrorData({ ...errorData, [name]: '' });
  };

  return (
    <Dialog open={open} className={classes.dialogRoot} disableEscapeKeyDown maxWidth="sm" fullWidth="on">
      <DialogTitle className={classes.dialogTitleRoot}>
        <IntlMessages id="create.new.company" />
      </DialogTitle>
      <DialogContent dividers>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            required
            variant="outlined"
            value={companyData.name}
            label={<IntlMessages id="company.name" />}
            helperText={errorData.name}
            onChange={e => {
              if (e.target.value.trim().length != 0) {
                handleChange('name')(e);
              } else {
                setErrorData({ ...errorData, name: requiredMessage });
                setCompanyData({ ...companyData, name: '' });
              }
            }}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            required
            variant="outlined"
            label={<IntlMessages id="official.title" />}
            value={companyData.official_title}
            //defaultValue={officialTitle}
            onChange={e => {
              if (e.target.value.trim().length != 0) {
                handleChange('official_title')(e);
              } else {
                setErrorData({ ...errorData, official_title: requiredMessage });
                setCompanyData({ ...companyData, official_title: '' });
              }
            }}
            helperText={errorData.official_title}
          />
        </Box>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              variant="outlined"
              required
              value={companyData.tax_office}
              label={<IntlMessages id="tax.office" />}
              onChange={e => {
                if (e.target.value.trim().length != 0) {
                  handleChange('tax_office')(e);
                } else {
                  setErrorData({ ...errorData, tax_office: requiredMessage });
                  setCompanyData({ ...companyData, tax_office: '' });
                }
              }}
              helperText={errorData.tax_office}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              variant="outlined"
              required
              value={companyData.taxNo}
              label={<IntlMessages id="tax.no" />}
              onChange={e => {
                if (e.target.value.trim().length != 0) {
                  handleChange('taxNo')(e);
                } else {
                  setErrorData({ ...errorData, taxNo: requiredMessage });
                  setCompanyData({ ...companyData, taxNo: '' });
                }
              }}
              helperText={errorData.tax_no}
            />
          </Grid>
        </GridContainer>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            required
            variant="outlined"
            label={<IntlMessages id="email" />}
            value={companyData.email}
            autoComplete="nope"
            onChange={e => {
              handleChange('email')(e);
            }}
            helperText={errorData.email}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <TextField
            label={<IntlMessages id="phone" />}
            fullWidth
            variant="outlined"
            size="small"
            // placeholder={intl.formatMessage({ id: 'phone' })}
            placeholder="+xx (xxx) xxx-xxxx"
            className={classes.form}
            value={companyData.phone ? companyData.phone : ''}
            onChange={e => {
              handleChange('phone')(e);
            }}
            helperText={errorData.phone}
            name="tel"
            id="outlined-required"
            autoComplete="nope"
            InputProps={{
              inputComponent: PhoneFormatCustom,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            variant="outlined"
            label={<IntlMessages id="website" />}
            value={companyData.website}
            autoComplete="nope"
            onChange={e => {
              handleChange('website')(e);
            }}
            helperText={errorData.website}
          />
        </Box>

        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              autoHighlight
              classes={{
                option: classes.option,
              }}
              options={regions}
              getOptionLabel={option => option.name}
              value={first}
              renderInput={params => (
                <AppTextInput
                  required
                  {...params}
                  label={<IntlMessages id="choose.a.region" />}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                  helperText={errorData.region}
                />
              )}
              onChange={(event, value) => {
                dispatch(getCountriesService(['region_id', value.id]));
                setfirst(value);
                setLocationData({
                  ...locationData,
                  region: value,
                  country: null,
                  city: null,
                  division: null,
                  subcity: null,
                });
              }}
            />
          </Grid>
          {locationData.region && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                autoHighlight
                classes={{
                  option: classes.option,
                }}
                options={countries}
                value={locationData.country}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <AppTextInput
                    required
                    {...params}
                    label={<IntlMessages id="choose.a.country" />}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                    helperText={errorData.country}
                  />
                )}
                onChange={(event, value) => {
                  if (value && value.has_division === 0) {
                    dispatch(getCitiesService(['country_id', value.id]));
                  } else if (value && value.has_division === 1) {
                    dispatch(getDivisionsService(['country_id', value.id]));
                  }
                  if (value) {
                    setLocationData({ ...locationData, country: value, city: null, division: null, subcity: null });
                  }
                }}
              />
            </Grid>
          )}
        </GridContainer>
        <GridContainer style={{ marginBottom: 12 }}>
          {locationData.country && divisions?.length > 0 && locationData.country.has_division == 1 && (
            <>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  autoHighlight
                  classes={{
                    option: classes.option,
                  }}
                  options={divisions}
                  getOptionLabel={option => option.name}
                  value={locationData.division}
                  renderInput={params => (
                    <AppTextInput
                      {...params}
                      label={<IntlMessages id="choose.a.state" />}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      dispatch(getDivisionCitiesService(['division_id', value.id]));
                      setLocationData({
                        ...locationData,
                        division: value,
                        city: null,
                        subcity: null,
                      });
                    }
                  }}
                />
              </Grid>
              {locationData.division && cities.length > 0 && (
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    autoHighlight
                    classes={{
                      option: classes.option,
                    }}
                    options={locationData.division ? cities : []}
                    value={locationData.city}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <AppTextInput
                        {...params}
                        label={<IntlMessages id="choose.a.city" />}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                    onChange={(event, value) => {
                      if (value) {
                        dispatch(getSubCitiesService(['city_id', value.id]));
                        setLocationData({
                          ...locationData,
                          city: value,
                          subcity: null,
                        });
                      }
                    }}
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12} sm={6}>
            {locationData.country && cities.length > 0 && !locationData.division && locationData.country.has_division !== 1 && (
              <Autocomplete
                autoHighlight
                classes={{
                  option: classes.option,
                }}
                options={cities}
                value={locationData.city}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <AppTextInput
                    {...params}
                    label={<IntlMessages id="choose.a.city" />}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
                onChange={(event, value) => {
                  if (value) {
                    dispatch(getSubCitiesService(['city_id', value.id]));
                    setLocationData({
                      ...locationData,
                      city: value,
                      subcity: null,
                    });
                  }
                }}
              />
            )}
          </Grid>

          {locationData.city && subCities.length > 0 && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                autoHighlight
                classes={{
                  option: classes.option,
                }}
                options={subCities}
                getOptionLabel={option => option.name}
                value={locationData.subcity}
                renderInput={params => (
                  <AppTextInput {...params} label={<IntlMessages id="choose.a.sub.city" />} variant="outlined" />
                )}
                onChange={(event, value) => {
                  setLocationData({
                    ...locationData,
                    subcity: value,
                  });
                }}
              />
            </Grid>
          )}
        </GridContainer>

        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            {locationData.subcity && (
              <AppTextInput
                variant="outlined"
                label={<IntlMessages id="district" />}
                onBlur={e => setLocationData({ ...locationData, district: e.target.value })}
              />
            )}
          </Grid>
        </GridContainer>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <ThemeProvider theme={themeCancel}>
            <Button variant="contained" color="primary" onClick={onCancelClick}>
              <IntlMessages id="cancel" />
            </Button>
          </ThemeProvider>

          <Box ml={2}>
            <ThemeProvider theme={theme}>
              <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={onSubmitClick}>
                <IntlMessages id="save" />
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
