import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableEditRow } from 'material-table';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getRoomService } from 'services/rooms';
import {
  addCancellationPoliciesDetailService,
  updateCancellationPoliciesDetailService,
  deleteCancellationPoliciesDetailService,
  getCancellationPoliciesServices,
  addCancellationPoliciesServices,
  updateCancellationPoliciesServices,
  deleteCancellationPoliciesServices,
} from 'services/cancellationPoliciesDetail';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Button } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';

const theme = createTheme({
  palette: {
    backgroundColor: orange[400],
  },
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    '&:hover': {
      backgroundColor: orange[500],
    },
  },
}))(Button);

const useStyles = makeStyles(theme => ({
  root: {
    padding: 1,
  },
  disabledInput: {
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: localStorage.getItem('theme-type') === 'dark' ? 'white' : 'black',
    },
  },
}));

const MySwal = withReactContent(Swal);
//For keyboard date picker
const date = new Date();
const today = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

function CancellationPolicies() {
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    dispatch(getCancellationPoliciesServices());
    dispatch(getRoomService());
  }, [dispatch]);

  const { cancellationPolicies } = useSelector(({ cancellationPoliciesDetail }) => cancellationPoliciesDetail);
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);

  const [isAddClick, setIsAddClick] = useState(true);

  const clickEdit = () => {
    setIsAddClick(!isAddClick);
  };

  let isAuthAdd = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.post === 1);
  let isAuthUpdate = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.put === 1);
  let isAuthDelete = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.delete === 1);
  let isAuthView = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.get === 1);

  const [columns, setColumns] = React.useState([
    {
      title: <IntlMessages id="code" />,
      field: 'code',
    },
    {
      title: <IntlMessages id="name" />,
      field: 'name',
    },
  ]);
  return (
    <MaterialTable
      title={<IntlMessages id="cancellation.policies" />}
      columns={columns}
      data={Object.values(cancellationPolicies)}
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 20, 30, 40],
        search: false,
        actionsColumnIndex: -1,
        tableLayout: 'auto',
        addRowPosition: 'first',
      }}
      detailPanel={rowData =>
        isAddClick && (isAuthAdd || isAuthView) ? <CancellationPoliciesDetail rowData={rowData} /> : null
      }
      onRowClick={(event, rowData, togglePanel) => {
        return isAddClick && (isAuthAdd || isAuthView) ? togglePanel() : null;
      }}
      actions={[
        isAuthAdd
          ? undefined
          : {
              icon: 'add',
              disabled: true,
              position: 'toolbar',
              tooltip: 'You are not authorized',
            },
      ]}
      icons={{
        Add: props => (
          <ThemeProvider theme={theme}>
            <ColorButton variant="contained" color="backgroundColor" onClick={clickEdit} startIcon={<AddBoxIcon />}>
              <IntlMessages id="add" />
            </ColorButton>
          </ThemeProvider>
        ),

        Edit: () => <ModeEditIcon style={{ color: 'black' }} onClick={clickEdit} />,
      }}
      editable={{
        deleteTooltip: row => (isAuthDelete ? 'Delete' : 'You are not authorized'),
        editTooltip: row => (isAuthUpdate ? 'Update' : 'You are not authorized'),
        isDeletable: row => (isAuthDelete ? true : false),
        isEditable: row => (isAuthUpdate ? true : false),
        onRowAdd: isAuthAdd
          ? newData =>
              new Promise(resolve => {
                setTimeout(() => {
                  setIsAddClick(true);
                  if (newData.code && newData.name) {
                    if (
                      Object.values(cancellationPolicies).filter(canPol => canPol.code === newData.code.toUpperCase())
                        .length == 0 &&
                      Object.values(cancellationPolicies).filter(canPol => canPol.name === newData.name.toUpperCase())
                        .length == 0
                    ) {
                      newData.code = newData.code.toUpperCase();
                      newData.name = newData.name.toUpperCase();
                      //Save cancellation policies table
                      dispatch(addCancellationPoliciesServices(newData));
                      resolve();
                    } else {
                      MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text:
                          intl.formatMessage({ id: 'cancellation.policy' }) +
                          intl.formatMessage({ id: 'is.already.exists' }),
                      });
                      resolve();
                    }
                  } else {
                    MySwal.fire('Oops...', intl.formatMessage({ id: 'please.fill.in.all.fields' }), 'error');
                    resolve();
                  }
                }, 1000);
              })
          : undefined,
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              setIsAddClick(true);
              if (newData.code && newData.name) {
                if (
                  Object.values(cancellationPolicies).filter(canPol => canPol.code === newData.code.toUpperCase()).length ==
                    0 &&
                  Object.values(cancellationPolicies).filter(canPol => canPol.name === newData.name.toUpperCase()).length ==
                    0
                ) {
                  newData.code = newData.code.toUpperCase();
                  newData.name = newData.name.toUpperCase();
                  dispatch(updateCancellationPoliciesServices(newData, oldData));
                  resolve();
                } else {
                  MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text:
                      intl.formatMessage({ id: 'cancellation.policy' }) + intl.formatMessage({ id: 'is.already.exists' }),
                  });
                  resolve();
                }
              } else {
                MySwal.fire('Oops...', intl.formatMessage({ id: 'please.fill.in.all.fields' }), 'error');
                resolve();
              }
            }, 1000);
          }),
        onRowUpdateCancelled: () => {
          setIsAddClick(true);
        },
        onRowAddCancelled: () => {
          setIsAddClick(true);
        },
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              dispatch(deleteCancellationPoliciesServices(oldData.id));
              resolve();
            }, 1000);
          }),
      }}
    />
  );
}
export default CancellationPolicies;

function CancellationPoliciesDetail(props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { cancellationPoliciesDetail } = useSelector(({ cancellationPoliciesDetail }) => cancellationPoliciesDetail);
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  let lastElementIndex;

  const isAuthAdd = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.post === 1);
  const isAuthUpdate = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.put === 1);
  const isAuthDelete = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.delete === 1);
  const classes = useStyles();

  //Find each accordion's last element for disable delete button
  lastElementIndex = props.rowData?.cancellation_policies_detail.at(-1);

  const calculateValueFrom = data => {
    data = Object.values(data).filter(item => item?.cancellation_policies_id === props.rowData.id);
    if (!data || !data.length) {
      return 0;
    }
    return Number(data.at(-1).day_to) + 1;
  };
  const validateValueTo = (data, rowData) => {
    data = Object.values(data).filter(item => item?.cancellation_policies_id === props.rowData.id);

    const sortData = data.sort((a, b) => a.id - b.id);

    // For first row
    if (sortData.length === 0) {
      return true;
    }

    // For Added Row
    if (!rowData.id) {
      if (sortData.at(-1).day_to >= rowData.day_to) {
        return false;
      }
      return true;
    }

    // For Edited Row
    const index = sortData.findIndex(item => item.id === rowData.id);

    if (sortData[index - 1] && sortData[index - 1].day_to >= rowData.day_to) {
      return 'Value to must be greater than value from';
    }
    /* if (index == 0 && rowData.day_to >= sortData[index + 1]?.day_to) {
      return false;
    } */

    if (sortData[index + 1] && sortData[index + 1].day_to <= rowData.day_to) {
      return 'Value to must be less than value from';
    }
    return true;
  };

  const tableColumns = [
    {
      title: <IntlMessages id="day.from" />,
      field: 'day_from',
      initialEditValue: `${calculateValueFrom(cancellationPoliciesDetail)}`,
      render: rowData => Number(rowData.day_from).toLocaleString(),
      editComponent: props => (
        <TextField
          id="dayFrom"
          // label={<IntlMessages id="day.from" />}
          size="small"
          type="number"
          disabled
          value={props.value < 0 ? '' : props.value}
          onChange={e => {
            props.onChange(e.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.disabledInput}
          inputProps={{
            disableUnderline: false,
            style: {
              color: localStorage.getItem('theme-type') === 'dark' ? 'white' : 'black',
            },
          }}
        />
      ),
    },
    {
      title: <IntlMessages id="day.to" />,
      field: 'day_to',
      validate: rowData => validateValueTo(cancellationPoliciesDetail, rowData),
      render: rowData => Number(rowData.day_to).toLocaleString(),
      editComponent: props => (
        <TextField
          id="DayTo"
          // label={<IntlMessages id="day.to" />}
          size="small"
          type="number"
          value={props.value < 0 ? '' : props.value}
          onChange={e => {
            props.onChange(e.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            style: {
              color: localStorage.getItem('theme-type') === 'dark' ? 'white' : 'black',
            },
          }}
        />
      ),
    },
    {
      title: <IntlMessages id="percent" />,
      field: 'percentage',
      validate: rowData => rowData.percentage >= 0 && rowData.percentage <= 100,

      editComponent: props => (
        <TextField
          // label={<IntlMessages id="percent" />}
          type="number"
          size="small"
          value={props.value}
          variant="outlined"
          error={props.value < 0 || props.value > 100}
          onChange={e => {
            props.onChange(e.target.value);
          }}
          style={{ width: '50%' }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
            inputProps: {
              min: 0,
              max: 100,
            },
            style: {
              color: localStorage.getItem('theme-type') === 'dark' ? 'white' : 'black',
            },
          }}
        />
      ),
    },
  ];
  return (
    <>
      <MaterialTable
        className={classes.root}
        title={<IntlMessages id="cancellation.policies.detail" />}
        columns={tableColumns}
        data={Object.values(cancellationPoliciesDetail).filter(elem => elem.cancellation_policies_id == props.rowData.id)}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40],
          search: false,
          actionsColumnIndex: -1,
          tableLayout: 'auto',
          addRowPosition: 'first',
        }}
        components={{
          EditRow: props => (
            <MTableEditRow
              {...props}
              onKeyDown={e => {
                if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                  e.preventDefault();
                }
              }}
            />
          ),
          onRowAdd: props => (
            <MTableEditRow
              {...props}
              onKeyDown={e => {
                if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                  e.preventDefault();
                }
              }}
            />
          ),
        }}
        actions={[
          !isAuthAdd && {
            icon: 'add',
            disabled: true,
            position: 'toolbar',
            tooltip: 'You are not authorized',
          },
        ]}
        /* delete_outline={{
        Add: props => (
          <ThemeProvider theme={theme}>
            <Button variant="contained" color="primary" startIcon={<AddBoxIcon />}>
              Add
            </Button>
          </ThemeProvider>
        ),
      }} */
        icons={{
          Add: props =>
            isAuthAdd && (
              <ThemeProvider theme={theme}>
                <ColorButton variant="contained" color="backgroundColor" startIcon={<AddBoxIcon />}>
                  <IntlMessages id="add" />
                </ColorButton>
              </ThemeProvider>
            ),
        }}
        editable={{
          deleteTooltip: row => (isAuthDelete ? 'Delete' : 'You are not authorized'),
          editTooltip: row => (isAuthUpdate ? 'Update' : 'You are not authorized'),
          isDeletable: rowData =>
            isAuthDelete
              ? rowData?.tableData?.id == cancellationPoliciesDetail[lastElementIndex]?.tableData?.id
                ? true
                : false
              : false,
          isEditable: row => (isAuthUpdate ? true : false),

          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                newData['cancellation_policies_id'] = props.rowData.id;
                if (Number(newData.day_from) !== Number(newData.day_to)) {
                  //Save cancellation policies detail table
                  dispatch(addCancellationPoliciesDetailService(newData));
                  MySwal.fire({
                    icon: 'success',
                    title: 'Success...',
                    text:
                      intl.formatMessage({ id: 'cancellation.policy.detail' }) +
                      intl.formatMessage({ id: 'created.successfully' }),
                  });
                } else {
                  MySwal.fire({
                    icon: 'error',
                    title: 'Error...',
                    text: intl.formatMessage({ id: 'from.date.and.to.date.cant.be.same' }),
                  });
                  reject();
                }
                resolve();
              }, 1000);
            }),

          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                if (Number(newData.day_from) !== Number(newData.day_to)) {
                  dispatch(updateCancellationPoliciesDetailService(newData, oldData));
                  MySwal.fire({
                    icon: 'success',
                    title: 'Success...',
                    text:
                      intl.formatMessage({ id: 'cancellation.policy.detail' }) +
                      intl.formatMessage({ id: 'updated.successfully' }),
                  });
                } else {
                  MySwal.fire({
                    icon: 'error',
                    title: 'Error...',
                    text: intl.formatMessage({ id: 'from.date.and.to.date.cant.be.same' }),
                  });
                  reject();
                }
                resolve();
              }, 1000);
            }),

          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                dispatch(deleteCancellationPoliciesDetailService(oldData.id));
                MySwal.fire({
                  icon: 'success',
                  title: 'Success...',
                  text:
                    intl.formatMessage({ id: 'cancellation.policy.detail' }) +
                    intl.formatMessage({ id: 'deleted.successfully' }),
                });
                resolve();
              }, 1000);
            }),
        }}
      />
    </>
  );
}
