import React, { useEffect, useState } from 'react';
import { makeStyles, lighten } from '@material-ui/core/styles';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { addContractService, updateContractService } from 'services/contract';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import SelectAll from './SelectAll';
import { isSavedConract } from 'redux/actions/contract';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { getAdminCompaniesService } from 'services/company';
import { green, purple } from '@material-ui/core/colors';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  Button,
  FormControl,
  TextField,
  MenuItem,
  Card,
  CardContent,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  InputLabel,
} from '@material-ui/core';
import { AgeNumberFormatCustom } from '../Common/NumberFormat/NumberFormat';
import moment from 'moment';
import { nonEnterprise, companyData, officeMemo } from 'redux/selector/companyHasOfficeSelector';
import _ from 'lodash';
import { useIntl } from 'react-intl';
// import { contractHasBoard } from 'redux/selector/contract';
import { CHECK_CONTRACT_DATA_IS_UPDATE } from 'redux/actions/types';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { MultiSelect } from 'react-multi-select-component';
import { multiDataSetter } from '@jumbo/utils/commonHelper';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ContractSeason(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(isSavedConract(''));
    dispatch(getAdminCompaniesService());
  }, [dispatch]);
  //dropdownlarda görüntülenmesi g0ereken veriler
  const filter = createFilterOptions();
  const { rateTypes } = useSelector(({ rateTypes }) => rateTypes);
  const { boards } = useSelector(({ boards }) => boards);
  const { seasons } = useSelector(({ seasons }) => seasons);
  const { operators } = useSelector(({ operators }) => operators);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { currencies } = useSelector(({ currencies }) => currencies);
  const { markets } = useSelector(({ markets }) => markets);
  const { marketCountries } = useSelector(({ markets }) => markets);
  const { authUser } = useSelector(({ auth }) => auth);
  const { allotmentType } = useSelector(({ allotmentType }) => allotmentType);
  const { selectedContract } = useSelector(({ contract }) => contract);
  const { board } = useSelector(({ contract }) => contract);
  const { contract_has_board } = useSelector(({ contract }) => contract);
  const { market } = useSelector(({ contract }) => contract);
  const { contracts } = useSelector(({ contract }) => contract);
  const { countries } = useSelector(({ contract }) => contract);
  const { entities } = useSelector(({ companyHasOffice }) => companyHasOffice);
  const onlyCompany = useSelector(nonEnterprise);
  const objectCompany = useSelector(companyData);
  const objectOffice = useSelector(officeMemo);
  const theme = createTheme({
    palette: {
      primary: green,
      secondary: purple,
    },
  });
  //tarihler için default değerler
  const [selectedStartDate, handleStartDateChange] = useState(new Date());
  const [selectedEndDate, handleEndDateChange] = useState(selectedStartDate);
  const [contractInfo, setContractInfo] = useState(selectedContract != 0 ? contracts[selectedContract] : {});
  const [hotelInformation, setHotelInformation] = useState({});
  const [tempMarkets, setTempMarkets] = useState([]);
  const [allCountries, setAllCountries] = useState(selectedContract != 0 ? Object.values(countries) : []);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [operatorState, setOperatorState] = useState([]);
  const [boardState, setBoardState] = useState([]);
  const [rateTypeState, setRateTypeState] = useState([]);
  const [allotmentTypeState, setAllotmentTypeState] = useState([]);
  const [currenciesState, setCurrenciesState] = useState([]);
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  const [companyInfo, setCompanyInfo] = useState([]);
  const [hotelCache, setHotelCache] = useState([]);
  const [hotelData, setHotelData] = useState([]);
  // const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [selectedCompany, setSelectedCompany] = useState();
  const [updateButtonDisable, setUpdateButtonDisable] = useState(true);
  const contractStatusControl = contracts[selectedContract]
    ? contracts[selectedContract].contract_statuses_id == 1 || contracts[selectedContract].contract_statuses_id == 5
      ? true
      : false
    : true;
  const intl = useIntl();
  const MySwal = withReactContent(Swal);
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: '',
      text: text,
    });
  };

  let confilictCountries = '';
  let confilictOpertors = '';
  let isAuthAdd = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.post === 1);
  let isAuthUpdate = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.put === 1);

  useEffect(() => {
    if (onlyCompany.length != 0) {
      if (authUser.authority_level == 10) {
        setCompanyInfo(onlyCompany);
        //Sadece 1 tane company varsa seçim yapmasın otomatik olarak o company seçili gelsin
        if (onlyCompany.length == 1) {
          setSelectedCompany(onlyCompany[0]);
        }
      } else if (authUser.authority_level == 20 || authUser.authority_level == 30 || authUser.authority_level == 11) {
        setSelectedCompany(objectCompany[parseInt(localStorage.selectedCompany)]);
      }
      /* else if (authUser.authority_level == 30) {
        setSelectedCompany(objectCompany[parseInt(localStorage.selectedCompany)]);
      } */
    }
  }, [onlyCompany]);

  useEffect(() => {
    dispatch({
      type: CHECK_CONTRACT_DATA_IS_UPDATE,
      payload: updateButtonDisable,
    });
  }, [updateButtonDisable]);

  useEffect(() => {
    /* 
    OTELLERİN USER SEVİYELERİNE GÖRE GELMESİ GEREKİYOR
    1-) ENTERPRİSE BİR USER (level = 10) COMPANY SEÇİMİ YAPACAK. SEÇİLİ OLAN COMPANYNİN DESTİNATİONLARINDA OLAN OTELLERİ LİSTELEYEBİLECEK.
        OFFİCELERE DESTİNATİON ATANDIĞI İÇİN COMPANYNİN DESTİNATİONLARI ONUN BÜTÜN OFFİCELERİNİN DESTİNATİONLARI OLACAK. BUNUN İÇİN SEÇİLİ 
        OLAN COMPANYNİN BÜTÜN OFFİCELERİ FOR DÖNÜLÜP COMPANYNİN DESTİNATİONU ELDE EDİLECEK. DAHA SONRA HOTELLERDE BU DESTİNATİON BİLGİLERİNE 
        DENK GELEN HOTELLER FİLTRELENECEK. 
        (FİLTRELEME KIRILIMI SEEDERLAR EKSİK OLDUĞU İÇİN ŞİMDİLİK ÜLKEYE KADAR KONTROL EDİLİYOR DÜZELİNCE CİTYE KADAR EKLENECEK)
    2-) ENTERPRİSE BİR COMPANYNİN COMPANY SEVİYESİNDEKİ BİR USERI (level 20) EĞER TEK BİR COMPANYDE ÇALIŞMIYORSA GİRİŞ YAPTIKTAN SONRA SEÇMİŞ
        OLDUĞU COMPANY ÜZERİNDEN İŞLEM YAPABİLİR. CONTRACT EKLERKEN COMPANY DEĞİŞTİRMEK İSTERSEN SİDEBARDAN DEĞİŞTİRECEK.
        SEÇİLİ GELEN COMPANYNİN ENTERPRİSEDAKİ GİBİ BÜTÜN OFFİCELERİ DÖNÜLÜP ONUN DESTİNATİONA GÖRE HOTELLER FİLTRELENECEK. MADDE 1 DEKİ GİBİ
    3-) OFFİCE LEVEL USERLAR (LEVEL 30) GİRİŞ YAPTIKTAN SONRA SEÇMİŞ OLDUKLARI COMPANY, COMPANY BÖLÜMÜNE DÜŞECEK.MADDE 2 DEKİ GİBİ COMPANY DEĞİŞTİRMEK
        İSTERSE SİDEBARDAN DEĞİŞTİRECEK. OFFİCE USERI GİRİŞ YAPTIKTAN SONRA OFFİCEDE SEÇTİĞİ İÇİN O SEÇİLİ OLAN OFFİCENİN DESTİNATİONLARINA GÖRE
        HOTELLER FİLTRELENECEK
    4-) COMPANY BİR COMPANYNİN COMPANY LEVEL USERI (LEVEL 11). TEK BİR COMPANYSİ OLDUĞU İÇİN O COMPANY OTOMATİK OLARAK DÜŞECEK. FARKLI BİR COMPANY
        OLMADIĞI İÇİN OTELLERDE HERHANGİ BİR FİLTRELEME OLMADAN DİREK SİSTEME DÜŞECEK 
    */
    setContractInfo({
      ...contractInfo,
    });
    if (selectedCompany && selectedContract == 0) {
      let companyDestination = {};
      let mergeCompany = {};
      let filteredHotel = [];
      if (authUser.authority_level == 10 || authUser.authority_level == 20 || authUser.authority_level == 11) {
        if (!hotelCache[selectedCompany.id]) {
          selectedCompany.offices.map(element => {
            if (objectOffice[element].get_destination?.destinations) {
              if (companyDestination[0]) {
                mergeCompany = _.merge(
                  companyDestination[0],
                  JSON.parse(objectOffice[element].get_destination.destinations),
                );
                companyDestination[0] = mergeCompany;
              } else {
                companyDestination[0] = JSON.parse(objectOffice[element].get_destination.destinations);
              }
            }
          });
          filteredHotel = Object.values(hotels).filter(
            element =>
              companyDestination[0]['regionID'][element.region_id] &&
              companyDestination[0]['regionID'][element.region_id]['countryID'][element.country_id],
          );
          setHotelCache({ ...hotelCache, [selectedCompany.id]: filteredHotel });
          setHotelData(filteredHotel);
        } else {
          setHotelData(hotelCache[selectedCompany.id]);
        }

        if (filteredHotel.length == 0) {
          setContractInfo({ ...contractInfo, hotel_id: null });
          setHotelInformation({});
        }
      } else if (authUser.authority_level == 30) {
        companyDestination[0] = JSON.parse(
          objectOffice[parseInt(localStorage.getItem('selectedOffice'))]['get_destination']['destinations'],
        );

        filteredHotel = Object.values(hotels).filter(
          element =>
            companyDestination[0]['regionID'][element.region_id] &&
            companyDestination[0]['regionID'][element.region_id]['countryID'][element.country_id],
        );
        setHotelData(filteredHotel);

        if (filteredHotel.length == 0) {
          setContractInfo({ ...contractInfo, hotel_id: null });
          setHotelInformation({});
        }
      }
      /* else if (authUser.authority_level == 20) {
        if (!hotelCache[selectedCompany.id]) {
        selectedCompany.offices.map(element => {
          if (objectOffice[element].get_destination?.destinations) {
            if (companyDestination[0]) {
              mergeCompany = _.merge(companyDestination[0], JSON.parse(objectOffice[element].get_destination.destinations));
              companyDestination[0] = mergeCompany;
            } else {
              companyDestination[0] = JSON.parse(objectOffice[element].get_destination.destinations);
            }
          }
        });
        filteredHotel = Object.values(hotels).filter(
          element =>
            companyDestination[0]['regionID'][element.region_id] &&
            companyDestination[0]['regionID'][element.region_id]['countryID'][element.country_id],
        );
        setHotelCache({ ...hotelCache, [selectedCompany.id]: filteredHotel });
        setHotelData(filteredHotel);
        } else {
          setHotelData(hotelCache[selectedCompany.id]);
        }

        if (filteredHotel.length == 0) {
          setContractInfo({ ...contractInfo, hotel_id: null });
          setHotelInformation({});
        }
      } */
    } else {
      if (hotelData && selectedContract == 0) {
        setHotelData([]);
        setHotelInformation({});
      }
    }
  }, [selectedCompany]);

  const setContractCountriesState = market => {
    // let tempMarkets = [];
    // let ids = [];
    // let tempCountries = [];
    // tempMarkets.push(market);
    // tempMarkets.forEach(tempMarket => {
    //   tempMarket.map(element => {
    //     ids.push(element.id);
    //     if (selectedContract != 0 && element.countries) {
    //       element.countries.map(country => {
    //         tempCountries = tempCountries.concat(marketCountries[country]);
    //       });
    //     } else {
    //       if (element.countries) {
    //         element.countries.map(country => {
    //           tempCountries = tempCountries.concat(marketCountries[country]);
    //         });
    //       }
    //     }
    //   });
    //   tempCountries = tempCountries.filter((value, index) => {
    //     const _value = JSON.stringify(value);
    //     return (
    //       index ===
    //       tempCountries.findIndex(obj => {
    //         return JSON.stringify(obj) === _value;
    //       })
    //     );
    //   });
    // });
    // setAllCountries(tempCountries);
    // return ids;

    const ids = market?.map(k => k.id);
    const baseCountries = Object.values(marketCountries);
    let selectCountries = [];
    for (const v of market) {
      for (const l of v.countries) {
        if (!selectCountries.find(t => t === l)) selectCountries.push(l);
      }
    }
    setAllCountries(baseCountries.map(k => (selectCountries.includes(k.id) ? k : false)).filter(k => k !== false));
    return ids;
  };
  useEffect(() => {
    if (selectedContract != 0) {
      let tempOperators = [];
      let tempBoards = [];
      let tempRateTypes = [];
      let tempMarkets = [];
      let board = [];
      if (contractInfo.max_child_age != null || contractInfo.max_infant_age != null) {
        contracts[selectedContract].contract_has_board.map(b => {
          board.push(contract_has_board[b]?.board_id);
        });
        setContractInfo({
          ...contractInfo,
          // checkChild: false,
          // disabledChild: false,
          // disabledInf: false,
          // checkInf: false,
          // include_countries: contractInfo.contractCountries,
          include_countries: contractInfo.contractCountries?.filter((v, i, a) => a.findIndex(t => t.id == v.id) == i),
          boards: Object.values(board),
        });
      } else {
        setContractInfo({
          ...contractInfo,
          // checkChild: true,
          // disabledChild: true,
          // disabledInf: true,
          // checkInf: true,
          // include_countries: contractInfo.contractCountries,
          include_countries: contractInfo.contractCountries?.filter((v, i, a) => a.findIndex(t => t.id == v.id) == i),
          boards: Object.values(board),
        });
      }

      setSelectedCompany(objectCompany[contractInfo.company_id]);
      //setHotelInformationState(hotels[contractInfo.hotel_id]);
      setHotelInformation(hotels[contractInfo.hotel_id]);
      //  setContractInfo({ ...contractInfo, include_countries: Object.values(countries) });
      contractInfo.operators.map(op => {
        tempOperators.push(operators[op]);
      });
      contractInfo.contract_has_board.map(b => {
        tempBoards.push(boards[contract_has_board[b].board_id]);
      });
      contractInfo.rate_types.map(r => {
        tempRateTypes.push(rateTypes[r]);
      });
      contractInfo.market.map(m => {
        if (tempMarkets.indexOf(m) == -1) {
          tempMarkets.push(market[m]);
        }
      });
      setOperatorState(tempOperators);
      //tempBoards.shift()
      setBoardState(tempBoards);
      setRateTypeState(tempRateTypes);
      setAllotmentTypeState(allotmentType[contractInfo.allotment_type_id]);
      setCurrenciesState(currencies[contractInfo.currency]);
      setTempMarkets(tempMarkets);
    }
  }, [hotels]);

  const handleOnChange = (name, value) => {
    setUpdateButtonDisable(false);
    let tempOperators = [];
    let tempBoards = [];
    let tempRateTypes = [];
    if (name == 'hotel_id') {
      //setHotelInformationState(value);
      setHotelInformation(value);
      setContractInfo({ ...contractInfo, [name]: value.id });
    } else if (name == 'market') {
      let ids = setContractCountriesState(value);
      let temp_include_countries = [];
      value.map(market => {
        if (contractInfo.include_countries) {
          contractInfo.include_countries.map(include_country => {
            if (market.countries.includes(include_country.id)) {
              temp_include_countries.push(include_country);
            }
          });
        }
      });
      let inc_countries = [];
      inc_countries = temp_include_countries.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          temp_include_countries.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
      setTempMarkets(value);

      setContractInfo({
        ...contractInfo,
        [name]: ids,
        include_countries: inc_countries,
        operators: [],
      });
      setOperatorState([]);
    } else if (name == 'operators') {
      value.map(val => {
        tempOperators.push(val.id);
      });
      setOperatorState(value);
      setContractInfo({ ...contractInfo, [name]: tempOperators });
    } else if (name == 'boards') {
      value.map(val => {
        tempBoards.push(val.id);
      });
      setBoardState(value);
      setContractInfo({ ...contractInfo, [name]: tempBoards });
    } else if (name == 'rate_types') {
      value.map(val => {
        tempRateTypes.push(val.id);
      });
      setRateTypeState(value);
      setContractInfo({ ...contractInfo, [name]: tempRateTypes });
    } else if (name == 'allotment_type_id') {
      setAllotmentTypeState(value);
      setContractInfo({ ...contractInfo, [name]: value.id });
    } else if (name == 'currency') {
      setCurrenciesState(value);
      setContractInfo({ ...contractInfo, [name]: value.id });
    } else if (name == 'season_id') {
      setContractInfo({
        ...contractInfo,
        [name]: value,
        start_date: seasons[value]?.start_date,
        end_date: seasons[value]?.end_date,
      });
    } else if (name == 'company_id') {
      //setCompanyInformationState(value);
      setSelectedCompany(objectCompany[value]);
      setContractInfo({ ...contractInfo, [name]: value });
    } else if (name == 'country') {
      setUpdateButtonDisable(false);
      setContractInfo({
        ...contractInfo,
        include_countries: value,
        operators: [],
      });
      setOperatorState([]);
    } else {
      setContractInfo({ ...contractInfo, [name]: value });
    }
  };
  /* ülkeler için allSelect buradan*/
  // const handleToggleOption = selectedOptions => {
  //   setContractInfo({ ...contractInfo, include_countries: selectedOptions });
  //   setSelectedOptions(selectedOptions);
  // };
  // const handleClearOptions = () => {
  //   setSelectedOptions([]);
  //   setContractInfo({ ...contractInfo, include_countries: [] });
  // };
  // const handleSelectAll = isSelected => {
  //   if (isSelected) {
  //     setSelectedOptions(allCountries);
  //   } else {
  //     setContractInfo({ ...contractInfo, include_countries: selectedOptions });

  //     handleClearOptions();
  //   }
  // };
  // const handleToggleSelectAll = () => {
  //   handleSelectAll && handleSelectAll(!allSelected);
  // };
  // const handleChangeForCountries = (event, selectedOptions, reason, selectedOption) => {
  //   if (reason === 'select-option' || reason === 'remove-option') {
  //     const selectAll = selectedOptions.find(option => option.value === 'select-all');
  //     if (selectedOption?.option?.name === 'Select All' && allCountries.length <= selectedOptions.length) {
  //       setContractInfo({ ...contractInfo, include_countries: [] });
  //       setSelectedOptions([]);
  //     } else {
  //       if (selectAll) {
  //         handleToggleSelectAll();
  //         let result = [];
  //         result = allCountries.filter(el => el.value !== 'select-all');
  //         return setContractInfo({
  //           ...contractInfo,
  //           include_countries: result,
  //         });
  //       } else {
  //         handleToggleOption && handleToggleOption(selectedOptions);
  //         return setContractInfo({
  //           ...contractInfo,
  //           include_countries: selectedOptions,
  //         });
  //       }
  //     }
  //   } else if (reason === 'clear') {
  //     handleClearOptions && handleClearOptions();
  //   }
  // };
  /* ülkeler için allSelect buraya*/
  const handleChange = event => {
    setUpdateButtonDisable(false);
    if (event.target.type === 'checkbox' && event.target.name == 'checkChild') {
      if (event.target.name === 'checkChild' && event.target.checked == true) {
        setContractInfo({
          ...contractInfo,
          [event.target.name]: event.target.checked,
        });
      } else {
        setContractInfo({
          ...contractInfo,
          [event.target.name]: event.target.checked,
          // disabledChild: event.target.checked,
          // disabledInf: event.target.checked,
          ageInf: 0,
        });
      }
    } else if (event.target.type === 'checkbox') {
      setContractInfo({
        ...contractInfo,
        [event.target.name]: event.target.checked,
      });
    } else {
      setContractInfo({
        ...contractInfo,
        [event.target.name]: event.target.value,
      });
    }
  };
  const dateConfilict = (old_start_date, old_end_date, new_start_date, new_end_date) => {
    return (
      moment(new_start_date).isBetween(old_start_date, old_end_date) ||
      moment(new_start_date).isSame(old_end_date) ||
      moment(new_start_date).isSame(old_start_date) ||
      moment(new_end_date).isBetween(old_start_date, old_end_date) ||
      moment(new_end_date).isSame(old_start_date) ||
      moment(new_end_date).isSame(old_end_date)
    );
  };
  const isSame = (oldContract, newContract) => {
    const isDateConfilict = dateConfilict(
      oldContract.start_date,
      oldContract.end_date,
      newContract.start_date,
      newContract.end_date,
    );

    // let isTheMarketInside = false;
    // newContract.market.map(element => {
    //   if (oldContract.market.includes(element)) {
    //     // return true;
    //     isTheMarketInside = true;
    //   } //else return false;
    // });
    let isTheCountryInside = false;
    newContract.include_countries.forEach(element => {
      if (oldContract.contractCountries.findIndex(oldCountry => oldCountry.id === element.id) !== -1) {
        confilictCountries = confilictCountries + element.name + ', ';
        isTheCountryInside = true;
      } //else return false;
    });

    let isTheOperatorInside = false;
    newContract.operators.forEach(element => {
      if (oldContract.operators.includes(element)) {
        confilictOpertors = confilictOpertors + operators[element].code + ', ';
        isTheOperatorInside = true;
        // return true;
      } /* else return false; */
    });
    if (
      oldContract.company_id === newContract.company_id &&
      oldContract.season_id === newContract.season_id &&
      oldContract.hotel_id === newContract.hotel_id
    ) {
      if (isDateConfilict) {
        if (/* isTheMarketInside && */ isTheCountryInside && isTheOperatorInside) {
          return true;
        } else if (
          /* !isTheMarketInside &&  */ (!isTheCountryInside && isTheOperatorInside) ||
          /* isTheMarketInside &&  */ (isTheCountryInside && !isTheOperatorInside)
        ) {
          return false;
        } else return false;
      } else return false;
    } else {
      return false;
    }
  };
  const saveAndUpdateSeason = () => {
    if (
      contractInfo.hasOwnProperty('base_board_id') &&
      contractInfo.base_board_id > 0 &&
      contractInfo.hasOwnProperty('rate_types') &&
      contractInfo.rate_types.length > 0 &&
      contractInfo.hasOwnProperty('max_child_age') &&
      contractInfo.max_child_age != '' &&
      contractInfo.min_child_age != null &&
      contractInfo.hasOwnProperty('max_child_age') &&
      contractInfo.min_child_age != '' &&
      contractInfo.min_child_age != null &&
      ((!contractInfo.hasOwnProperty('checkChild') && contractInfo.max_infant_age != '') ||
        contractInfo.checkChild == true ||
        (contractInfo.checkChild == false && contractInfo.max_infant_age != ''))
    ) {
      let filteredContracts = contracts;
      let isThereContract = false;
      contractInfo['company_id'] = selectedCompany?.id;
      if (contractInfo.hasOwnProperty('id')) {
        //güncellenen bir kontrat ise kıyaslamaya gerek yok. Güncellenen filtrelendi.
        filteredContracts = Object.values(contracts).filter(i => i.id !== contractInfo.id);
      }
      if (filteredContracts) {
        Object.values(filteredContracts).map(oldContract => {
          if (isSame(oldContract, contractInfo)) {
            isThereContract = true;
            confilictCountries = confilictCountries.slice(0, -2);
            confilictOpertors = confilictOpertors.slice(0, -2);
            sweetAlerts(
              'warning',
              (confilictOpertors.length > 0
                ? confilictOpertors + (confilictOpertors.split(',').length > 1 ? ' coded operators' : ' coded operator')
                : '') +
                (confilictCountries.length > 0
                  ? ' and ' + confilictCountries + (confilictCountries.split(',').length > 1 ? ' countries ' : ' country ')
                  : '') +
                oldContract.contract_name +
                ' available for coded contract. Change operator or country and try again!',
            );
            confilictCountries = '';
            confilictOpertors = '';
          } /* else return (isThereContract = false); */
        });
      }
      if (!isThereContract) {
        if (
          contractInfo.hasOwnProperty('max_infant_age') &&
          contractInfo.hasOwnProperty('max_child_age') &&
          Number(contractInfo.max_infant_age) >= Number(contractInfo.max_child_age)
        ) {
          sweetAlerts('warning', 'Child age cannot be younger than infant age!');
        } else if (
          (contractInfo.hasOwnProperty('max_infant_age') &&
            contractInfo.hasOwnProperty('max_child_age') &&
            contractInfo.max_child_age > 20) ||
          contractInfo.max_infant_age > 20
        ) {
          sweetAlerts('warning', 'Child age and infant age cannot be older than 20!');
        } else if (moment(contractInfo.start_date).isAfter(contractInfo.end_date)) {
          sweetAlerts('warning', 'Start date cannot be later than end date!');
        } else if (contractInfo.start_date == 'Invalid date' || contractInfo.end_date == 'Invalid date') {
          sweetAlerts('warning', 'Please enter a valid date!');
        } else {
          let tempMarketsData = [];
          let include_countries = [];
          let exclude_countries = [];
          if (tempMarkets && contractInfo.include_countries) {
            tempMarkets.map(market => {
              include_countries = [];
              exclude_countries = [];
              ///BURASI DÜZELTİLECEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEKKKKKKKKKKKKK EXCLUDE YANLIŞ ÇALIŞIYO
              contractInfo.include_countries.map(in_country => {
                if (market.countries.includes(in_country.id)) {
                  include_countries.push(in_country);
                } else {
                  exclude_countries.push(countries[in_country.id]);
                }

                // if (market.countries[market.countries.findIndex(i => i === in_country.id)]) {
                //   market.countries.map(ex_country => {

                //     let countryCount = contractInfo.include_countries.filter(country => {
                //       return country.id == ex_country;
                //     });
                //     if (
                //       !countryCount.length > 0 &&
                //       market.countries.includes(ex_country) &&
                //       !exclude_countries.includes(ex_country.id)
                //     ) {
                //       exclude_countries.push(ex_country);
                //     }
                //   });
                //   market.countries.map(in_country => {
                //     let countryCount = contractInfo.include_countries.filter(country => {
                //       return country.id == in_country;
                //     });
                //     if (countryCount > 0 && market.countries.includes(in_country) && !include_countries.includes(in_country.id)) {

                //       include_countries.push(in_country);
                //     }
                //   });
                // }
              });
              tempMarketsData.push({
                market_id: market.id,
                includes: include_countries,
                excludes: exclude_countries,
              });
            });
          }
          contractInfo['market'] = tempMarketsData;
          contractInfo['user'] = authUser;
          contractInfo['start_date'] = contractInfo.start_date;
          contractInfo['end_date'] = contractInfo.end_date;
          // contractInfo['start_date'] = new Date(contractInfo.start_date);
          // contractInfo['end_date'] = new Date(contractInfo.end_date);
          // contractInfo['company'] = selectedCompany?.id;

          let contract_name_temp = '';
          contract_name_temp = seasons[contractInfo.season_id].code;
          Object.values(contractInfo?.market).forEach(
            element => (contract_name_temp = contract_name_temp + '-' + markets[element.market_id].code),
          );
          contract_name_temp = contract_name_temp + '-' + currencies[contractInfo.currency].code;
          contractInfo['contract_name'] = contract_name_temp;
          if (selectedContract != 0) {
            dispatch(updateContractService(contractInfo));
          } else {
            dispatch(addContractService(contractInfo));
          }
          // const emptyValuesState = {};
          // Object.entries(contractInfo).forEach(element => {
          //   if (element[0] === 'rate_types' || element[0] === 'boards' || element[0] === 'market') {
          //     emptyValuesState[element[0]] = [];
          //     setContractInfo({ ...contractInfo, ...emptyValuesState });
          //   } else {
          //     emptyValuesState[element[0]] = '';
          //     setContractInfo({ ...contractInfo, ...emptyValuesState });
          //   }
          // });
          dispatch(isSavedConract('isSaved'));
        }
      }
    } else {
      sweetAlerts('warning', intl.formatMessage({ id: 'please.fill.in.all.fields' }));
    }
  };
  const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: '95%',
      height: '100%',
      margin: '0 auto',
      fontSize: '12px!important',
      margin: '0 auto',
      fontSize: '12px!important',
    },
    formControl: {
      margin: theme.spacing(2),
      fontSize: '12px!important',
      minWidth: '85%',
    },
    rootcard: {
      minWidth: '100%',
      maxWidth: '100%',
      margin: '0 auto',
      fontSize: '12px!important',
      backgroundColor: lighten(theme.palette.background.paper, 0.1),
    },
    title: {
      fontSize: '12px!important',
    },
    formElement: {
      minWidth: '100%',
      fontSize: '10px!important',
      margin: theme.spacing(2),
    },
    container: {
      display: 'flex',
      fontSize: '12px!important',
      flexWrap: 'wrap',
    },
    button: {
      float: 'right',
      margin: theme.spacing(2),
      fontSize: '12px!important',
      maxWidth: '50%',
    },
    contractName: {
      textAlign: 'center',
    },
  }));

  const classes = useStyles();
  const allSelected = allCountries.length === selectedOptions.length;
  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      allCountries.length > 0 && option.value === 'select-all' // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <React.Fragment>
        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} {...selectAllProps} />
        {option.name}
      </React.Fragment>
    );
  };
  const countriesOperator = () => {
    let tempOperator = [];
    Object.values(operators).filter(op => {
      if (contractInfo.include_countries) {
        contractInfo.include_countries.map(country => {
          if (op.countries) {
            op.countries.map(item => {
              if (item.name == country.name) {
                tempOperator.push(op);
              }
            });
          }
        });
      }
    });
    //aynı değerleri çiftlememesi için daha efektif başka bir şey de yapılabilir belki
    let countriesOperator = [];
    if (tempOperator) {
      countriesOperator = tempOperator.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          tempOperator.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
    }
    return countriesOperator; //.sort((a, b) => (a.name > b.name ? 1 : -1)
  };

  /**** Calculate Max Child Age Here****/
  const maxChild = 19.99;
  const maxChildVal = [];

  let maxage = _.range(2.99, maxChild, 1);

  maxage.forEach(param => {
    maxChildVal.push({ age: param.toFixed(2) });
  });
  /*****Calculate Min Child Age****/
  const minChild = 19;
  const minChildVal = [];

  let minage = _.range(2, minChild, 1);

  minage.forEach(param => {
    minChildVal.push({ age: param.toFixed(2) });
  });
  /*************************************/
  const dataControl = () => {
    return contractInfo.hotel_id &&
      contractInfo.season_id &&
      contractInfo.currency &&
      contractInfo.allotment_type_id &&
      contractInfo.operators.length > 0 &&
      contractInfo.hasOwnProperty('include_countries') &&
      contractInfo.include_countries?.length > 0 &&
      contractInfo.boards.length > 0 &&
      contractInfo.market.length > 0
      ? false
      : true;
  };
  return (
    <div className={classes.root}>
      <Card className={classes.root}>
        <CardContent className={classes.rootcard}>
          <Typography sx={{ fontSize: 18 }} className={classes.contractName} color="text.secondary" gutterBottom>
            <b>{contractInfo?.contract_name}</b>
          </Typography>
          <Grid container item xs={12} direction="row">
            <Grid item xs container direction="row" spacing={1}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid item xs={12}>
                        {props.type !== 'edit' ? (
                          <Autocomplete
                            options={companyInfo}
                            freeSolo
                            disabled={
                              authUser?.authority_level == 11 ||
                              authUser?.authority_level == 20 ||
                              authUser?.authority_level == 30
                            }
                            value={selectedCompany ? selectedCompany.name : ''}
                            onChange={(event, value) => {
                              // handleOnChange('company', value ? value?.id : {});
                              handleOnChange('company_id', value ? value?.id : '');
                            }}
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                              <TextField
                                {...params}
                                disabled={
                                  authUser?.authority_level == 11 ||
                                  authUser?.authority_level == 20 ||
                                  authUser?.authority_level == 30
                                }
                                placeholder={intl.formatMessage({
                                  id: 'company',
                                })}
                                //error={!contractInfo.company_id}
                                label={<IntlMessages id="company" />}
                                value={selectedCompany ? selectedCompany.name : ''}
                                InputLabelProps={{
                                  shrink: true,
                                  readOnly: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  value: selectedCompany ? selectedCompany.name : '',
                                }}
                              />
                            )}
                          />
                        ) : (
                          <TextField
                            placeholder={intl.formatMessage({ id: 'company' })}
                            className={classes.formElement}
                            label={<IntlMessages id="company.name" />}
                            value={selectedCompany ? selectedCompany.name : ''}
                            InputLabelProps={{
                              shrink: true,
                              readOnly: true,
                            }}
                          />
                        )}
                      </Grid>
                    </AccordionSummary>

                    <AccordionDetails hidden={selectedCompany == undefined}>
                      <Card className={classes.rootcard} hidden={selectedContract != 0 ? false : contractInfo.hotelInfo}>
                        <CardContent>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <FormControl className={classes.formControl}>
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label={<IntlMessages id="company.name" />}
                                defaultValue="Şirket ismi"
                                value={selectedCompany ? selectedCompany.name : ''}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label={<IntlMessages id="company.taxoffice" />}
                                defaultValue="Tax Ofis & No"
                                value={selectedCompany ? selectedCompany.tax_office : ''}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label={<IntlMessages id="city" />}
                                defaultValue="City"
                                value={selectedCompany ? entities.city[selectedCompany.city_id]?.name : ''}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label={<IntlMessages id="phone.number" />}
                                value={selectedCompany ? selectedCompany.phone : ''}
                                defaultValue="Tel"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label="Fax"
                                defaultValue="Fax"
                                value={selectedCompany ? selectedCompany.tax_no : ''}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label="Email"
                                defaultValue="Email"
                                value={selectedCompany ? selectedCompany.email : ''}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </FormControl>
                          </Typography>
                        </CardContent>
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid item xs={12}>
                        {props.type !== 'edit' ? (
                          <Autocomplete
                            options={hotelData}
                            freeSolo
                            value={selectedCompany ? hotelData?.name : ''}
                            onChange={(event, value) => {
                              handleOnChange('hotel_id', value ? value : {});
                            }}
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                              <TextField
                                {...params}
                                placeholder="Otel"
                                label="Otel"
                                error={!contractInfo.hotel_id}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        ) : (
                          <TextField
                            placeholder={intl.formatMessage({
                              id: 'hotel',
                            })}
                            className={classes.formElement}
                            label={<IntlMessages id="hotel" />}
                            value={hotelInformation.name}
                            InputLabelProps={{
                              shrink: true,
                              readOnly: true,
                            }}
                          />
                        )}
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                      hidden={contractInfo.hasOwnProperty('hotel_id') && contractInfo.hotel_id != undefined ? false : true}>
                      <Card className={classes.rootcard} hidden={selectedContract != 0 ? false : contractInfo.hotelInfo}>
                        <CardContent>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <IntlMessages id="hotel.info" />
                          </Typography>
                          <FormControl className={classes.formControl}>
                            <Grid container item xs={12} direction="row">
                              <Grid item xs container direction="row" spacing={1}>
                                <Grid item xs={12}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="hotel.name" />}
                                    value={hotelInformation.name}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="tax.office" />}
                                    value={hotelInformation.tax_office}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label="No"
                                    value={hotelInformation.tax_no}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="star.rating" />}
                                    value={hotelInformation.star_rating}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    value={hotelInformation.region?.name}
                                    label={<IntlMessages id="region" />}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="country" />}
                                    value={hotelInformation.country?.name}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="city" />}
                                    value={hotelInformation.city?.name}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    value={hotelInformation.sub_city?.name}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="sub.city" />}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="address" />}
                                    value={hotelInformation.district}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="website" />}
                                    value={hotelInformation.web_page}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="phone.number" />}
                                    value={hotelInformation.tel}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label="Fax"
                                    value={hotelInformation.fax}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label="Email"
                                    value={hotelInformation.e_mail}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </CardContent>
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  {contractStatusControl ? (
                    <Autocomplete
                      value={contractInfo.season_id ? seasons[contractInfo.season_id] : ''}
                      options={Object.values(seasons).sort((a, b) => (a.name > b.name ? 1 : -1))}
                      onChange={(event, value) => {
                        handleOnChange('season_id', value ? value.id : '');
                      }}
                      getOptionLabel={option => option.name}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder={intl.formatMessage({
                            id: 'season.name',
                          })}
                          error={contractInfo.season_id != undefined ? false : true}
                          label={<IntlMessages id="season.name" />}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      placeholder={intl.formatMessage({
                        id: 'season.name',
                      })}
                      className={classes.formElement}
                      label={<IntlMessages id="season.name" />}
                      value={seasons[contractInfo.season_id].name}
                      InputLabelProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      required
                      name="start_date"
                      disablePast
                      format="dd/MM/yyyy"
                      autoOk="true"
                      value={
                        contractInfo.season_id
                          ? moment(contractInfo.start_date).format('YYYY-MM-DD')
                          : moment(selectedStartDate).format('YYYY-MM-DD')
                      }
                      error={contractInfo.season_id ? false : true}
                      onChange={value => {
                        handleOnChange('start_date', moment(value).format('YYYY-MM-DD'));
                      }}
                      label={<IntlMessages id="start.date" />}
                      animateYearScrolling
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: !contractStatusControl,
                      }}
                      readOnly={!contractStatusControl}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      required
                      name="end_date"
                      disablePast
                      format="dd/MM/yyyy"
                      autoOk="true"
                      //value={contractInfo.season_id ? contractInfo.end_date : selectedEndDate}
                      value={
                        contractInfo.season_id
                          ? moment(contractInfo.end_date).format('YYYY-MM-DD')
                          : moment(selectedEndDate).format('YYYY-MM-DD')
                      }
                      error={contractInfo.season_id ? false : true}
                      minDate={contractInfo.start_date}
                      onChange={value => {
                        handleOnChange('end_date', moment(value).format('YYYY-MM-DD'));
                      }}
                      label={<IntlMessages id="end.date" />}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: !contractStatusControl,
                      }}
                      readOnly={!contractStatusControl}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <Typography variant="h6" gutterBottom>
                    <IntlMessages id="market" />
                  </Typography>
                  <MultiSelect
                    options={multiDataSetter(
                      'name',
                      'id',
                      Object.values(markets).sort((a, b) => (a.name > b.name ? 1 : -1)),
                    )}
                    value={multiDataSetter('name', 'id', tempMarkets)}
                    onChange={event => handleOnChange('market', event)}
                    labelledBy="Select"
                    overrideStrings={{ selectSomeItems: 'Select Markets' }}
                  />
                  {/* <Autocomplete
                    options={Object.values(markets).sort((a, b) => (a.name > b.name ? 1 : -1))}
                    limitTags={2}
                    value={contractInfo.market ? tempMarkets : []}
                    onChange={(event, value) => {
                      if (contractInfo.hasOwnProperty('market')) {
                        if (tempMarkets.filter(i => i?.name == event.target.outerText).length < 1) {
                          handleOnChange('market', value ? value : {});
                        }
                      } else {
                        handleOnChange('market', value ? value : {});
                      }
                    }}
                    getOptionLabel={option => option.name}
                    disableCloseOnSelect
                    multiple
                    renderInput={params => (
                      <TextField
                        {...params}
                        required={true}
                        placeholder={intl.formatMessage({
                          id: 'market',
                        })}
                        error={contractInfo.market?.length > 0 ? false : true}
                        label={<IntlMessages id="market" />}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    disabled={!contractStatusControl}
                  /> */}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  {/* <SelectAll
                    optionLabel={'name'}
                    options={allCountries.sort((a, b) => (a.name > b.name ? 1 : -1))}
                    onChange={val => {
                      setContractInfo({
                        ...contractInfo,
                        include_countries: val,
                      });
                    }}
                    value={contractInfo.include_countries ?? []}
                    placeholder={'Geçerli Ülkeler'}
                    disabled={!contractStatusControl}
                  /> */}
                  <Typography variant="h6" gutterBottom>
                    <IntlMessages id="countries" />
                  </Typography>
                  <MultiSelect
                    options={multiDataSetter(
                      'name',
                      'id',
                      allCountries.sort((a, b) => (a.name > b.name ? 1 : -1)),
                    )}
                    value={multiDataSetter('name', 'id', contractInfo.include_countries)}
                    onChange={e => handleOnChange('country', e)}
                    labelledBy="Select"
                    overrideStrings={{ selectSomeItems: 'Select Countries' }}
                  />
                </FormControl>
                {/*<FormControl className={classes.formControl}>*/}
                {/*  <Autocomplete*/}
                {/*    options={allCountries.sort((a, b) => (a.name > b.name ? 1 : -1))}*/}
                {/*    getOptionSelected={(option, value) => option.name === value.name}*/}
                {/*    noOptionsText="Veri bulunamadı!"*/}
                {/*    filterOptions={(options, params) => {*/}
                {/*      if (allCountries.length > 0) {*/}
                {/*        const filtered = filter(options, params);*/}
                {/*        return [{ name: 'Select All', value: 'select-all' }, ...filtered];*/}
                {/*      } else if (allCountries.length === 0) {*/}
                {/*        const filtered = filter(options, params);*/}
                {/*        return [...filtered];*/}
                {/*      }*/}
                {/*    }}*/}
                {/*    multiple*/}
                {/*    limitTags={2}*/}
                {/*    onChange={handleChangeForCountries}*/}
                {/*    disableCloseOnSelect*/}
                {/*    renderOption={optionRenderer}*/}
                {/*    //renderInput={inputRenderer}*/}
                {/*    value={contractInfo.include_countries ? contractInfo.include_countries : []}*/}
                {/*    getOptionLabel={option => option.name}*/}
                {/*    renderInput={params => (*/}
                {/*      <TextField*/}
                {/*        {...params}*/}
                {/*        placeholder="Geçerli Ülkeler"*/}
                {/*        error={contractInfo.include_countries?.length > 0 ? false : true}*/}
                {/*        label="Geçerli Ülkeler"*/}
                {/*        InputLabelProps={{*/}
                {/*          shrink: true,*/}
                {/*        }}*/}
                {/*        required={true}*/}
                {/*      />*/}
                {/* )}*/}
                {/*  />*/}
                {/*</FormControl> */}
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <Typography variant="h6" gutterBottom>
                    <IntlMessages id="operators" />
                  </Typography>
                  <MultiSelect
                    options={multiDataSetter(
                      'name',
                      'id',
                      countriesOperator().sort((a, b) => (a.name > b.name ? 1 : -1)),
                    )}
                    value={multiDataSetter('name', 'id', operatorState)}
                    onChange={e => handleOnChange('operators', e)}
                    labelledBy="Select"
                    overrideStrings={{ selectSomeItems: 'Select Operators' }}
                  />
                  {/* <SelectAll
                    optionLabel={'name'}
                    options={countriesOperator().sort((a, b) => (a.name > b.name ? 1 : -1))}
                    onChange={value => {
                      handleOnChange('operators', value ? value : {});
                    }}
                    value={contractInfo.operators ? operatorState : []}
                    placeholder={'Operator'}
                    disabled={!contractStatusControl}
                  /> */}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <Typography variant="h6" gutterBottom>
                    <IntlMessages id="boards" />
                  </Typography>
                  <MultiSelect
                    options={multiDataSetter('code', 'id', Object.values(boards))}
                    value={multiDataSetter('code', 'id', boardState)}
                    onChange={event => handleOnChange('boards', event)}
                    labelledBy="Select"
                    overrideStrings={{ selectSomeItems: 'Select Boards' }}
                  />
                  {/* <SelectAll
                    optionLabel={'code'}
                    options={Object.values(boards)}
                    onChange={value => {
                      contractInfo.boards?.includes(contractInfo.base_board_id) ||
                      contractInfo.boards?.includes(String(contractInfo.base_board_id))
                        ? (contractInfo.base_board_id = contractInfo.base_board_id)
                        : (contractInfo.base_board_id = 1);
                      handleOnChange('boards', value ? value : {});
                    }}
                    value={contractInfo.boards ? boardState : []}
                    placeholder={'Boards'}
                    disabled={!contractStatusControl}
                  /> */}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Base Board</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label={<IntlMessages id="base.board" />}
                    required
                    error={
                      contractInfo.base_board_id &&
                      (contractInfo.boards?.includes(contractInfo.base_board_id) ||
                        contractInfo.boards?.includes(String(contractInfo.base_board_id)))
                        ? false
                        : true
                    }
                    placeholder={intl.formatMessage({ id: 'base.board' })}
                    style={{ width: '100%' }}
                    value={contractInfo.base_board_id}
                    onChange={e => {
                      handleOnChange('base_board_id', e.target.value);
                    }}
                    readOnly={!contractStatusControl}>
                    {contractInfo.boards?.map(option => (
                      <MenuItem value={option}>{boards[option]?.code}</MenuItem>
                    ))}
                  </Select>
                  {/* <Autocomplete
                    //error={contractInfo.base_board_id ? false : true}
                    options={baseBoardOptions ? baseBoardOptions : []}
                    //value={contractInfo.base_board_id}
                    onChange={(event, value) => {
                      handleOnChange('base_board_id', value ? value : {});
                    }}
                    getOptionLabel={option => option.label}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Base Board"
                        // error={!contractInfo.allotment_type_id}
                        error={!contractInfo.base_board_id}
                        label="Base Board"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required={true}
                      />
                    )}
                  /> */}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <Typography variant="h6" gutterBottom>
                    <IntlMessages id="rate.type" />
                  </Typography>
                  <MultiSelect
                    options={multiDataSetter(
                      'name',
                      'id',
                      Object.values(rateTypes)
                        .filter(rt => {
                          return rt.id == 1 || rt.id == 2;
                        })
                        .sort((a, b) => (a.name > b.name ? 1 : -1)),
                    )}
                    value={multiDataSetter('name', 'id', rateTypeState)}
                    onChange={event => handleOnChange('rate_types', event)}
                    labelledBy="Select"
                    overrideStrings={{ selectSomeItems: 'Select Rate Types' }}
                  />

                  {/* <SelectAll
                    optionLabel={'name'}
                    options={Object.values(rateTypes)
                      .filter(rt => {
                        return rt.id == 1 || rt.id == 2;
                      })
                      .sort((a, b) => (a.name > b.name ? 1 : -1))}
                    onChange={value => {
                      handleOnChange('rate_types', value ? value : {});
                    }}
                    value={contractInfo ? rateTypeState : []}
                    placeholder={'Fiyat Tipi'}
                    disabled={!contractStatusControl}
                  /> */}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    error={allotmentTypeState?.length > 0 ? false : true}
                    options={Object.values(allotmentType)}
                    value={contractInfo.allotment_type_id ? allotmentTypeState : ''}
                    onChange={(event, value) => {
                      handleOnChange('allotment_type_id', value ? value : {});
                    }}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Kontenjan Tipi"
                        // error={!contractInfo.allotment_type_id}
                        error={!contractInfo.allotment_type_id}
                        label="Kontenjan Tipi"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required={true}
                      />
                    )}
                    disabled={!contractStatusControl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    limitTags={2}
                    options={Object.values(currencies).sort((a, b) => (a.code > b.code ? 1 : -1))}
                    value={contractInfo.currency ? currenciesState : ''}
                    onChange={(event, value) => {
                      handleOnChange('currency', value ? value : {});
                    }}
                    getOptionLabel={option => option.code}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder={intl.formatMessage({
                          id: 'currency',
                        })}
                        // error={!contractInfo.currency}
                        error={!contractInfo.currency}
                        label={<IntlMessages id="currency" />}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required={true}
                      />
                    )}
                    disabled={!contractStatusControl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs container direction="row" spacing={1}>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={contractInfo.checkChild ? contractInfo.checkChild : false}
                          onChange={handleChange}
                          name="checkChild"
                          disabled={!contractStatusControl}
                        />
                      }
                      label={<IntlMessages id="only.adult" />}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      limitTags={2}
                      options={minChildVal}
                      value={Object.values(minChildVal).find(item => item.age == contractInfo.min_child_age)}
                      onChange={(e, value) => {
                        handleOnChange('min_child_age', value ? value.age : '');
                      }}
                      getOptionLabel={option => option.age}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder={intl.formatMessage({
                            id: 'min.child.age',
                          })}
                          error={!contractInfo.min_child_age}
                          label={<IntlMessages id="min.child.age" />}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required={true}
                        />
                      )}
                      disabled={!contractStatusControl}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      limitTags={2}
                      options={maxChildVal}
                      value={Object.values(maxChildVal).find(item => item.age == contractInfo.max_child_age)}
                      onChange={(e, value) => {
                        handleOnChange('max_child_age', value ? value.age : '');
                      }}
                      getOptionLabel={option => option.age}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder={intl.formatMessage({
                            id: 'max.child.age',
                          })}
                          error={!contractInfo.max_child_age}
                          label={<IntlMessages id="max.child.age" />}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required={true}
                        />
                      )}
                      disabled={!contractStatusControl}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={contractInfo.checkInf}
                          disabled={/* contractInfo.disabledInf */ !contractStatusControl || contractInfo.checkChild}
                          onChange={handleChange}
                          name="checkInf"
                        />
                      }
                      label={<IntlMessages id="should.infant.count.as.pax" />}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      defaultValue={contractInfo.max_infant_age ? '' : '01.99'}
                      value={contractInfo.max_infant_age}
                      disabled={/* contractInfo.disabledInf */ contractInfo.checkChild}
                      placeholder={intl.formatMessage({
                        id: 'infant.age',
                      })}
                      label={<IntlMessages id="infant.age" />}
                      name="max_infant_age"
                      InputProps={{
                        inputComponent: AgeNumberFormatCustom,
                        readOnly: !contractStatusControl,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        readOnly: true,
                      }}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <FormControl className={classes.button}>
            <ThemeProvider theme={theme}>
              {selectedContract != 0 ? (
                isAuthUpdate ? (
                  <Button
                    variant="contained"
                    disabled={updateButtonDisable || dataControl()}
                    color="secondary"
                    onClick={() => saveAndUpdateSeason()}
                    startIcon={<UpdateIcon />}
                    hidden={!contractStatusControl}>
                    UPDATE
                  </Button>
                ) : (
                  undefined
                )
              ) : isAuthAdd ? (
                <Button
                  variant="contained"
                  disabled={
                    dataControl()
                    // contractInfo.hotel_id &&
                    // contractInfo.season_id &&
                    // contractInfo.currency &&
                    // contractInfo.allotment_type_id &&
                    // contractInfo.operators.length > 0 &&
                    // contractInfo.hasOwnProperty('include_countries') &&
                    // contractInfo.include_countries?.length > 0 &&
                    // contractInfo.boards.length > 0 &&
                    // contractInfo.market.length > 0
                    //   ? false
                    //   : true
                  }
                  color="primary"
                  onClick={() => saveAndUpdateSeason()}
                  startIcon={<SaveIcon />}
                  hidden={!contractStatusControl}>
                  SAVE
                </Button>
              ) : (
                undefined
              )}
            </ThemeProvider>
          </FormControl>
        </CardContent>
      </Card>
    </div>
  );
}

export default ContractSeason;
