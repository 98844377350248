/*eslint-disable */
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent.js';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader.js';
import CmtCardFooter from '@coremat/CmtCard/CmtCardFooter';
import { Typography } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import { Divider, Grid, Button } from '@material-ui/core';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import axios from 'services/axios';
import LogCard from './LogCard';
import LogSelects from './LogSelects';
import { getMasterUsersService } from 'services/user';
import { useDispatch, useSelector } from 'react-redux';
import { getMasterCompaniesService } from 'services/company';
import IntlMessages from '@jumbo/utils/IntlMessages';

const serviceArray = [
  { value: 'hotelService', label: 'Hotel Service' },
  { value: 'additionalpath', label: 'Additional Path' },
  { value: 'advertisingfee', label: 'Advertising Fee' },
  { value: 'allotment', label: 'Allotment' },
  { value: 'allotmentrest', label: 'Allotment Rest' },
  { value: 'boardsupplement', label: 'Board Supplement' },
  { value: 'cancellation', label: 'Cancellation' },
  { value: 'board', label: 'Board' },
  { value: 'bsreduction', label: 'Board Supplement Reduction' },
  { value: 'catalougecontribution', label: 'Catalouge Contribution' },
  { value: 'company', label: 'Company' },
  { value: 'conterm', label: 'Conterm' },
  { value: 'contractroom', label: 'Contract Rooms' },
  { value: 'contractstatus', label: 'Contract Status' },
  { value: 'contributionfee', label: 'Contribution Fee' },
  { value: 'closeout', label: 'Closeout' },
  { value: 'cincout', label: 'Check In Check Out' },
  { value: 'extras', label: 'Extras' },
  { value: 'hotel', label: 'Hotel' },
  { value: 'marketingpromotion', label: 'Marketing Promotion' },
  { value: 'market', label: 'Market' },
  { value: 'markup', label: 'Markup' },
  { value: 'newservice', label: 'New Service' },
  { value: 'officedestination', label: 'Office Destination' },
  { value: 'office', label: 'Office' },
  { value: 'offer', label: 'Offer' },
  { value: 'opensale', label: 'Open Sale' },
  { value: 'operator', label: 'Operator' },
  { value: 'occupancy', label: 'Occupancy' },
  { value: 'period', label: 'Period' },
  { value: 'payment', label: 'Payment' },
  { value: 'producttypes', label: 'Product Types' },
  { value: 'representativefee', label: 'Representative Fee' },
  { value: 'release', label: 'Release' },
  { value: 'reservation', label: 'Reservation' },
  { value: 'room', label: 'Room' },
  { value: 'season', label: 'Season' },
  { value: 'stopsale', label: 'Stop Sale' },
  { value: 'specialnotes', label: 'Special Notes' },
  { value: 'termsconditions', label: 'Terms & Conditions' },
  { value: 'turnover', label: 'Turnover' },
  { value: 'userlogin', label: 'User Login' },
  { value: 'userlogout', label: 'User Logout' },
  { value: 'userservice', label: 'User Service' },
  { value: 'userpermission', label: 'User Permission' },
];

const Logger = () => {
  const dispatch = useDispatch();
  const [logs, setLogs] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [usersFetched, setUsersFetched] = useState(false);
  const usersArray = useSelector(state => {
    return state.user.masterUsers.map(user => {
      let filteredUser = { value: user.email, label: user.email };
      return filteredUser;
    });
  });

  useEffect(() => {
    dispatch(getMasterCompaniesService());
    dispatch(
      getMasterUsersService(() => {
        setUsersFetched(true);
      }),
    );
  }, [dispatch]);

  const [filterSelected, setFilterSelected] = useState({
    log_type: 'all_logs',
    start_date: new moment().format('YYYY-MM-DD'),
    end_date: new moment().format('YYYY-MM-DD'),
    service_type: '',
    user_email: '',
    page: 1,
    limit: 2,
  });

  const logCardCreator = () => {
    let logCardsArray = [];
    if (logs.length > 0) {
      logs.forEach((log, index) => {
        logCardsArray.push(
          <Grid item xs={6}>
            <LogCard log={log} index={index} page={filterSelected.page} />
          </Grid>,
        );
      });
    } else if (logs.length === 0) {
      logCardsArray.push(
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardHeader title="Search Failed" subTitle="Data not Found" />
            <CmtCardContent>
              <Typography style={{ fontSize: 25 }} variant="b2" color="textSecondary" component="p">
                There is no result between this dates
              </Typography>
            </CmtCardContent>
          </CmtCard>
        </Grid>,
      );
    }

    return logCardsArray;
  };
  useEffect(() => {
    handleSubmit(filterSelected);
  }, [filterSelected.page]);
  const handleSubmit = filterSelected => {
    let url = '';
    switch (filterSelected.log_type) {
      case 'all_logs':
        url = `http://localhost:3030/proxolab/api/v1/getalllogs/${filterSelected.start_date}/${filterSelected.end_date}/?page=${filterSelected.page}&limit=${filterSelected.limit}`;
        break;
      case 'service_logs':
        url = `http://localhost:3030/proxolab/api/v1/getservicelogs/${filterSelected.service_type}/all/${
          filterSelected.start_date
        }/${filterSelected.end_date}/?page=${filterSelected.page}&limit=${20}`;
        break;
      case 'user_logs':
        url = `http://localhost:3030/proxolab/api/v1/getalluserlogs/${filterSelected.user_email}/${
          filterSelected.start_date
        }/${filterSelected.end_date}/?page=${filterSelected.page}&limit=${20}`;
        break;
      case 'user_with_service':
        url = `http://localhost:3030/proxolab/api/v1/getservicelogs/${filterSelected.service_type}/${
          filterSelected.user_email
        }/${filterSelected.start_date}/${filterSelected.end_date}/?page=${filterSelected.page}&limit=${20}`;
        break;
    }
    axios({
      method: 'get',
      url: url,
    }).then(res => {
      let newLogs = [];
      if (filterSelected.log_type == 'all_logs' || filterSelected.log_type === 'user_logs') {
        let serviceNames = Object.keys(res.data.data[0]);

        serviceNames.forEach(serviceName => {
          if (res.data.data[0][serviceName].length > 0) {
            res.data.data[0][serviceName].forEach(log => {
              let newLog = {
                service_type: serviceName.split('all')[1],
                data: log.data,
                user: log.user,
                process_date: log.createdAt,
              };
              newLogs.push(newLog);
            });
          }
        });
      } else if (filterSelected.log_type === 'service_logs' || filterSelected.log_type === 'user_with_service') {
        let service_name = serviceArray.find(service => service.value === filterSelected.service_type).label;
        res.data.data.forEach(log => {
          let newLog = {
            service_type: service_name,
            data: log.data,
            user: log.user,
            process_date: log.createdAt,
          };
          newLogs.push(newLog);
        });
      }
      setToggle(true);
      setLogs(newLogs);
    });
  };

  return (
    <GridContainer>
      <Grid item xs={12}>
        <CmtCard>
          <CmtCardHeader title="Logger" />
          <Divider />
          <CmtCardContent>
            <Grid item xs={1}></Grid>
            <Grid item xs={11} spacing={2}>
              <LogSelects
                handleSubmit={handleSubmit}
                filterSelect={filterSelected}
                setFilterSelect={setFilterSelected}
                serviceArray={serviceArray}
                usersEmail={usersArray}
              />
            </Grid>
            <Grid container xs={12} spacing={4}>
              <CmtCardHeader title="Logs" />
              <Grid container xs={12} spacing={4}>
                <Divider />
              </Grid>
              {toggle == false ? null : logCardCreator()}
            </Grid>
          </CmtCardContent>
          <CmtCardFooter>
            <Grid item xs={2}>
              <Button
                variant="contained"
                //className={classes.greenButton}
                //disabled={reservationDetail.rooms.length === 0 ? true : false}
                onClick={() =>
                  setFilterSelected(prev => ({
                    ...prev,
                    page: prev.page == 1 ? 1 : prev.page - 1,
                  }))
                }>
                <IntlMessages id="previous" />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                //className={classes.greenButton}
                //disabled={reservationDetail.rooms.length === 0 ? true : false}
                onClick={() =>
                  setFilterSelected(prev => ({
                    ...prev,
                    page: logs.length == 0 ? prev.page : prev.page + 1,
                  }))
                }>
                <IntlMessages id="next" />
              </Button>
            </Grid>
          </CmtCardFooter>
        </CmtCard>
      </Grid>
    </GridContainer>
  );
};

export default Logger;
