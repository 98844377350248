import React, { useEffect, useState } from 'react';

import { getAdminCompaniesService } from 'services/company';
import { useDispatch, useSelector } from 'react-redux';
import { authUserMemo } from 'redux/selector/companyHasOfficeSelector';
import CompanyLoginScreen from './CompanyLogin';
import EnterpriseLoginScreen from './EnterpriseLogin';

export default function AdminOperations() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminCompaniesService());
  }, [dispatch]);

  const authUserState = useSelector(authUserMemo);
  const [countryCache, setCountryCache] = useState({});
  const [divisionCache, setDivisionCache] = useState({});
  const [divisionCityCache, setDivisionCityCache] = useState({});
  const [cityCache, setCityCache] = useState({});
  const [subCityCache, setSubCityCache] = useState({});

  //AUTH LEVEL 10 İSE ENTERPRİSE SEVİYESİNDEKİ USER 11 => COMPANY SEVİYESİNDE USER
  //20 ISE ENTERPRISENIN COMPANYSI
  //30 OFFICE KULLANICISI O BURAYA ERİŞEMİYOR
  return authUserState === 10 ? (
    <EnterpriseLoginScreen
      countryCache={countryCache}
      cityCache={cityCache}
      divisionCache={divisionCache}
      divisionCityCache={divisionCityCache}
      subCityCache={subCityCache}
      setCountryCache={setCountryCache}
      setDivisionCache={setDivisionCache}
      setDivisionCityCache={setDivisionCityCache}
      setCityCache={setCityCache}
      setSubCityCache={setSubCityCache}
    />
  ) : authUserState === 11 || authUserState === 20 ? (
    <CompanyLoginScreen
      countryCache={countryCache}
      divisionCache={divisionCache}
      divisionCityCache={divisionCityCache}
      cityCache={cityCache}
      subCityCache={subCityCache}
      setCountryCache={setCountryCache}
      setDivisionCache={setDivisionCache}
      setDivisionCityCache={setDivisionCityCache}
      setCityCache={setCityCache}
      setSubCityCache={setSubCityCache}
    />
  ) : (
    <h1>Girmeye Yetkiniz Yok</h1>
  );
}
