import {
  UPDATE_ADMIN_COMPANY,
  GET_ADMIN_COMPANIES,
  ADD_ADMIN_COMPANY,
  /* GET_OFFICES, */
  ADD_OFFICE,
  UPDATE_OFFICE,
  ADD_ADMIN_USER,
  UPDATE_ADMIN_USER,
  ADD_USER_PERMISSION,
  DELETE_ADMIN_COMPANY,
  DELETE_OFFICE,
  DELETE_ADMIN_USER,
  OFFICE_DESTINATION_COUNTRIES,
  OFFICE_DESTINATION_CITIES,
  ADD_OFFICE_DESTINATION,
  UPDATE_OFFICE_DESTINATION,
  FETCH_COMPANY_ERROR,
  FETCH_COMPANY_SUCCESS,
  OFFICE_DESTINATION_DIVISIONS,
} from '../../@jumbo/constants/ActionTypes';
import { normalizeTasks } from 'redux/schemas/company';
import { normalizrOffice } from 'redux/schemas/office';
import { normalizrUser } from 'redux/schemas/user';
import { userUpdateNormalizr } from 'redux/schemas/userUpdate';
import { userDeleteNormalizr } from 'redux/schemas/userDelete';
import { normalizrDestination } from 'redux/schemas/officeDestination';

const INITIAL_STATE = {
  entities: {
    companies: [],
    offices: [],
    officeHasDestination: {
      country: [],
      city: [],
      allCountryID: [],
      allCityID: [],
    },
    users: [],
    regions: [],
    country: [],
    city: [],
    sub_city: [],
    get_permission: [],
    error_message: '',
    success_message: '',
  },
  result: {
    companiesId: [],
  },
};

const adminCompanyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //COMPANİES
    case GET_ADMIN_COMPANIES:
      const normalizeData = normalizeTasks([action.payload]);
      const entities = normalizeData.entities;
      return {
        ...state,
        entities: {
          companies: entities.companies ? entities.companies : state.entities.companies,
          offices: entities.offices ? entities.offices : state.entities.offices,
          users: entities.users ? entities.users : state.entities.users,
          regions: entities.region ? entities.region : state.entities.regions,
          country: entities.country ? entities.country : state.entities.country,
          city: entities.city ? entities.city : state.entities.city,
          sub_city: entities.sub_city ? entities.sub_city : state.entities.sub_city,
          get_permission: entities.get_permission ? entities.get_permission : state.entities.get_permission,
          officeHasDestination: { country: [], city: [], allCountryID: [] },
          success_message: '',
          error_message: '',
          success_message: '',
        },
        result: {
          companiesId: normalizeData.result[0].companies,
        },
      };

    case ADD_ADMIN_COMPANY:
      const addNormalizr = normalizeTasks([action.payload]);

      const addCompEntites = addNormalizr.entities;
      const addedCompanyID = addNormalizr.result[0].addedCompany[0];
      const addedCompany = addCompEntites.companies[addedCompanyID];
      state.result.companiesId.push(addedCompanyID);

      return {
        ...state,
        entities: {
          ...state.entities,
          companies: {
            ...state.entities.companies,
            [addedCompanyID]: addedCompany,
          },
          regions: addCompEntites.region
            ? {
                ...state.entities.regions,
                [addedCompany.region]: addCompEntites.region[addedCompany.region],
              }
            : state.entities.regions,
          country: addCompEntites.country
            ? {
                ...state.entities.country,
                [addedCompany.country]: addCompEntites.country[addedCompany.country],
              }
            : state.entities.country,
          city: addCompEntites.city
            ? {
                ...state.entities.city,
                [addedCompany.city]: addCompEntites.city[addedCompany.city],
              }
            : state.entities.city,
          sub_city: addCompEntites.sub_city
            ? {
                ...state.entities.sub_city,
                [addedCompany.sub_city]: addCompEntites.sub_city[addedCompany.sub_city],
              }
            : state.entities.sub_city,
          success_message: '',
          error_message: '',
        },
        result: {
          companiesId: state.result.companiesId,
        },
      };

    case UPDATE_ADMIN_COMPANY:
      action.payload['updateCompanyState'] = state.entities.companies;
      const normalizr = normalizeTasks([action.payload]);

      const newEntites = normalizr.entities;
      const updatedCompanyID = normalizr.result[0].updateCompany[0];
      const updatedCompany = newEntites.companies[updatedCompanyID];

      return {
        ...state,
        entities: {
          ...state.entities,
          /* companies: {
            ...state.entities.companies,
            [updatedCompanyID]: updatedCompany,
          }, */
          companies: newEntites.companies,
          regions: newEntites.region
            ? {
                ...state.entities.regions,
                [updatedCompany.region]: newEntites.region[updatedCompany.region],
              }
            : state.entities.regions,
          country: newEntites.country
            ? {
                ...state.entities.country,
                [updatedCompany.country]: newEntites.country[updatedCompany.country],
              }
            : state.entities.country,
          city: newEntites.city
            ? {
                ...state.entities.city,
                [updatedCompany.city]: newEntites.city[updatedCompany.city],
              }
            : state.entities.city,
          sub_city: newEntites.sub_city
            ? {
                ...state.entities.sub_city,
                [updatedCompany.sub_city]: newEntites.sub_city[updatedCompany.sub_city],
              }
            : state.entities.sub_city,
          success_message: '',
          error_message: '',
        },
        result: {
          companiesId: normalizr.result[0].updateCompanyState,
        },
      };

    case DELETE_ADMIN_COMPANY:
      const oldData = state.entities.companies;
      const deleteCompany = Object.keys(oldData)
        .filter(id => id !== action.payload.data)
        .reduce((obj, key) => {
          obj[key] = oldData[key];
          return obj;
        }, {});
      const allCompanyID = state.result.companiesId;
      const newCompanyIDS = allCompanyID.filter(i => i !== action.payload.data);

      return {
        ...state,
        entities: {
          ...state.entities,
          companies: deleteCompany,
          success_message: '',
          error_message: '',
        },
        result: {
          companiesId: newCompanyIDS,
        },
      };

    //OFFICE
    case ADD_OFFICE:
      action.payload['addOfficeState'] = state.entities.companies;
      const addedOfficeNormalizr = normalizrOffice([action.payload]);
      const addedOfficeID = addedOfficeNormalizr.result[0].addOffice[0];
      const newOffice = addedOfficeNormalizr.entities.offices[addedOfficeID];

      return {
        ...state,
        entities: {
          ...state.entities,
          companies: {
            ...state.entities.companies,
            [newOffice.company_id]: addedOfficeNormalizr.entities.companies[newOffice.company_id],
          },
          offices: {
            ...state.entities.offices,
            [addedOfficeID]: newOffice,
          },
          regions: addedOfficeNormalizr.entities.region
            ? {
                ...state.entities.regions,
                [newOffice.region]: addedOfficeNormalizr.entities.region[newOffice.region],
              }
            : state.entities.regions,
          country: addedOfficeNormalizr.entities.country
            ? {
                ...state.entities.country,
                [newOffice.country]: addedOfficeNormalizr.entities.country[newOffice.country],
              }
            : state.entities.country,
          city: addedOfficeNormalizr.entities.city
            ? {
                ...state.entities.city,
                [newOffice.city]: addedOfficeNormalizr.entities.city[newOffice.city],
              }
            : state.entities.city,
          sub_city: addedOfficeNormalizr.entities.sub_city
            ? {
                ...state.entities.sub_city,
                [newOffice.sub_city]: addedOfficeNormalizr.entities.sub_city[newOffice.sub_city],
              }
            : state.entities.sub_city,
          success_message: '',
          error_message: '',
        },
        /* result: {
          companiesId: normalizr.result[0].updateCompanyState,
        }, */
      };

    case UPDATE_OFFICE:
      action.payload.data['companyUpState'] = state.entities.companies;
      const upOfficeNormalizr = normalizrOffice([action.payload.data]);

      const upOfficeID = upOfficeNormalizr.result[0].updateOffice[0];
      const normEntities = upOfficeNormalizr.entities;
      const normUpdateOffice = normEntities.offices[upOfficeID];

      return {
        ...state,
        entities: {
          ...state.entities,
          /* companies: {
            ...state.entities.companies,
            [updatedCompanyID]: updatedCompany,
          }, */
          companies: normEntities.companies,
          offices: {
            ...state.entities.offices,
            [upOfficeID]: normEntities.offices[upOfficeID],
          },
          regions: normEntities.region
            ? {
                ...state.entities.regions,
                [normUpdateOffice.region]: normEntities.region[normUpdateOffice.region],
              }
            : state.entities.regions,
          country: normEntities.country
            ? {
                ...state.entities.country,
                [normUpdateOffice.country]: normEntities.country[normUpdateOffice.country],
              }
            : state.entities.country,
          city: normEntities.city
            ? {
                ...state.entities.city,
                [normUpdateOffice.city]: normEntities.city[normUpdateOffice.city],
              }
            : state.entities.city,
          sub_city: normEntities.sub_city
            ? {
                ...state.entities.sub_city,
                [normUpdateOffice.sub_city]: normEntities.sub_city[normUpdateOffice.sub_city],
              }
            : state.entities.sub_city,
          success_message: '',
          error_message: '',
        },
      };

    case DELETE_OFFICE:
      const deleteData = [];
      const officeHasCompaniesID = state.entities.offices[action.payload.data].company_id;
      const stateData = state.entities.companies[officeHasCompaniesID];
      stateData['deletedOfficeID'] = action.payload.data;
      deleteData['deleteData'] = [stateData];
      const deleteOfficeNormalizr = normalizrOffice([deleteData]);
      const deleteOffice = Object.keys(state.entities.offices)
        .filter(id => parseInt(id) !== action.payload.data)
        .reduce((obj, key) => {
          obj[key] = state.entities.offices[key];
          return obj;
        }, {});

      return {
        ...state,
        entities: {
          ...state.entities,
          companies: {
            ...state.entities.companies,
            [officeHasCompaniesID]: {
              ...state.entities.companies[officeHasCompaniesID],
              offices: deleteOfficeNormalizr.entities.companies[officeHasCompaniesID].offices,
            },
          },
          offices: deleteOffice,
          success_message: '',
          error_message: '',
        },
      };

    case OFFICE_DESTINATION_COUNTRIES:
      //officeHasDestination: { country: [], city: [] },
      const destinationCountry = [];
      destinationCountry['country'] = action.payload.data;
      destinationCountry['stateCountry'] = state.entities.officeHasDestination.country;
      const destinationOfficeCountries = normalizrDestination([destinationCountry]);
      return {
        ...state,
        entities: {
          ...state.entities,
          officeHasDestination: {
            ...state.entities.officeHasDestination,
            country: action.payload.data,
            allCountryID: destinationOfficeCountries.result[0].country,
            success_message: '',
            error_message: '',
          },
        },
      };
    case OFFICE_DESTINATION_DIVISIONS:
      return {
        ...state,
        entities: {
          ...state.entities,
          officeHasDestination: {
            ...state.entities.officeHasDestination,
            division: action.payload.data,
          },
          success_message: '',
          error_message: '',
        },
      };

    case OFFICE_DESTINATION_CITIES:
      return {
        ...state,
        entities: {
          ...state.entities,
          officeHasDestination: {
            ...state.entities.officeHasDestination,
            city: action.payload.data,
          },
          success_message: '',
          error_message: '',
        },
      };

    case ADD_OFFICE_DESTINATION:
      const destinationAdded = state.entities.offices;
      destinationAdded[action.payload.data.office_id].get_destination = action.payload.data;
      destinationAdded[action.payload.data.office_id].user = action.payload[0];

      return {
        ...state,
        entities: {
          ...state.entities,
          offices: destinationAdded,
        },
      };

    case UPDATE_OFFICE_DESTINATION:
      const destinationUpdate = state.entities.offices;
      destinationUpdate[action.payload.data.office_id].get_destination = action.payload.data;

      return {
        ...state,
        entities: {
          ...state.entities,
          offices: destinationUpdate,
        },
      };

    //USER
    case ADD_ADMIN_USER:
      action.payload['statePermision'] = state.entities.get_permission;
      action.payload['stateOffice'] = state.entities.offices;
      const newUserNormalizr = normalizrUser([action.payload]);
      const userEntities = newUserNormalizr.entities;
      const newUserID = newUserNormalizr.result[0].addedUser[0];

      return {
        ...state,
        entities: {
          ...state.entities,
          offices: userEntities.offices,
          users: {
            ...state.entities.users,
            [newUserID]: userEntities.users[newUserID],
          },
          success_message: '',
          error_message: '',
          get_permission: userEntities.permission,
        },
      };

    case UPDATE_ADMIN_USER:
      action.payload['stateOffice'] = state.entities.offices;
      const updateNormalizrUser = userUpdateNormalizr([action.payload]);

      const updatedUserEntities = updateNormalizrUser.entities;
      const updatedUserID = updateNormalizrUser.result[0].updateUser[0];
      updatedUserEntities.users[updatedUserID].get_permission = state.entities.users[updatedUserID].get_permission;

      return {
        ...state,
        entities: {
          ...state.entities,
          offices: updatedUserEntities.offices,
          users: {
            ...state.entities.users,
            [updatedUserID]: updatedUserEntities.users[updatedUserID],
          },
          success_message: '',
          error_message: '',
        },
      };

    case DELETE_ADMIN_USER:
      const deleteUserData = [];
      const pivot = [];
      state.entities.users[action.payload.data].pivot_office.map(e => pivot.push(state.entities.offices[e]));
      deleteUserData['officeState'] = state.entities.offices;
      deleteUserData['deleteUser'] = [state.entities.users[action.payload.data]];
      deleteUserData['deleteUser'][0].pivot_office = pivot;

      const deleteUserNormalizr = userDeleteNormalizr([deleteUserData]);

      const deleteUser = Object.keys(state.entities.users)
        .filter(id => id !== action.payload.data)
        .reduce((obj, key) => {
          obj[key] = state.entities.users[key];
          return obj;
        }, {});

      return {
        ...state,
        entities: {
          ...state.entities,
          offices: deleteUserNormalizr.entities.offices,
          users: deleteUser,
          success_message: '',
          error_message: '',
        },
      };

    case ADD_USER_PERMISSION:
      const arrayPermissionID = state.entities.users[action.payload[0].user_id].get_permission;
      for (let i = 0; i < arrayPermissionID.length; i++) {
        state.entities.get_permission[arrayPermissionID[i]]['get'] = action.payload[i]['get'];
        state.entities.get_permission[arrayPermissionID[i]]['post'] = action.payload[i]['post'];
        state.entities.get_permission[arrayPermissionID[i]]['put'] = action.payload[i]['put'];
        state.entities.get_permission[arrayPermissionID[i]]['delete'] = action.payload[i]['delete'];
      }
      return state;

    case FETCH_COMPANY_ERROR: {
      return {
        ...state,
        entities: {
          ...state.entities,
          success_message: '',
          error_message: action.payload,
        },
      };
    }

    case FETCH_COMPANY_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          success_message: action.payload,
          error_message: '',
        },
      };
    }

    default:
      return state;
  }
};

export default adminCompanyReducer;
