class SendLogService {
  constructor(user) {
    this.user = user;
    this.url = 'http://localhost:3030/proxolab/api/v1/';
    this.projectID = 1;

    /****** CHANGE PROJECT ID AND SEND LOGGER *****/
    fetch(this.url + 'changedb', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ projectID: this.projectID }),
    })
      .then(function(res) {
        console.log(res);
      })
      .catch(function(res) {
        console.log(res);
      });
  }
  /************************************************/
  async sendLogin(login) {
    try {
      await fetch(
        this.url + 'userlogininfo',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ data: login }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendLogout(user) {
    try {
      await fetch(
        this.url + 'userlogoutinfo',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ data: user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendCompanyLog(res, data) {
    try {
      await fetch(
        this.url + 'companyservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendHotelLog(res, data) {
    try {
      await fetch(
        this.url + 'hotelservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendBoardLog(res, data) {
    try {
      await fetch(
        this.url + 'boardservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendMarketLog(res, data) {
    try {
      await fetch(
        this.url + 'marketservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendOfficeDestinationLog(res, data) {
    try {
      delete res.config.data;

      await fetch(
        this.url + 'officedestinationservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendOfficeLog(res, data) {
    try {
      await fetch(
        this.url + 'officeservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendOperatorLog(res, data) {
    try {
      await fetch(
        this.url + 'operatorservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendProductTypesLog(res, data) {
    try {
      await fetch(
        this.url + 'producttypesservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendRoomLog(res, data) {
    try {
      await fetch(
        this.url + 'roomservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendSeasonLog(res, data) {
    try {
      await fetch(
        this.url + 'seasonservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendUserLog(res, data) {
    try {
      await fetch(
        this.url + 'userservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendNewServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'newservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendContractServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'contractservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendPeriodServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'periodservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendContractRoomServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'contractroomservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendContractStatusServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'contractstatusservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }
  async markupServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'markupservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async cancellationPoliciesServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'cancellationpoliciesservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async termsConditionsServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'termsconditionsservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async additionalPaxServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'additionalpaxservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async representativeFeeServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'representativefeeservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async contributionFeeServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'contributionfeeservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async turnoverServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'turnoverservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async advertisingFeeServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'advertisingfeeservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async marketingPromotionServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'marketingpromotionservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async catalougeContributionServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'catalougecontributionservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async offerServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'offerservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async occupancyServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'occupancyservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async closeoutServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'closeoutservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async cincoutServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'cincoutservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async cincoutServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'cincoutservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async allotmentRestServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'allotmentrestservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async releaseServiceLog(res, data) {
    try {
      await fetch(
        this.url + 'releaseservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async specialNotesServiceLogs(res, data) {
    try {
      await fetch(
        this.url + 'specialnotesservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async paymentServiceLogs(res, data) {
    try {
      await fetch(
        this.url + 'paymentservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async conTermsServiceLogs(res, data) {
    try {
      await fetch(
        this.url + 'contermsservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async boardSupplementServiceLogs(res, data) {
    try {
      await fetch(
        this.url + 'boardSupplementservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async bsReductionServiceLogs(res, data) {
    try {
      await fetch(
        this.url + 'bsreductionservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async extrasServiceLogs(res, data) {
    try {
      await fetch(
        this.url + 'extrasservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async masterCompanyServiceLogs(res, data) {
    try {
      await fetch(
        this.url + 'mastercompanyservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async masterUserServiceLogs(res, data) {
    try {
      await fetch(
        this.url + 'masteruserservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async allotmentServiceLogs(res, data) {
    try {
      await fetch(
        this.url + 'allotmentservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async stopSaleLogs(res, data) {
    try {
      await fetch(
        this.url + 'stopsaleservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async openSaleLogs(res, data) {
    try {
      await fetch(
        this.url + 'opensaleservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async reservationServiceLogs(res, data) {
    try {
      await fetch(
        this.url + 'reservationservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async userPermissionServiceLogs(res, data) {
    try {
      await fetch(
        this.url + 'userpermissionservice',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async errorlog(res, data) {
    try {
      await fetch(
        this.url + 'error',

        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ res: res, data: data, user: this.user }),
        },
      )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }
}

let sendLogService = new SendLogService();

export default sendLogService;
