import React, { useEffect, useState, lazy, Suspense } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import ContractSeason from './ContractSeason';
import Period from './Periods';
import Contract from './Contract';
import { useDispatch, useSelector } from 'react-redux';
import { changeContractStatusService, ContractConfirmControlService } from 'services/contract';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useWhyDidYouUpdate } from '@jumbo/utils/useWhyDidYouUpdate';
const MemoizedContractOnSale = lazy(() => import('./ContractResyncModal'));
const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: '',
    text: text,
  });
};
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    margin: theme.spacing(2),
  },
  nextButton: {
    margin: theme.spacing(2),
    float: 'right',
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  stepper: {
    padding: 1,
    paddingTop: 5,
    backgroundColor: localStorage.getItem('theme-type') === 'dark' ? '#2e2e2e' : '#f4f4f7',
  },
}));

function getSteps() {
  return ['1', '2', '3'];
}

function getStepContent(stepIndex, type, isPeriadsNewAndEdit) {
  switch (stepIndex) {
    case 0:
      return <ContractSeason type={type !== undefined ? type : null} />;
    case 1:
      return (
        <Period
          isPeriadsNewAndEdit={e => {
            isPeriadsNewAndEdit(e);
          }}
        />
      );
    case 2:
      return <Contract />;
    default:
      return 'Unknown stepIndex';
  }
}

function WizardPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSaved = useSelector(state => state.contract.isSaved);
  const { periods } = useSelector(({ periods }) => periods);
  const { selectedContract } = useSelector(({ contract }) => contract);
  const [lastPeriod, setLastPeriod] = useState(0);
  const [editMarkupOpen, setEditMarkupOpen] = useState(false);

  const [isPeriadsNewAndEdit, setIsPeriadsNewAndEdit] = useState(false);

  // const { isUpdate } = useSelector(({ contract }) => contract); //dispatchler düzenlenince açılacak
  const [activeStep, setActiveStep] = React.useState(0);
  const { contracts } = useSelector(({ contract }) => contract);
  const { checkContractDataIsUpdate } = useSelector(({ contract }) => contract);
  const { rowStatus } = useSelector(({ periods }) => periods);

  useWhyDidYouUpdate('WizardPage', props);

  let history = useHistory();
  const redirectToDashboard = () => {
    history.push('/dashboard');
  };
  const changeContractStatus = status_id => {
    let data;
    data = {
      contract_id: selectedContract,
      status_id: status_id ? status_id : '1',
    };

    dispatch(changeContractStatusService(data));
    /* if (props.type == 'edit') {
      props.setOpen(false);
    } else {
      redirectToDashboard();
    } */
  };
  const confirmControl = () => {
    dispatch(ContractConfirmControlService({ id: selectedContract, setOpen: props.setOpen }));
  };
  const steps = getSteps();
  //let period = Object.values(periods).filter(p => p.contract_id == selectedContract);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  // useEffect(() => {
  //   setActiveStep(isUpdate);
  // }, [isUpdate]);

  useEffect(() => {
    if (isSaved === 'isSaved') {
      handleNext();
    }
  }, [isSaved]);
  useEffect(() => {
    setActiveStep(activeStep);
    setLastPeriod(Object.keys(periods).splice(-1)[0]);
  }, [periods]);
  const controlPeriod = () => {
    if (new Date(periods[lastPeriod]?.end_date).getTime() == new Date(contracts[selectedContract]?.end_date).getTime()) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <Box className={classes.root}>
        <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box>
          {activeStep === steps.length ? (
            <Box>
              <Typography className={classes.instructions}>All steps completed</Typography>
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          ) : (
            <Box>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep, props.type, e => setIsPeriadsNewAndEdit(e))}
              </Typography>
              <Box>
                <Button
                  disabled={activeStep === 0 || !rowStatus}
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                  className={classes.backButton}>
                  <IntlMessages id="previous" />
                </Button>

                {//son sayfa
                activeStep === steps.length - 1 ? (
                  //draft modundaysa
                  contracts[selectedContract]?.contract_statuses_id == 1 ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={confirmControl}
                        className={classes.nextButton}
                        disabled={contracts[selectedContract]?.contract_statuses_id == 1 || 2 ? false : true}>
                        <IntlMessages id="confirm" />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => changeContractStatus(1)}
                        className={classes.nextButton}
                        disabled={contracts[selectedContract]?.contract_statuses_id <= 1 ? false : true}>
                        <IntlMessages id="save.as.draft" />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.setOpen(false)}
                        className={classes.nextButton}>
                        <IntlMessages id="close" />
                      </Button>
                    </>
                  ) : //confirm Modundaysa
                  contracts[selectedContract]?.contract_statuses_id == 2 ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => changeContractStatus(3)}
                        className={classes.nextButton}
                        disabled={contracts[selectedContract]?.contract_statuses_id == 1 || 2 ? false : true}>
                        <IntlMessages id="sign" />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => changeContractStatus(1)}
                        className={classes.nextButton}
                        disabled={contracts[selectedContract].contract_statuses_id == 1 || 2 ? false : true}>
                        <IntlMessages id="back.to.draft" />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.setOpen(false)}
                        className={classes.nextButton}>
                        <IntlMessages id="close" />
                      </Button>
                    </>
                  ) : //sign modundaysa
                  contracts[selectedContract]?.contract_statuses_id == 3 ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEditMarkupOpen(true)}
                        className={classes.nextButton}
                        disabled={contracts[selectedContract]?.contract_statuses_id == 1 || 2 ? false : true}>
                        <IntlMessages id="assing markup" />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.setOpen(false)}
                        className={classes.nextButton}>
                        <IntlMessages id="close" />
                      </Button>
                      <Suspense fallback={<div>Loading...</div>}>
                        <MemoizedContractOnSale
                          editMarkupOpen={editMarkupOpen}
                          setEditMarkupOpen={setEditMarkupOpen}
                          selectedContract={selectedContract}
                          contractSync={false}></MemoizedContractOnSale>
                      </Suspense>
                    </>
                  ) : contracts[selectedContract]?.contract_statuses_id == 4 ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => changeContractStatus(5)}
                        className={classes.nextButton}
                        disabled={contracts[selectedContract]?.contract_statuses_id == 1 || 2 ? false : true}>
                        <IntlMessages id="revise" />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.setOpen(false)}
                        className={classes.nextButton}>
                        <IntlMessages id="close" />
                      </Button>
                    </>
                  ) : contracts[selectedContract]?.contract_statuses_id == 5 ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEditMarkupOpen(true)}
                        className={classes.nextButton}
                        disabled={contracts[selectedContract]?.contract_statuses_id == 1 || 2 ? false : true}>
                        <IntlMessages id="assing markup" />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.setOpen(false)}
                        className={classes.nextButton}>
                        <IntlMessages id="close" />
                      </Button>
                      <MemoizedContractOnSale
                        editMarkupOpen={editMarkupOpen}
                        setEditMarkupOpen={setEditMarkupOpen}
                        selectedContract={selectedContract}
                        contractSync={false}></MemoizedContractOnSale>
                    </>
                  ) : (
                    ''
                  )
                ) : //periyod sayfası
                activeStep === steps.length - 2 ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.nextButton}
                      disabled={isPeriadsNewAndEdit ? true : activeStep == 1 && controlPeriod() ? false : true}>
                      <IntlMessages id="next" />
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => changeContractStatus(1)}
                      className={classes.nextButton}
                      disabled={
                        isPeriadsNewAndEdit
                          ? true
                          : activeStep == 1 || contracts[selectedContract]?.contract_statuses_id == 4
                          ? false
                          : true
                      }>
                      <IntlMessages id="save.as.draft" />
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.nextButton}
                    disabled={
                      activeStep == 1 || contracts[selectedContract]?.contract_statuses_id == 4
                        ? false
                        : !checkContractDataIsUpdate
                    }>
                    <IntlMessages id="next" />
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default React.memo(WizardPage);
