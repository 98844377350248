import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import { getPermissionsByAuthUserService } from 'services/permission';
import { getRoutesService } from 'services/route';
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { resetReducer, setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';

import axios from './config';
import { auth } from '../../proxolabLogger/authUser/authUser';
import { authLogin } from '../../proxolabLogger/authUser/authUser';
import { authLogout } from '../../proxolabLogger/authUser/authUser';

/******* PROXOLAB SEND USER INFO FOR LOGIN-LOGOUT VARIABLE *********/
let user;
/*******************************************************************/

const JWTAuth = {
  onRegister: ({ name, email, password }) => dispatch => {
    dispatch(fetchStart());
    axios
      .post('auth/register', {
        email,
        password,
        name,
      })
      .then(({ data }) => {
        if (data.result) {
          localStorage.setItem('token', data.token.access_token);
          axios.defaults.headers.common.Authorization = `Bearer ${data.token.access_token}`;
          dispatch(fetchSuccess());
          dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
        } else {
          dispatch(fetchError(data.error));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  },

  onLogin: ({ email, password }) => dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .post('login', {
          email,
          password,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem('token', data.token.access_token);
            axios.defaults.headers.common.Authorization = `Bearer ${data.token.access_token}`;
            dispatch(fetchSuccess('Login Successful'));
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
            dispatch(getRoutesService());
          }

          /*********** PROXOLAB LOGGER *********/
          authLogin(user);
          /*************************************/
        })
        .catch(error => {
          dispatch(fetchError('Login Failed'));
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  },
  onMasterLogin: ({ email, password }) => dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .post('master/login', {
          email,
          password,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem('token', data.token.access_token);
            axios.defaults.headers.common.Authorization = `Bearer ${data.token.access_token}`;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  },
  onLogout: () => dispatch => {
    dispatch(fetchStart());
    const token = localStorage.getItem('token');
    // localStorage.removeItem('token');
    // localStorage.removeItem('selectedCompany');
    dispatch(setAuthUser(null));
    axios
      .post('logout', token)
      .then(data => {
        if (data) {
          dispatch(fetchSuccess());
          localStorage.removeItem('token');
          localStorage.removeItem('selectedCompany');
          localStorage.removeItem('userCompanies');
          localStorage.removeItem('selectedOffice');
          localStorage.removeItem('userOffices');
          dispatch(setAuthUser(null));
        } else {
          dispatch(fetchError(data.error));
        }
        dispatch(resetReducer());
        /*********** PROXOLAB LOGGER *********/
        authLogout(user);
        /***********************************/
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  },

  getAuthUser: (loaded = false, token) => dispatch => {
    if (!token) {
      const token = localStorage.getItem('token');
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    dispatch(updateLoadUser(loaded));
    axios
      .get('user')
      .then(({ data }) => {
        if (data.result) {
          dispatch(setAuthUser(data.user));
          dispatch(getPermissionsByAuthUserService());
        } else {
          localStorage.removeItem('token');
          dispatch(updateLoadUser(true));
        }

        /*************PROXOLABLOGGER*******************/
        user = data.user;
        auth(data.user);
        /**********************************************/
      })
      .catch(error => {
        localStorage.removeItem('token');
        dispatch(updateLoadUser(true));
      });
  },

  onForgotPassword: () => dispatch => {
    dispatch(fetchStart());

    setTimeout(() => {
      dispatch(setForgetPassMailSent(true));
      dispatch(fetchSuccess());
    }, 300);
  },
  getSocialMediaIcons: () => <div> </div>,
};

export default JWTAuth;
