import {
  getUserPermission,
  addUserPermission,
  updateUserPermission,
  deleteUserPermission,
} from 'redux/actions/userPermission';
import axios from './axios';
import { fetchStart, fetchError, fetchSuccess } from '../redux/actions/Common';

/**PROXOLAB LOGGER **/
import sendLogService from './proxolabLogger/Logger.js';
import Swal from 'sweetalert2';
/**PROXOLAB LOGGER **/

export const getUserPermissionService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('userPermission')
    .then(res => {
      dispatch(getUserPermission(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const addUserPermissionService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('userPermission', data)
    .then(res => {
      dispatch(addUserPermission(res));
      dispatch(fetchSuccess());
      Swal.fire({
        title: 'Success',
        text: 'User Permission added',
        icon: 'success',
        confirmButtonText: 'Ok',
      });
      sendLogService.userPermissionServiceLogs(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      Swal.fire({
        title: 'Error',
        text: e.message,
        icon: 'error',
        confirmButtonText: 'Ok',
      });

      sendLogService.errorlog(e, data);
    });
};

export const updateUserPermissionService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('userPermission', data)
    .then(res => {
      dispatch(updateUserPermission(res));
      dispatch(fetchSuccess());
      sendLogService.userPermissionServiceLogs(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteUserPermissionService = id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`userPermission/${id}`)
    .then(res => {
      dispatch(deleteUserPermission(res));
      dispatch(fetchSuccess());
      sendLogService.userPermissionServiceLogs(res, id);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};

export const getRoutes = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('routes')
    .then(res => {
      //dispatch(getUserPermission(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
