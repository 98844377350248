import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Autocomplete from '@mui/material/Autocomplete';
import AppTextInput from '../../Common/formElements/AppTextInput';
import GridContainer from '../../GridContainer';
import AppSelectBox from '../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { addAdminUserService } from 'services/user';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import SaveIcon from '@mui/icons-material/Save';
import { authUserMemo, companyData, officeMemo, get_destinations } from 'redux/selector/companyHasOfficeSelector';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import IntlMessages from '@jumbo/utils/IntlMessages';

import { InputLabel, Select, TextField } from '@material-ui/core';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useIntl } from 'react-intl';

const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  option: {
    fontSize: 15,
    /* '& > span': {
      marginRight: 10,
      fontSize: 18,
    }, */
  },
  dark: {
    '& .MuiChip-label': {
      color: 'white',
    },
  },
  light: {
    '& .MuiChip-label': {
      color: '#000', // black
    },
  },
}));

export default function AddUserDialog({ open, setOpen }) {
  const theme = createTheme({
    palette: {
      primary: green,
    },
  });

  const themeCancel = createTheme({
    palette: {
      primary: red,
    },
  });

  const dispatch = useDispatch();
  const themeMode = localStorage.getItem('theme-type');
  const { error_message } = useSelector(({ companyHasOffice }) => companyHasOffice.entities);
  const { success_message } = useSelector(({ companyHasOffice }) => companyHasOffice.entities);

  const newUserAlert = () => {
    Swal.fire({
      title: intl.formatMessage({ id: 'user.successfully.added' }),
      text: intl.formatMessage({ id: 'do.you.want.to.add.new.user' }),
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: intl.formatMessage({ id: 'yes' }),
      cancelButtonText: intl.formatMessage({ id: 'no' }),
    }).then(result => {
      if (result.isConfirmed) {
        setUserData(prevState => ({
          ...prevState,
          ...{ fullname: '', email: '' },
        }));
      } else {
        setOpen(false);
      }
    });
  };

  useEffect(() => {
    if (
      userData.fullname &&
      userData.email &&
      // password &&
      // passwordConfirm &&
      error_message == '' &&
      success_message == 'success'
    ) {
      newUserAlert();
    } else {
      if (error_message.email) {
        setErrorData({ ...errorData, email: error_message.email[0] });
      }
    }
  }, [error_message, success_message]);
  /* 
  useEffect(() => {
    if (
      fullname &&
      authorityLevel &&
      errorOffice == '' &&
      email &&
      password &&
      passwordConfirm &&
      success_message == 'success'
    ) {
      newUserAlert();
    }
  }, [success_message]); */

  const officesState = useSelector(officeMemo);
  const destinationData = useSelector(get_destinations);
  const companyOrEnterprise = useSelector(authUserMemo);
  const companies = useSelector(companyData);

  const classes = useStyles();
  const intl = useIntl();
  // const [password, setPassword] = useState('');
  // const [passwordConfirm, setPasswordConfirm] = useState('');
  const [choosedOffices, setChoosedOffices] = useState([]);
  const [choosedCompanies, setChoosedCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [showOffice, setShowOffice] = useState([]);

  const [userData, setUserData] = useState({
    fullname: '',
    email: '',
    company: '',
    office: '',
    authorityLevel: '',
  });

  const [errorData, setErrorData] = useState({
    fullname: '',
    email: '',
    company: '',
    office: '',
    authorityLevel: '',
  });

  useEffect(() => {
    let companiesID = [];
    destinationData.map(element => {
      if (companiesID.length == 0) {
        companiesID.push(element.company_id);
      } else if (!companiesID.find(company => element.company_id == company)) {
        companiesID.push(element.company_id);
      }
    });
    setChoosedCompanies(companiesID);
  }, [destinationData]);
  useEffect(() => {
    let officeControl = [];
    let officeData = [];
    let selectedID = [];

    // Get all offices
    if (userData.authorityLevel == 10 || userData.authorityLevel == 11) {
      setChoosedOffices(destinationData);
    }

    // Get all offices of selected company
    else if (userData.authorityLevel == 20) {
      destinationData.map(element => {
        if (selectedCompanies.includes(element.company_id)) {
          officeControl.push(element);
        }
      });
      setChoosedOffices(officeControl);
    }

    // Get selected office of selected company/companies
    else if (selectedCompanies && userData.authorityLevel == 30) {
      destinationData.map(element => {
        if (selectedCompanies.includes(element.company_id)) {
          selectedID.push(element.id);
          officeData.push(element);
        }
      });

      choosedOffices.map(element => {
        if (selectedID.includes(element.id)) {
          officeControl.push(element);
        }
      });
      officeData.sort(function(a, b) {
        return a.company_id - b.company_id;
      });

      setChoosedOffices(officeControl);
      setShowOffice(officeData);
    }
  }, [selectedCompanies, userData.authorityLevel]);

  const onSubmitClick = () => {
    const user = {
      ...userData,
      choosedOffices,
    };

    if (!userData.fullname) {
      setErrorData({ ...errorData, fullname: requiredMessage });
    } else {
      setErrorData({ ...errorData, fullname: '' });
    }
    if (!userData.authorityLevel) {
      setErrorData({ ...errorData, authorityLevel: requiredMessage });
    } else {
      setErrorData({ ...errorData, authorityLevel: '' });
    }
    if (choosedOffices.length == 0) {
      setErrorData({ ...errorData, office: requiredMessage });
    } else {
      setErrorData({ ...errorData, office: '' });
    }
    if (selectedCompanies.length == 0) {
      setErrorData({ ...errorData, company: requiredMessage });
    } else {
      setErrorData({ ...errorData, company: '' });
    }
    if (!userData.email) {
      setErrorData({ ...errorData, email: requiredMessage });
    } else {
      setErrorData({ ...errorData, email: '' });
    }

    // if (password != passwordConfirm || !password || !passwordConfirm) {
    //   setPassControl('Passwords is not equal or empty! Please check again');
    // } else {
    //   setPassControl('');
    // }
    if (
      userData.fullname &&
      userData.authorityLevel &&
      errorData.office == '' &&
      errorData.company == '' &&
      userData.email

      //  && password &&
      //   passwordConfirm &&
      //   password == passwordConfirm
    ) {
      dispatch(addAdminUserService(user));
      setErrorData({ ...errorData, email: '' });
    }
  };

  const handleChange = name => event => {
    setUserData({ ...userData, [name]: event.target.value });
    setErrorData({ ...errorData, [name]: '' });
  };

  const onCancelClick = () => {
    setOpen(false);
  };

  const [labels, setLabels] = useState(
    companyOrEnterprise == 10
      ? [
          { title: 'Enterprise', slug: '10' },
          { title: 'Company', slug: '20' },
          { title: 'Office', slug: '30' },
        ]
      : [
          { title: 'Company', slug: '11' },
          { title: 'Office', slug: '30' },
        ],
  );
  return (
    <Dialog open={open} className={classes.dialogRoot} disableEscapeKeyDown maxWidth="sm" fullWidth="on">
      <DialogTitle className={classes.dialogTitleRoot}>
        {' '}
        <IntlMessages id="create.new.user" />
      </DialogTitle>
      <DialogContent dividers>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            <AppTextInput
              variant="outlined"
              label={<IntlMessages id="name" />}
              value={userData.fullname}
              required
              helperText={errorData.fullname}
              onChange={e => {
                if (e.target.value.trim().length != 0) {
                  handleChange('fullname')(e);
                } else {
                  setErrorData({ ...errorData, fullname: requiredMessage });
                }
              }}
            />
          </Grid>
        </GridContainer>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            <AppTextInput
              variant="outlined"
              label={<IntlMessages id="email" />}
              value={userData.email}
              required
              helperText={errorData.email}
              autoComplete="new-password"
              onChange={e => {
                if (e.target.value.trim().length != 0) {
                  handleChange('email')(e);
                } else {
                  setErrorData({ ...errorData, email: requiredMessage });
                }
              }}
            />
          </Grid>
        </GridContainer>
        {/* 
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              variant="outlined"
              label="Password"
              value={password}
              type="password"
              autoComplete="new-password"
              onChange={e => {
                setPassword(e.target.value);
              }}
              required
              helperText={passControl}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              label="Password Confirmation"
              type="password"
              valueKey="slug"
              variant="outlined"
              autoComplete="new-password"
              value={passwordConfirm}
              onChange={e => setPasswordConfirm(e.target.value)}
              required
              helperText={passControl}
            />
          </Grid>
        </GridContainer> */}

        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            <AppSelectBox
              fullWidth
              data={labels}
              label={<IntlMessages id="authority.level" />}
              valueKey="slug"
              variant="outlined"
              labelKey="title"
              value={userData.authorityLevel}
              required
              helperText={errorData.authorityLevel}
              onChange={e => {
                handleChange('authorityLevel')(e);
              }}
            />
          </Grid>
        </GridContainer>

        {(userData.authorityLevel == 20 || userData.authorityLevel == 30) && (
          <GridContainer style={{ marginBottom: 12 }}>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple
                limitTags={2}
                options={choosedCompanies}
                defaultValue={choosedCompanies.length == 1 ? Object.values(companies)[0].name : ''}
                getOptionLabel={option => companies[option].name}
                /* defaultValue={[officeInfo[0]]} */
                filterSelectedOptions
                value={selectedCompanies}
                onChange={(e, value) => {
                  if (value.length == 0) {
                    setErrorData({ ...errorData, company: requiredMessage });
                  } else {
                    setErrorData({ ...errorData, company: '' });
                  }
                  setSelectedCompanies(value);
                }}
                renderInput={params => (
                  <AppTextInput
                    required
                    helperText={errorData.company}
                    {...params}
                    className={themeMode === 'dark' ? classes.dark : classes.light}
                    variant="outlined"
                    label={<IntlMessages id="select.company" />}
                    placeholder={intl.formatMessage({ id: 'select.company' })}
                  />
                )}
              />
            </Grid>
          </GridContainer>
        )}

        {showOffice.length != 0 && userData.authorityLevel == 30 && (
          <GridContainer style={{ marginBottom: 12 }}>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple
                limitTags={2}
                options={showOffice}
                getOptionLabel={option => option.name + ' ' + ' (' + ' ' + companies[option.company_id].name + ' ' + ' )'}
                groupBy={option => companies[option.company_id].name}
                filterSelectedOptions
                value={choosedOffices}
                onChange={(e, value) => {
                  if (value.length == 0) {
                    setErrorData({ ...errorData, office: requiredMessage });
                  } else {
                    setErrorData({ ...errorData, office: '' });
                  }
                  setChoosedOffices(value);
                }}
                renderInput={params => (
                  <AppTextInput
                    required
                    helperText={errorData.office}
                    {...params}
                    className={themeMode === 'dark' ? classes.dark : classes.light}
                    variant="outlined"
                    label={<IntlMessages id="select.office" />}
                    placeholder={intl.formatMessage({ id: 'select.office' })}
                  />
                )}
              />
            </Grid>
          </GridContainer>
        )}

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <ThemeProvider theme={themeCancel}>
            <Button variant="contained" color="primary" onClick={onCancelClick}>
              <IntlMessages id="cancel" />
            </Button>
          </ThemeProvider>
          <Box ml={2}>
            <ThemeProvider theme={theme}>
              <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={onSubmitClick}>
                <IntlMessages id="save" />
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
