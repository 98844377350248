import React, { useEffect, useState } from 'react';
import { getMarkupsService } from 'services/markup';
import { getOperatorService } from 'services/operator';
import { getMarketService } from 'services/market';
import { getCurrenciesService } from 'services/currency';
import { getSeasonService } from 'services/season';
import { getStarRatesService } from 'services/starRate';
import { getProductTypesService } from 'services/productTypes';
import { getCountriesService, getRegionsService } from 'services/destinations';
import { getBoardsService } from 'services/boards';
import { getRateTypesService } from 'services/rateType';
import { getAllotmentTypeService } from 'services/allotmentType';
import { getBanksService } from 'services/banks';
import { getPaymentMethodsService } from 'services/paymentMethods';
import { getPaymentConditionsService } from 'services/paymentConditions';
import { getStayTypesService } from 'services/stayType';
import { getCancellationPoliciesServices } from 'services/cancellationPoliciesDetail';
import { getAdminCompaniesService } from 'services/company';
import { getReservationsService } from 'services/reservation';
import { getOnSaleContractService } from 'services/onSaleContract';
import { getExtraService } from 'services/extras';
import { getServiceService } from 'services/services';
import { getHotelService } from 'services/hotels';
import { getRoomService } from 'services/rooms';
import { ContractConfirmControlService, getContractService } from 'services/contract';
import { useDispatch, useSelector } from 'react-redux';
import { MemoizedDashboardInfo } from './DashboardInfo';
import Loading from './Loading';

const DashboardLoading = () => {
  const dispatch = useDispatch();
  const [isFinished, setIsFinished] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);
  const { currencies } = useSelector(({ currencies }) => currencies);

  useEffect(() => {
    const firstLoader = async () => {
      if (currencies.length === 0) {
        await Promise.all([
          dispatch(getContractService(authUser.authority_level, localStorage.getItem('selectedCompany'))),
          dispatch(getOnSaleContractService()),
          dispatch(getRegionsService()),
          dispatch(getAdminCompaniesService()),
          dispatch(getBoardsService()),
          dispatch(getHotelService()),
          dispatch(getProductTypesService()),
          dispatch(getSeasonService()),
          dispatch(getMarketService()),
          dispatch(getOperatorService()),
          dispatch(getMarkupsService()),
          dispatch(getCurrenciesService()),
          dispatch(getRateTypesService()),
          dispatch(getBanksService()),
          dispatch(getPaymentMethodsService()),
          dispatch(getPaymentConditionsService()),
          dispatch(getAllotmentTypeService()),
          dispatch(getStayTypesService()),
          dispatch(getCancellationPoliciesServices()),
          dispatch(getReservationsService()),
          dispatch(getStarRatesService()),
          dispatch(getRoomService()),
          dispatch(getExtraService()),
          dispatch(getServiceService()),
          dispatch(getCountriesService(['', 0])),
        ]);
        setIsFinished(true);
      } else {
        setIsFinished(true);
      }
    };
    firstLoader();

    setIsFinished(true);

  }, []);

  return <React.Fragment>{isFinished && <MemoizedDashboardInfo />}</React.Fragment>;
};

export default DashboardLoading;
