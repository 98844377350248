import React, { useEffect, useState, useCallback, Suspense, lazy } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, PagingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  ColumnChooser,
  TableColumnVisibility,
  Toolbar,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import ContractInfo from './ContractGraph/ContractInfo';
import ReservationInfo from './ReservationGraph/ReservationInfo';
import ReservationGraph from './ReservationGraph/ReservationGraph';
import TextField from '@material-ui/core/TextField';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import MenuItem from '@mui/material/MenuItem';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import ContractGraph from './ContractGraph/ContractGraph';
import { ContractConfirmControlService, getContractService } from 'services/contract';

import { Row } from 'react-grid-system';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { blue, blueGrey, amber } from '@material-ui/core/colors';

import { SET_SELECTED_CONTRACT } from 'redux/actions/types';

import { changeContractStatusService } from 'services/contract';

import { Box, Button, Paper, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Swal from 'sweetalert2';
import moment from 'moment';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import UpdateIcon from '@mui/icons-material/Update';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import IntlMessages from '@jumbo/utils/IntlMessages';
import WeekDayView from './ContractGraph/WeekDayView';
import ResWeekDayView from './ReservationGraph/ResWeekDayView';
import DayView from './ContractGraph/DayView';
import ResDayView from './ReservationGraph/ResDayView';
import { Grid as GridInCore } from '@material-ui/core';
import { useWhyDidYouUpdate } from '@jumbo/utils/useWhyDidYouUpdate';
import { hotelsSelector, contracts } from 'redux/selector/contract';

const MemoizedContractPdf = lazy(() => import('../Reports/ContractPdf'));
const MemoizedEditContract = lazy(() => import('./EditContract'));
const MemoizedContractCopy = lazy(() => import('./CopyContract/CopyContract'));
const MemoizedContractOnSale = lazy(() => import('./ContractResyncModal'));
const ContractSyncModal = lazy(() => import('./ContractSyncModal'));

const useStyles = makeStyles(theme => ({
  scrollContainer: {
    // height: 400,
    overflow: 'auto',
    marginBottom: theme.spacing(6),
  },
  scroll: {
    position: 'relative',
    // width: '230%',
    backgroundColor: theme.palette.background.paper,
    // height: '230%',
  },
  cardRoot: {
    '& .Cmt-card-content': {
      marginTop: -15,
    },
  },
  cmtCardStyle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    margin: '20px',
    paddingTop: '60px',
  },
  graphStyle: {
    marginTop: '4%',
    marginLeft: '10%',
  },
  titleSpace: {
    marginBottom: 20,
    fontWeight: theme.typography.fontWeightBold,
  },
  order2: {
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  order3: {
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
  row: {
    marginTop: '1rem',
    marginLeft: '1rem !important',
    minWidth: '14rem',
  },
  text: {
    width: '15%',
  },
  disabledButton: {
    color: '#435e7e !important',
    border: '1px solid rgb(255 255 255 / 15%) !important',
  },
  button: {
    color: '#2196f3 !important',
    border: '1px solid rgba(33, 150, 243, 0.5) !important',
  },
}));
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    id="basic-menu"
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
    }}
    transformOrigin={{
      vertical: 'top',
    }}
    {...props}
    MenuListProps={{
      'aria-labelledby': 'basic-button',
    }}
  />
));
const StyledMenuItem = withStyles(theme => ({}))(MenuItem);
export default function DashboardInfo() {
  const dispatch = useDispatch();

  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  const { authUser } = useSelector(({ auth }) => auth);

  let isAuthUpdate = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.put === 1);
  let isAuthView = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.get === 1);

  const [status, setStatus] = useState([
    { name: 'DRAFT', id: 1 },
    { name: 'CONFIRM', id: 2 },
    { name: 'SIGNED', id: 3 },
    { name: 'ON SALE', id: 4 },
    { name: 'REVISED', id: 5 },
  ]);
  let history = useHistory();
  const redirect = () => {
    history.push('/contract/newcontract');
    dispatch({ type: SET_SELECTED_CONTRACT, payload: 0 });
  };

  const themeButton = createTheme({
    palette: {
      primary: blue,
    },
  });
  const theme = createTheme({
    palette: {
      primary: blueGrey,
      secondary: amber,
    },
  });

  const [selectGraph, setSelectGraph] = useState('Contract');
  const [selectView, setSelectedView] = useState('Week');
  const [selectedContract, setSelectedContract] = useState(null);
  const [copyContractOpen, setCopyContractOpen] = useState(false);
  const [editMarkupOpen, setEditMarkupOpen] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [contractSync, setContractSync] = useState(null);
  const memoizedIsClickedEdit = useCallback(contractId => {
    dispatch({ type: SET_SELECTED_CONTRACT, payload: contractId });
  }, []);
  const graphNames = [
    {
      value: 'Contract',
      label: <IntlMessages id="contract" />,
    },
    {
      value: 'Reservation',
      label: <IntlMessages id="reservation" />,
    },
  ];
  const viewNames = [
    {
      value: 'Month',
      label: <IntlMessages id="month" />,
    },
    {
      value: 'Week',
      label: <IntlMessages id="week" />,
    },
    {
      value: 'Day',
      label: <IntlMessages id="day" />,
    },
  ];

  const hotels = useSelector(hotelsSelector);
  const boards = useSelector(state => state.boards.boards);
  const operators = useSelector(state => state.operators.operators);
  const seasons = useSelector(state => state.seasons.seasons);
  const regions = useSelector(state => state.destinations.regions);
  const currencies = useSelector(state => state.currencies.currencies);
  const productTypes = useSelector(state => state.productTypes.productTypes);
  const users = useSelector(state => state.companyHasOffice.entities.users);
  const contract_has_board = useSelector(state => state.contract.contract_has_board);
  const allotmentType = useSelector(state => state.allotmentType.allotmentType);
  const contractsSelector = useSelector(contracts);

  const getContracts = () => {
    let newContracts = [];
    if (
      Object.values(hotels).length > 0 &&
      productTypes.length > 0 &&
      Object.values(operators).length > 0 &&
      Object.values(seasons).length > 0 &&
      Object.values(regions).length > 0 &&
      Object.values(boards).length > 0 &&
      Object.values(allotmentType).length > 0 &&
      Object.values(currencies).length > 0 &&
      Object.values(users).length > 0 &&
      Object.values(contract_has_board).length > 0
    ) {
      Object.values(contractsSelector).forEach(contract => {
        let contractHotel = hotels[contract.hotel_id];
        let newContract = {
          ...contract,
          product_name: contractHotel.name,
          star_rating_name: contractHotel.star_rating + ' Star',
          type_of_product: productTypes.find(element => element.id === contractHotel.product_type_id).name,
          season_name: Object.values(seasons).find(element => element.id === contract.season_id).name,
          operator_string: (function() {
            let operator_string = '';
            contract.operators.forEach(operator => {
              operator_string += operators[operator].code + ' ';
            });
            return operator_string;
          })(),
          region_name: (function() {
            let region_string = '';
            Object.values(regions).forEach(region => {
              if (region.id === contractHotel.region_id) {
                region_string += region.name + ' ';
              }
            });
            return region_string;
          })(),
          contract_status: status.find(st => st.id === contract.contract_statuses_id).name,
          sync_name: (
            <Suspense fallback={<div>Loading...</div>}>
              <ContractSyncModal props={contract.sync} />
            </Suspense>
          ),
          board_string: (function() {
            let board_string = '';
            contract.contract_has_board.forEach(board => {
              board_string += boards[contract_has_board[board].board_id]?.code + ' ';
            });
            return board_string;
          })(),
          allotment_name: allotmentType[contract.allotment_type_id].name,
          currency_name: currencies[contract.currency]?.code,
          user_name: users[contract.user_id].fullname,
        };
        newContracts.push(newContract);
      });
    }
    return newContracts;
  };

  useWhyDidYouUpdate('DASHBOARD', {
    contracts: getContracts(),
    allotmentType: allotmentType,
    boards: boards,
    operators: operators,
    seasons: seasons,
    regions: regions,
    currencies: currencies,
    productTypes: productTypes,
    users: users,
    contract_has_board: contract_has_board,
    hotels: hotels,
    status: status,
  });

  const reservations = useSelector(state => state.reservation?.entities?.reservations);
  const handleClickOpen = (contractId, statusId) => {
    if (statusId == 2) {
      Swal.fire({
        text: 'Contract Updates in Draft Status.',
        title: 'Do you want to put the contract in draft mode?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: `No`,
      }).then(result => {
        //Read more about isConfirmed, isDenied below
        if (result.isConfirmed) {
          dispatch(
            changeContractStatusService({
              contract_id: contractId,
              status_id: '1',
            }),
          );
          memoizedIsClickedEdit(contractId);
          setEditOpen(true);
        }
      });
    } else {
      memoizedIsClickedEdit(contractId);
      setEditOpen(true);
    }
  };
  const [actionsOpen, setactionsOpen] = useState(null);
  const [selectedId, setselectedId] = useState(0);
  const [selectedContractStatus, setselectedContractStatus] = useState(0);
  const [rowDataSync, setrowDataSync] = useState('');
  const handleClickActionsOpen = (event, rowData) => {
    setactionsOpen(event.currentTarget);
    setselectedId(rowData.id);
    setselectedContractStatus(rowData.contract_statuses_id);
    setrowDataSync(rowData.sync);
  };
  const handleClickActionsClose = () => {
    setactionsOpen(null);
  };
  const [columns] = useState([
    {
      title: <IntlMessages id="actions" />,
      name: 'actions',
      getCellValue: rowData => {
        return (
          <ThemeProvider theme={themeButton}>
            <Button
              id="basic-button"
              aria-controls={actionsOpen ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={actionsOpen ? 'true' : undefined}
              variant="contained"
              color="primary"
              onClick={event => handleClickActionsOpen(event, rowData)}>
              <IntlMessages id="actions" />
            </Button>
          </ThemeProvider>
        );
      },
    },
    { title: <IntlMessages id="product" />, name: 'product_name' },
    { title: <IntlMessages id="star.rating" />, name: 'star_rating_name', hide: true },
    { title: <IntlMessages id="type.of.product" />, name: 'type_of_product', hide: true },
    { title: <IntlMessages id="season.name" />, name: 'season_name' },
    { title: <IntlMessages id="operator" />, name: 'operator_string' },
    { title: <IntlMessages id="region" />, name: 'region_name' },
    {
      title: <IntlMessages id="start.date" />,
      name: 'start_date',
      render: props => {
        return moment(props.start_date).format('DD-MM-YYYY');
      },
    },
    { title: <IntlMessages id="end.date" />, name: 'end_date', dateSetting: { locale: 'en-US' } },
    { title: <IntlMessages id="created.date" />, name: 'created_date', hide: true },
    { title: <IntlMessages id="signed.date" />, name: 'signed_date', hide: true },
    { title: <IntlMessages id="published.date" />, name: 'published_date', hide: true },
    { title: <IntlMessages id="contract.status" />, name: 'contract_status' },
    {
      title: <IntlMessages id="sync" />,
      name: 'sync_name',
    },
    /* {
      title: 'Markup',
      name: 'markup',
      width: '200%',
      getCellValue: rowData => {
        return (
          <ThemeProvider theme={themeButton}>
            <Button
              className={rowData.contract_statuses_id == 1 ? classes.disabledButton : classes.button}
              variant="outlined"
              disabled={rowData.contract_statuses_id == '1' || rowData.contract_statuses_id == '2'}
              onClick={() => {
                setSelectedContract(rowData.id);
                setEditMarkupOpen(true);
                setContractSync(rowData.sync);
              }}>
              {rowData.contract_statuses_id == 1 ? 'Assign Markup' : 'Edit Markup'}
            </Button>
          </ThemeProvider>
        );
      },
    }, */
    {
      title: <IntlMessages id="base.board" />,
      name: 'board_string',
      hide: true,
    },
    { title: <IntlMessages id="allotment.type" />, name: 'allotment_name' },
    {
      title: <IntlMessages id="currency" />,
      name: 'currency_name',
      hide: true,
    },
    { title: <IntlMessages id="user" />, name: 'user_name' },
  ]);
  const [defaultHiddenColumnNames] = useState([
    'star_rating_name',
    'created_date',
    'signed_date',
    'published_date',
    'board_string',
    'allotment_name',
  ]);
  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: 'actions', togglingEnabled: false },
    { columnName: 'edit', togglingEnabled: false },
    { columnName: 'copy', togglingEnabled: false },
    { columnName: 'viewPdf', togglingEnabled: false },
    { columnName: 'markup', togglingEnabled: false },
  ]);
  const classes = useStyles();
  const [filteringStateColumnExtensions] = useState([
    { columnName: 'actions', filteringEnabled: false },
    { columnName: 'edit', filteringEnabled: false },
    { columnName: 'copy', filteringEnabled: false },
    { columnName: 'viewPdf', filteringEnabled: false },
    { columnName: 'markup', filteringEnabled: false },
  ]);

  let totalContract = Object.values(getContracts()).length;
  let draftContract = [];
  let onSaleContracts = [];
  let signedContracts = [];
  let revisedContracts = [];
  let totalReservation = reservations != undefined ? Object.values(reservations).length : 0;
  let cancelledRes = [];
  let confirmedRes = [];
  Object.values(getContracts()).map(item => {
    if (item.contract_statuses_id == 1) {
      draftContract.push(item);
    } else if (item.contract_statuses_id == 4) {
      onSaleContracts.push(item);
    } else if (item.contract_statuses_id == 3) {
      signedContracts.push(item);
    } else if (item.contract_statuses_id == 2) {
      revisedContracts.push(item);
    }
  });
  if (reservations != undefined) {
    Object.values(reservations).map(item => {
      if (item.hotel_status == 3) {
        cancelledRes.push(item);
      } else if (item.hotel_status == 4) {
        confirmedRes.push(item);
      }
    });
  }
  const info = [
    {
      label: <IntlMessages id="total.contract" />,
      value: totalContract,
      color: '#246e89',
    },
    {
      label: <IntlMessages id="draft.contract" />,
      value: draftContract.length,
      color: '#388ea3',
    },
    {
      label: <IntlMessages id="onsale.contract" />,
      value: onSaleContracts.length,
      color: '#55afc0',
    },
    {
      label: <IntlMessages id="revised.contract" />,
      value: revisedContracts.length,
      color: '#91d1d3',
    },
    {
      label: <IntlMessages id="signed.contract" />,
      value: signedContracts.length,
      color: '#bae7e0',
    },
  ];
  const resInfo = [
    {
      label: <IntlMessages id="total.reservation" />,
      value: totalReservation,
      color: '#246e89',
    },
    {
      label: <IntlMessages id="cancelled.reservation" />,
      value: cancelledRes.length,
      color: '#388ea3',
    },
    {
      label: <IntlMessages id="confirmed.reservation" />,
      value: confirmedRes.length,
      color: '#55afc0',
    },
  ];
  const refreshPage = () => {
    dispatch(getContractService(authUser.authority_level, localStorage.getItem('selectedCompany')));
  };
  return (
    <React.Fragment>
      <StyledMenu
        id="customized-menu"
        anchorEl={actionsOpen}
        keepMounted
        open={Boolean(actionsOpen)}
        onClose={handleClickActionsClose}>
        {isAuthUpdate && selectedContractStatus == 1 && (
          <StyledMenuItem
            onClick={() => {
              handleClickActionsClose();
              setSelectedContract(selectedId);
              //setEditOpen(true);
              handleClickOpen(selectedId, selectedContractStatus);
            }}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <IntlMessages id={'update'} />
          </StyledMenuItem>
        )}
        {isAuthView && selectedContractStatus == 5 && (
          <StyledMenuItem
            onClick={() => {
              handleClickActionsClose();
              setSelectedContract(selectedId);
              //setEditOpen(true);
              handleClickOpen(selectedId, selectedContractStatus);
            }}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <IntlMessages id={'view'} />
          </StyledMenuItem>
        )}
        {isAuthUpdate && (
          <StyledMenuItem
            onClick={() => {
              setSelectedContract(selectedId);
              setCopyContractOpen(true);
              handleClickActionsClose();
            }}>
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" />
            </ListItemIcon>
            <IntlMessages id="copy" />
          </StyledMenuItem>
        )}
        {selectedContractStatus != 1 ? (
          <StyledMenuItem
            onClick={() => {
              setPdfOpen(true);
              setSelectedContract(selectedId);
              handleClickActionsClose();
            }}>
            <ListItemIcon>
              <PictureAsPdfIcon fontSize="small" />
            </ListItemIcon>
            <IntlMessages id="view.pdf" />
          </StyledMenuItem>
        ) : (
          <p></p>
        )}
        {selectedContractStatus == 1 && isAuthUpdate ? (
          <StyledMenuItem
            onClick={() => {
              dispatch(ContractConfirmControlService({ id: selectedId }));
              handleClickActionsClose();
            }}>
            <ListItemIcon>
              <ThumbUpAltIcon fontSize="small" />
            </ListItemIcon>
            <IntlMessages id="confirm" />
          </StyledMenuItem>
        ) : selectedContractStatus == 2 && isAuthUpdate ? (
          <>
            <StyledMenuItem
              onClick={() => {
                dispatch(
                  changeContractStatusService({
                    contract_id: selectedId,
                    status_id: '1',
                  }),
                );
                handleClickActionsClose();
              }}>
              <ListItemIcon>
                <ReceiptIcon fontSize="small" />
              </ListItemIcon>
              <IntlMessages id="draft" />
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                dispatch(
                  changeContractStatusService({
                    contract_id: selectedId,
                    status_id: '3',
                  }),
                );
                handleClickActionsClose();
              }}>
              <ListItemIcon>
                <AssignmentTurnedInIcon fontSize="small" />
              </ListItemIcon>
              <IntlMessages id="sign" />
            </StyledMenuItem>
          </>
        ) : (selectedContractStatus == 3 || selectedContractStatus == 5) && isAuthUpdate ? (
          <StyledMenuItem
            onClick={() => {
              setSelectedContract(selectedId);
              setEditMarkupOpen(true);
              setContractSync(rowDataSync);
              handleClickActionsClose();
            }}>
            <ListItemIcon>
              <PriceCheckIcon fontSize="small" />
            </ListItemIcon>
            <IntlMessages
              id={selectedContractStatus == 1 || selectedContractStatus == 5 ? 'assing markup' : 'edit.markup'}
            />
          </StyledMenuItem>
        ) : selectedContractStatus == 4 && isAuthUpdate ? (
          <StyledMenuItem
            onClick={() => {
              dispatch(
                changeContractStatusService({
                  contract_id: selectedId,
                  status_id: '5',
                }),
              );
              handleClickActionsClose();
            }}>
            <ListItemIcon>
              <UpdateIcon fontSize="small" />
            </ListItemIcon>
            <IntlMessages id="revise" />
          </StyledMenuItem>
        ) : (
          <p></p>
        )}
      </StyledMenu>
      <Paper>
        <div style={{ overflowX: 'hidden' /*grafik için eklendi*/ }}>
          <Typography variant="h6" component="h4" color="primary"></Typography>
          <Grid rows={getContracts()} columns={columns}>
            <FilteringState defaultFilters={[]} columnExtensions={filteringStateColumnExtensions} />
            <IntegratedFiltering />
            <PagingState defaultCurrentPage={0} pageSize={10} />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow />
            <PagingPanel />
            <ThemeProvider theme={theme}>
              <Row className={classes.row}>
                <Button variant="contained" onClick={redirect} startIcon={<LibraryAddIcon />} color={'primary'}>
                  <IntlMessages id="add.contract" />
                </Button>
                <Button
                  variant="contained"
                  /* onClick={() => handleClickOpen()} */
                  startIcon={<ImportExportIcon />}
                  style={{ marginLeft: '5px' }}
                  color={'secondary'}>
                  <IntlMessages id="import.contract" />
                </Button>
                <Button
                  variant="contained"
                  onClick={() => refreshPage()}
                  startIcon={<AutorenewIcon />}
                  style={{ marginLeft: '5px' }}
                  color={'inherit'}>
                  <IntlMessages id="refresh" />
                </Button>
              </Row>
            </ThemeProvider>
            <TableColumnVisibility
              defaultHiddenColumnNames={defaultHiddenColumnNames}
              columnExtensions={tableColumnVisibilityColumnExtensions}
            />
            <TableFilterRow />
            <Toolbar />
            <ColumnChooser />
          </Grid>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              pl: 1,
              ml: 2,
              mt: -5,
              flexGrow: 1,
              justifyContent: 'flex-start',
            }}>
            <TextField
              className={classes.text}
              select
              label="GRAPHICS"
              placeholder="GRAPHICS"
              name="graphics"
              size="small"
              focused
              variant="outlined"
              color="warning"
              value={selectGraph}
              onChange={event => setSelectGraph(event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}>
              {graphNames.map(graph => (
                <MenuItem key={graph.value} value={graph.value}>
                  {graph.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              pl: 1,
              ml: '280px',
              mt: '-40px',
              flexGrow: 1,
              justifyContent: 'flex-start',
            }}>
            <TextField
              className={classes.text}
              select
              label="VIEW"
              placeholder="VIEW"
              name="view"
              size="small"
              focused
              variant="outlined"
              color="warning"
              value={selectView}
              onChange={event => setSelectedView(event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}>
              {viewNames.map(graph => (
                <MenuItem key={graph.value} value={graph.value}>
                  {graph.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <div className={classes.scrollContainer}>
            <GridInCore className={classes.scroll} container>
              <div>
                {selectGraph == 'Contract' && selectView == 'Month' ? (
                  <CmtCard className={classes.cmtCardStyle}>
                    <div>
                      <CmtCardHeader
                        title={<IntlMessages id="contract.informations" />}
                        titleProps={{
                          variant: 'h1',
                          component: 'div',
                        }}
                      />
                      <CmtCardContent>
                        <Typography component="div" variant="h5" className={classes.titleSpace}>
                          <IntlMessages id="general.info" />
                        </Typography>
                        <Typography component="div" variant="h5" className={classes.titleSpace}>
                          <IntlMessages id="contract" />
                        </Typography>
                        <ContractInfo data={info} />
                      </CmtCardContent>
                    </div>
                    <div className={classes.graphStyle}>
                      <CmtCardContent>
                        <Typography component="div" variant="h5" className={classes.titleSpace}>
                          <IntlMessages id="contract" />
                        </Typography>
                        <ContractGraph />
                      </CmtCardContent>
                    </div>
                  </CmtCard>
                ) : selectGraph == 'Contract' && selectView == 'Week' ? (
                  <CmtCard className={classes.cmtCardStyle}>
                    <div>
                      <CmtCardContent>
                        <WeekDayView contract={Object.values(getContracts())} />
                      </CmtCardContent>
                    </div>
                  </CmtCard>
                ) : selectGraph == 'Contract' && selectView == 'Day' ? (
                  <CmtCard className={classes.cmtCardStyle}>
                    <div>
                      <CmtCardContent>
                        <DayView />
                      </CmtCardContent>
                    </div>
                  </CmtCard>
                ) : selectGraph == 'Reservation' && selectView == 'Month' ? (
                  <CmtCard className={classes.cmtCardStyle}>
                    <div>
                      <CmtCardHeader
                        title={<IntlMessages id="reservation.informations" />}
                        titleProps={{
                          variant: 'h1',
                          component: 'div',
                        }}
                      />
                      <CmtCardContent>
                        <Typography component="div" variant="h5" className={classes.titleSpace}>
                          <IntlMessages id="general.info" />
                        </Typography>
                        <ReservationInfo data={resInfo} />
                      </CmtCardContent>
                    </div>
                    <div className={classes.graphStyle}>
                      <CmtCardContent>
                        <Typography component="div" variant="h5" className={classes.titleSpace}>
                          <IntlMessages id="reservation" />
                        </Typography>
                        <ReservationGraph />
                      </CmtCardContent>
                    </div>
                  </CmtCard>
                ) : selectGraph == 'Reservation' && selectView == 'Week' ? (
                  <CmtCard className={classes.cmtCardStyle}>
                    <div>
                      <CmtCardContent>
                        <ResWeekDayView />
                      </CmtCardContent>
                    </div>
                  </CmtCard>
                ) : selectGraph == 'Reservation' && selectView == 'Day' ? (
                  <CmtCard className={classes.cmtCardStyle}>
                    <div>
                      <CmtCardContent>
                        <ResDayView />
                      </CmtCardContent>
                    </div>
                  </CmtCard>
                ) : null}
              </div>
            </GridInCore>
          </div>
          {copyContractOpen && (
            <Suspense fallback={<div>Loading...</div>}>
              <MemoizedContractCopy
                copyContractOpen={copyContractOpen}
                setCopyContractOpen={setCopyContractOpen}
                selectedContract={selectedContract}></MemoizedContractCopy>
            </Suspense>
          )}

          {editMarkupOpen && (
            <Suspense fallback={<div>Loading...</div>}>
              <MemoizedContractOnSale
                editMarkupOpen={editMarkupOpen}
                setEditMarkupOpen={setEditMarkupOpen}
                selectedContract={selectedContract}
                contractSync={contractSync}></MemoizedContractOnSale>
            </Suspense>
          )}
          {pdfOpen && (
            <Suspense fallback={<div>Loading...</div>}>
              <MemoizedContractPdf
                contractId={selectedContract}
                pdfOpen={pdfOpen}
                setPdfOpen={setPdfOpen}></MemoizedContractPdf>
            </Suspense>
          )}
          {editOpen && (
            <Suspense fallback={<div>Loading...</div>}>
              <MemoizedEditContract
                onClick={() => memoizedIsClickedEdit(selectedContract)}
                editOpen={editOpen}
                setEditOpen={setEditOpen}
                contract_id={selectedContract}
                type={'edit'}></MemoizedEditContract>
            </Suspense>
          )}
        </div>
      </Paper>
    </React.Fragment>
  );
}

export const MemoizedDashboardInfo = React.memo(DashboardInfo);
