import React, { useState, useEffect, useRef } from 'react';
import MaterialTable, { MTableEditRow } from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMarkupService,
  addRateRangesServices,
  deleteMarkupService,
  deleteRateRangesServices,
  getMarkupsService,
  updateMarkupService,
  updateRateRangesServices,
} from 'services/markup';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Autocomplete } from '@material-ui/lab';
import InputAdornment from '@mui/material/InputAdornment';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { PriceFormatCustom } from '../Common/NumberFormat/NumberFormat';
import { Button } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SelectAll from './SelectAll';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';
import { multiDataSetter } from '@jumbo/utils/commonHelper';
import { MultiSelect } from 'react-multi-select-component';

const theme = createTheme({
  palette: {
    backgroundColor: orange[400],
  },
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    '&:hover': {
      backgroundColor: orange[500],
    },
  },
}))(Button);

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: '',
    text: text,
  });
};

function Markup() {
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    dispatch(getMarkupsService());
  }, [dispatch]);
  const tableRef = useRef();
  const { markups } = useSelector(({ markups }) => markups);
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);

  let isAuthAdd = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.post === 1);
  let isAuthUpdate = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.put === 1);
  let isAuthDelete = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.delete === 1);

  const [isAddClick, setIsAddClick] = useState(true);

  const clickEdit = () => {
    tableRef.current.props.data.map(k => {
      k.tableData = { id: 0 };
    });
    setIsAddClick(!isAddClick);
  };

  //For keyboard date picker
  const date = new Date();
  const today = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

  //For Invalid Date Control
  const controlDate = date => {
    if (date != 'Invalid date') {
      return true;
    }
    return false;
  };

  const [columnsMarkUp, setColumnsBaseMarkUp] = useState([
    { title: 'Code', align: 'center', field: 'code', validate: rowData => (rowData.code ? true : false) },
    { title: 'Mark Up Name', align: 'center', field: 'name', validate: rowData => (rowData.name ? true : false) },
    {
      title: 'Selling Start Date',
      align: 'center',
      field: 'selling_start_date',
      render: rowData => {
        return moment(rowData.selling_start_date).format('DD/MM/YYYY');
      },
      validate: rowData => controlDate(rowData.selling_start_date),
      editComponent: props => (
        (props.rowData.selling_start_date = props.value ? moment(props.value).format('YYYY-MM-DD') : today),
        (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              name="selling_start_date"
              error={props.rowData.selling_start_date ? false : true}
              disablePast
              //format={localStorage.getItem('format')}
              format="dd/MM/yyyy"
              autoOk="true"
              value={moment(props.rowData.selling_start_date).format('YYYY-MM-DD')}
              onChange={date => props.onChange(moment(date).format('YYYY-MM-DD'))}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MuiPickersUtilsProvider>
        )
      ),
    },
    {
      title: 'Selling End Date',
      align: 'center',
      field: 'selling_end_date',
      render: rowData => {
        return moment(rowData.selling_end_date).format('DD/MM/YYYY');
      },
      validate: rowData => controlDate(rowData.selling_end_date),
      editComponent: props => (
        (props.rowData.selling_end_date = props.value ? moment(props.value).format('YYYY-MM-DD') : today),
        (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              name="selling_end_date"
              error={props.rowData.selling_end_date ? false : true}
              disablePast
              //format={localStorage.getItem('format')}
              format="dd/MM/yyyy"
              autoOk="true"
              value={moment(props.rowData.selling_end_date).format('YYYY-MM-DD')}
              minDate={props.rowData.selling_start_date}
              onChange={date => props.onChange(moment(date).format('YYYY-MM-DD'))}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MuiPickersUtilsProvider>
        )
      ),
    },
    {
      title: 'Stay Start Date',
      align: 'center',
      field: 'accomodation_start_date',
      render: rowData => {
        return moment(rowData.accomodation_start_date).format('DD/MM/YYYY');
      },
      validate: rowData => controlDate(rowData.accomodation_start_date),
      editComponent: props => (
        (props.rowData.accomodation_start_date = props.value ? moment(props.value).format('YYYY-MM-DD') : today),
        (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              name="accomodation_start_date"
              error={props.rowData.accomodation_start_date ? false : true}
              disablePast
              format="dd/MM/yyyy"
              autoOk="true"
              value={moment(props.rowData.accomodation_start_date).format('YYYY-MM-DD')}
              onChange={date => {
                props.onChange(moment(date).format('YYYY-MM-DD'));
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MuiPickersUtilsProvider>
        )
      ),
    },
    {
      title: 'Stay End Date',
      align: 'center',
      field: 'accomodation_end_date',
      render: rowData => {
        return moment(rowData.accomodation_end_date).format('DD/MM/YYYY');
      },
      validate: rowData => controlDate(rowData.accomodation_end_date),
      editComponent: props => (
        (props.rowData.accomodation_end_date = props.value ? moment(props.value).format('YYYY-MM-DD') : today),
        (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              name="accomodation_end_date"
              error={props.rowData.accomodation_end_date ? false : true}
              disablePast
              format="dd/MM/yyyy"
              autoOk="true"
              value={moment(props.rowData.accomodation_end_date).format('YYYY-MM-DD')}
              onChange={date => {
                props.onChange(moment(date).format('YYYY-MM-DD'));
              }}
              minDate={props.rowData.accomodation_start_date}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MuiPickersUtilsProvider>
        )
      ),
    },
  ]);
  return (
    <MaterialTable
      tableRef={tableRef}
      columns={columnsMarkUp}
      data={Object.values(markups)}
      title=""
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 20, 30, 40],
        actionsColumnIndex: -1,
        search: false,
        addRowPosition: 'first',
      }}
      detailPanel={rowData => (isAddClick ? <MarkupRates rowData={rowData} /> : null)}
      onRowClick={(event, rowData, togglePanel) => (isAddClick ? togglePanel() : null)}
      components={{
        EditRow: props => (
          <MTableEditRow
            {...props}
            onKeyDown={e => {
              if (e.keyCode === 27) {
                props.onEditingCanceled(props.mode, props.data);
              }
            }}
          />
        ),
        onRowAdd: props => (
          <MTableEditRow
            {...props}
            onKeyDown={e => {
              if (e.keyCode === 27) {
                props.onEditingCanceled(props.mode, props.data);
              }
            }}
          />
        ),
        Header: props => {
          return (
            <TableHead>
              <TableCell align="center" rowSpan={2}></TableCell>

              <TableCell align="center" rowSpan={2}>
                <IntlMessages id="code" />
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                <IntlMessages id="name" />
              </TableCell>
              <TableCell colSpan={2} align="center">
                <IntlMessages id="selling.date" />
              </TableCell>
              <TableCell colSpan={2} align="center">
                <IntlMessages id="stay.date" />
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                <IntlMessages id="actions" />
              </TableCell>
              <TableRow>
                <TableCell align="center">
                  <IntlMessages id="start.date" />
                </TableCell>
                <TableCell align="center">
                  <IntlMessages id="end.date" />
                </TableCell>
                <TableCell align="center">
                  <IntlMessages id="start.date" />
                </TableCell>
                <TableCell align="center">
                  <IntlMessages id="end.date" />
                </TableCell>
              </TableRow>
            </TableHead>
          );
        },
        EditRow: props => (
          <MTableEditRow
            {...props}
            onKeyDown={e => {
              if (e.keyCode === 27) {
                props.onEditingCanceled(props.mode, props.data);
              }
            }}
          />
        ),
        onRowAdd: props => (
          <MTableEditRow
            {...props}
            onKeyDown={e => {
              if (e.keyCode === 27) {
                props.onEditingCanceled(props.mode, props.data);
              }
            }}
          />
        ),
      }}
      actions={[
        isAuthAdd
          ? undefined
          : {
              icon: 'add',
              disabled: true,
              position: 'toolbar',
              tooltip: 'You are not authorized',
              onClick: (event, rowData) => {},
            },
      ]}
      icons={{
        Add: props => (
          <ThemeProvider theme={theme}>
            <ColorButton variant="contained" color="backgroundColor" startIcon={<AddBoxIcon />} onClick={clickEdit}>
              <IntlMessages id="add" />
            </ColorButton>
          </ThemeProvider>
        ),
        Edit: () => <ModeEditIcon style={{ color: 'black' }} onClick={clickEdit} />,
      }}
      editable={{
        deleteTooltip: row => (isAuthDelete ? 'Delete' : 'You are not authorized'),
        editTooltip: row => {
          return isAuthUpdate ? 'Update' : 'You are not authorized';
        },
        isDeletable: row => (isAuthDelete ? true : false),
        isEditable: row => (isAuthUpdate ? true : false),
        onRowAdd: isAuthAdd
          ? newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  setIsAddClick(true);
                  if (
                    !newData.code ||
                    !newData.name ||
                    !newData.accomodation_start_date ||
                    !newData.accomodation_end_date ||
                    !newData.selling_start_date ||
                    !newData.selling_end_date
                  ) {
                    sweetAlerts('error', intl.formatMessage({ id: 'empty.value' }));
                  } else if (moment(newData.selling_start_date).isAfter(newData.selling_end_date)) {
                    sweetAlerts('error', intl.formatMessage({ id: 'end.date.cant.be.smaller.than.start.date' }), 'Ooops...');
                    reject();
                  } else if (moment(newData.accomodation_start_date).isAfter(newData.accomodation_end_date)) {
                    sweetAlerts('error', intl.formatMessage({ id: 'end.date.cant.be.smaller.than.start.date' }), 'Ooops...');
                    reject();
                  } else {
                    newData.contract_id = 1; // burası gelen contract_idye göre değişçek
                    dispatch(addMarkupService(newData));
                  }
                  resolve();
                }, 1000);
              })
          : undefined,
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              setIsAddClick(true);
              markups[newData.id] = newData;
              if (
                !newData.code ||
                !newData.name ||
                !newData.accomodation_start_date ||
                !newData.accomodation_end_date ||
                !newData.selling_start_date ||
                !newData.selling_end_date
              ) {
                sweetAlerts('error', intl.formatMessage({ id: 'empty.value' }));
              } else if (moment(newData.selling_start_date).isAfter(newData.selling_end_date)) {
                sweetAlerts('error', intl.formatMessage({ id: 'end.date.cant.be.smaller.than.start.date' }), 'Ooops...');
                reject();
              } else if (moment(newData.accomodation_start_date).isAfter(newData.accomodation_end_date)) {
                sweetAlerts('error', intl.formatMessage({ id: 'end.date.cant.be.smaller.than.start.date' }), 'Ooops...');
                reject();
              } else {
                dispatch(updateMarkupService(markups[newData.id]));
              }
              resolve();
            }, 1000);
          }),
        onRowUpdateCancelled: () => {
          setIsAddClick(true);
        },
        onRowAddCancelled: () => {
          setIsAddClick(true);
        },
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              dispatch(deleteMarkupService(oldData.id));
              resolve();
            }, 1000);
          }),
      }}
    />
  );
}
export default Markup;

function MarkupRates(props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { rate_ranges } = useSelector(({ markups }) => markups);
  const [columnsValues, setColumnsValues] = useState([]);
  const [rateTypeOptions, setRateTypeOptions] = useState([]);
  const ratesWithMarkup = Object.values(rate_ranges).filter(rate_range => rate_range.markup_id == props.rowData?.id);
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  const isAuthAdd = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.post === 1);
  const isAuthUpdate = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.put === 1);
  const isAuthDelete = permissionsByAuthUser.some(permission => permission.route_id == 2 && permission.delete === 1);

  let rateType = {
    1: {
      id: 1,
      name: 'Per Person',
      code: 'PP',
    },
    2: {
      id: 2,
      name: 'Per Room',
      code: 'PR',
    },
  };

  useEffect(() => {
    setColumnsValues([
      {
        title: <IntlMessages id="rate.from" />,
        field: 'min_rate',
        type: 'numeric',
        align: 'center',
        cellStyle: {
          textAlign: 'center',
        },
        render: rowData => {
          return rowData.min_rate ? rowData.min_rate : '';
        },
        editComponent: p => (
          (p.rowData.min_rate = p.rowData.min_rate
            ? p.rowData.min_rate
            : ratesWithMarkup.length
            ? p.rowData.tabelData
              ? p.rowData.tabelData?.id == 0
                ? 0
                : ratesWithMarkup[p.rowData?.tableData?.id]?.min_rate
              : ratesWithMarkup[ratesWithMarkup.length - 1]
              ? (parseFloat(ratesWithMarkup[ratesWithMarkup.length - 1]?.max_rate) + 0.01).toFixed(2)
              : 0
            : 0),
          (
            <TextField
              type="number"
              value={p.rowData.min_rate}
              onChange={e => {
                p.onChange(e.target.value);
              }}
              style={{ width: '50%' }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                readOnly: true,
                shrink: true,
                step: 0.01,
                min: 0,
              }}
            />
          )
        ),
      },
      {
        title: <IntlMessages id="rate.to" />,
        field: 'max_rate',
        type: 'numeric',
        align: 'center',
        cellStyle: {
          textAlign: 'center',
        },
        validate: rowData => (rowData.max_rate && rowData.max_rate !== '0.00' ? true : false),
        editComponent: props => (
          <TextField
            error={props.rowData.min_rate <= props.rowData.max_rate ? false : true}
            //defaultValue={props.rowData.min_rate ? props.rowData.min_rate : 0.01}
            value={props.value ? props.value : null}
            onChange={e => {
              props.onChange(e.target.value);
            }}
            InputProps={{
              inputComponent: PriceFormatCustom,
            }}
            style={{ width: '50%' }}
            /* inputProps={{
              shrink: true,
              step: 0.01,
              min: props.rowData.min_rate,
              //inputComponent: {NumberFormatCustom},
            }} */
          />
        ),
      },
      {
        title: <IntlMessages id="apply.type" />,
        field: 'apply_type',
        align: 'center',
        cellStyle: {
          textAlign: 'center',
        },
        validate: rowData => (rowData.apply_type ? true : false),
        render: rowData => {
          let rateString = '';
          rowData.apply_type.forEach(element => {
            rateString = rateString + rateType[element].code + ' ';
          });
          return rateString;
        },
        editComponent: ({ onChange, value, rowData }) => {
          if (!(value?.length > 0 && value[0] instanceof Object)) {
            value = multiDataSetter(
              'code',
              'id',
              value?.map(v => rateType[v]),
            );
          }
          return (
            <MultiSelect
              options={multiDataSetter('code', 'id', Object.values(rateType))}
              value={multiDataSetter('code', 'id', value)}
              onChange={e => onChange(e)}
              labelledBy="Select"
              overrideStrings={{ selectSomeItems: 'Select Rate Types' }}
            />
          );
        },
        // editComponent: p => (
        //   <SelectAll
        //     optionLabel={'name'}
        //     optionValue={'code'}
        //     optionId={'id'}
        //     label="none"
        //     options={Object.values(rateType)}
        //     onChange={val => {
        //       const c = val.map(k => {
        //         if (k?.id === undefined) {
        //           return k;
        //         } else {
        //           return k.id;
        //         }
        //       });
        //       p.onChange(c);
        //     }}
        //     value={p.value ? p.value : []}
        //     placeholder={intl.formatMessage({ id: 'apply.type' })}
        //   />
        //   /* ,
        //   (
        //     <Autocomplete
        //       multiple
        //       options={Object.keys(rateType)}
        //       onChange={(event, value) => {
        //         p.onChange(value);
        //       }}
        //       value={p.value}
        //       getOptionLabel={option => rateType[option].name}
        //       renderInput={params => (
        //         <TextField
        //           error={p.rowData.apply_type ? false : true}
        //           {...params}
        //           placeholder="Rate Type"
        //           InputLabelProps={{
        //             shrink: true,
        //           }}
        //         />
        //       )}
        //     />
        //   ) */
        // ),
      },
      {
        title: <IntlMessages id="value" />,
        field: 'value',
        type: 'number',
        align: 'center',
        cellStyle: {
          textAlign: 'center',
        },
        render: rowData => {
          return rowData.value ? rowData.value : '';
        },
        validate: rowData => (rowData.value || rowData.percent ? true : false),
        editComponent: p => (
          <TextField
            error={p.rowData.value || p.rowData.percent ? false : true}
            //type="number"
            disabled={
              p.rowData.hasOwnProperty('percent')
                ? Number(p.rowData.percent) !== 0 || null
                  ? p.rowData.percent !== ''
                    ? Number(p.rowData.percent) > 0
                      ? true
                      : false
                    : false
                  : false
                : false
            }
            InputProps={{
              inputComponent: PriceFormatCustom,
            }}
            /* inputProps={{
              shrink: true,
              // inputComponent: NumberFormatCustom,
              step: 0.01,
              inputProps: {
                min: 0,
              },
            }} */
            value={p.value}
            onChange={e => p.onChange(e.target.value > 0 ? e.target.value : '')}
          />
        ),
      },
      {
        title: <IntlMessages id="percent" />,
        field: 'percent',
        type: 'number',
        align: 'center',
        cellStyle: {
          textAlign: 'center',
        },
        validate: rowData => (rowData.value || rowData.percent ? true : false),
        editComponent: props => (
          <TextField
            error={props.rowData.value || props.rowData.percent ? false : true}
            //type="number"
            disabled={
              props.rowData.hasOwnProperty('value')
                ? Number(props.rowData.value) !== 0 || null
                  ? props.rowData.value !== ''
                    ? Number(props.rowData.value) > 0
                      ? true
                      : false
                    : false
                  : false
                : false
            }
            InputProps={{
              startAdornment: <InputAdornment position="start">%</InputAdornment>,
              inputComponent: PriceFormatCustom,
              /* inputProps: {
                step: 0.01,
                inputProps: {
                  min: 0,
                },
              }, */
            }}
            value={Number(props.rowData.percent) > 0 ? parseFloat(props.rowData.percent) /* .toFixed(2) */ : ''}
            onChange={e => props.onChange(e.target.value > 0 ? e.target.value : '')}
          />
        ),
      },
    ]);
  }, [rate_ranges]);

  return (
    <MaterialTable
      title={<IntlMessages id="rates" />}
      options={{
        search: false,
        actionsColumnIndex: -1,
        addRowPosition: 'first',
      }}
      components={{
        EditRow: props => (
          <MTableEditRow
            {...props}
            onKeyDown={e => {
              if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
              }
            }}
            onEditingCanceled={(mode, rowData) => {
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (mode == 'update') {
                    Swal.fire({
                      title: intl.formatMessage({ id: 'are.you.sure?' }),
                      text: intl.formatMessage({ id: 'do.you.want.to.cancel.the.changes' }),
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#41C329',
                      allowOutsideClick: false,
                      cancelButtonColor: '#d33',
                      confirmButtonText: intl.formatMessage({ id: 'yes' }),
                      cancelButtonText: intl.formatMessage({ id: 'no' }),
                    }).then(result => {
                      if (result.isConfirmed) {
                        props.onEditingCanceled(mode);
                        resolve();
                      } else if (result.isDenied) {
                        reject();
                      }
                    });
                  }
                  if (mode == 'add') {
                    Swal.fire({
                      title: intl.formatMessage({ id: 'are.you.sure?' }),
                      text: intl.formatMessage({ id: 'do.you.want.to.cancel.the.changes' }),
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#41C329',
                      allowOutsideClick: false,
                      cancelButtonColor: '#d33',
                      confirmButtonText: intl.formatMessage({ id: 'yes' }),
                      cancelButtonText: intl.formatMessage({ id: 'no' }),
                    }).then(result => {
                      if (result.isConfirmed) {
                        props.onEditingCanceled(mode, rowData);
                        resolve();
                      } else if (result.isDenied) {
                        reject();
                      }
                    });
                  }
                  if (mode == 'delete') {
                    props.onEditingCanceled(mode, rowData);
                  }
                });
              });
            }}
          />
        ),
        onRowAdd: props => (
          <MTableEditRow
            {...props}
            onKeyDown={e => {
              if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
              }
            }}
          />
        ),
      }}
      columns={columnsValues}
      data={Object.values(rate_ranges).filter(rate_range => rate_range.markup_id == props.rowData.id)}
      actions={[
        isAuthAdd
          ? undefined
          : {
              icon: 'add',
              disabled: true,
              position: 'toolbar',
              tooltip: 'You are not authorized',
            },
      ]}
      icons={{
        Add: props => (
          <ThemeProvider theme={theme}>
            <Button variant="contained" color="primary" startIcon={<AddBoxIcon />}>
              <IntlMessages id="add" />
            </Button>
          </ThemeProvider>
        ),
      }}
      editable={{
        deleteTooltip: row => (isAuthDelete ? 'Delete' : 'You are not authorized'),
        editTooltip: row => (isAuthUpdate ? 'Update' : 'You are not authorized'),
        isDeletable: row => (isAuthDelete ? true : false),
        isEditable: row => (isAuthUpdate ? true : false),
        onRowAdd: isAuthAdd
          ? newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  newData['min_rate'] = newData['min_rate']
                    ? newData['min_rate']
                    : ratesWithMarkup.length
                    ? ratesWithMarkup[ratesWithMarkup.length - 1]?.max_rate + 0.01
                    : 0;
                  newData['max_rate'] = newData['max_rate']
                    ? newData['max_rate']
                    : (parseFloat(newData['min_rate']) + 0.01).toFixed(2);
                  newData.markup_id = props.rowData.id;

                  if (!newData.max_rate || !newData.apply_type || !(newData.percent || newData.value)) {
                    reject();
                    sweetAlerts('error', intl.formatMessage({ id: 'empty.value' }));
                  } else if (newData.max_rate < 0 || newData.apply_type < 0 || newData.percent < 0 || newData.value < 0) {
                    reject();
                    sweetAlerts('error', intl.formatMessage({ id: 'negative.value' }));
                  } else {
                    newData['apply_type'] = newData.apply_type.map(type => type.id);
                    dispatch(addRateRangesServices(newData));
                    resolve();
                  }
                }, 1000);
              })
          : undefined,
        onRowUpdate: newData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              if (!newData.min_rate || !newData.max_rate || !newData.apply_type || !(newData.percent || newData.value)) {
                sweetAlerts('error', intl.formatMessage({ id: 'empty.value' }));
              } else if (newData.max_rate < 0 || newData.apply_type < 0 || newData.percent < 0 || newData.value < 0) {
                reject();
                sweetAlerts('error', intl.formatMessage({ id: 'negative.value' }));
              } else {
                if (newData?.apply_type[0].hasOwnProperty('id')) {
                  newData['apply_type'] = newData?.apply_type.map(type => type.id);
                }
                dispatch(updateRateRangesServices(newData));
              }

              resolve();
            }, 1000);
          }),
        onRowDelete: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              dispatch(deleteRateRangesServices(newData.id));

              resolve();
            }, 1000);
          }),
      }}
    />
  );
}
