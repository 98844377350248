import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableEditRow } from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import { getRoomService, addRoomService, updateRoomService, deleteRoomService } from 'services/rooms';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Button } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';
import swal from 'sweetalert';

const theme = createTheme({
  palette: {
    backgroundColor: orange[400],
  },
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    '&:hover': {
      backgroundColor: orange[500],
    },
  },
}))(Button);

const MySwal = withReactContent(Swal);

const keysForRoomState = ['type_id', 'grade_id', 'base_id', 'view_id', 'sub_type_id', 'promo_id'];
const keysForRoomCode = ['types', 'grades', 'bases', 'views', 'subTypes', 'promos'];
export default function AddRoomType({ isAuthAdd, isAuthUpdate, isAuthDelete }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
     if(Object.values(rooms).length === 0){
      dispatch(getRoomService());
     }
  }, [dispatch]);

  const { rooms } = useSelector(({ rooms }) => rooms);
  const {
    rooms: { roomType },
  } = useSelector(({ rooms }) => rooms);
  const roomState = useSelector(state => state.rooms.rooms.roomType);
  const contract_rooms = useSelector(state => state.contract.contract_room);
  const allStates = useSelector(state => state.rooms.rooms);
  const [all, setAll] = useState({});

  // Backendden gelirken bir array içinde birden fazla obje geliyodu ikisini de
  // obje yapıp lookup'a koymak için useEffect yapıldı
  useEffect(() => {
    if (rooms.roomType !== undefined) {
      Object.keys(rooms).forEach(key => {
        Object.values(rooms[key]).forEach(type => {
          setAll(prev => ({
            ...prev,
            [key]: { ...prev[key], [type.id]: type.name },
          }));
        });
      });
    }
  }, [rooms]);

  const hasRoomtype = room_type_id => {
    //To check if the room type is in the use in contracts
    var has = true;
    Object.values(contract_rooms).forEach(contract_room => {
      if (contract_room.room_id == room_type_id) {
        has = false;
      }
    });
    return has;
  };

  const [room, setRoom] = useState({});
  useEffect(() => {
    if (roomType) {
      setRoom(Object.values(roomType));
    }
  }, [roomType]);

  const tableColumns = [
    {
      title: <IntlMessages id="code" />,
      field: 'code',
      width: '20%',
      editComponent: props => (
        <TextField
          id="code"
          name="code"
          inputProps={{ style: { textTransform: 'uppercase' }, readOnly: true }}
          defaultValue={props.rowData.code}
        />
      ),
    },
    {
      title: <IntlMessages id="type" />,
      field: 'type_id',
      id: 'type_id',
      width: '20%',
      lookup: all.types,
      validate: rowData => (rowData.type_id ? true : false),
      editComponent: props => {
        return (
          <Autocomplete
            options={Object.values(rooms.types)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={rooms.types[props.rowData['type_id']]}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        );
      },
    },
    {
      title: <IntlMessages id="grade" />,
      field: 'grade_id',
      width: '20%',
      lookup: all.grades,
      validate: rowData => (rowData.grade_id ? true : false),
      editComponent: props => {
        return (
          <Autocomplete
            options={Object.values(rooms.grades)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={rooms.grades[props.rowData['grade_id']]}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        );
      },
    },
    {
      title: <IntlMessages id="base" />,
      field: 'base_id',
      width: '20%',
      lookup: all.bases,
      initialEditValue: 1,
      validate: rowData => (rowData.base_id ? true : false),
      editComponent: props => {
        return (
          <Autocomplete
            options={Object.values(rooms.bases)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={rooms.bases[props.rowData['base_id']]}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                defaultValue="NotDefined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        );
      },
    },
    {
      title: <IntlMessages id="view" />,
      field: 'view_id',
      width: '20%',
      lookup: all.views,
      initialEditValue: 1,
      validate: rowData => (rowData.view_id ? true : false),
      editComponent: props => {
        return (
          <Autocomplete
            options={Object.values(rooms.views)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={rooms.views[props.rowData['view_id']]}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        );
      },
    },
    {
      title: <IntlMessages id="sub.type" />,
      field: 'sub_type_id',
      width: '20%',
      lookup: all.subTypes,
      initialEditValue: 1,
      validate: rowData => (rowData.sub_type_id ? true : false),
      editComponent: props => {
        return (
          <Autocomplete
            options={Object.values(rooms.subTypes)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={rooms.subTypes[props.rowData['sub_type_id']]}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        );
      },
    },
    {
      title: <IntlMessages id="promo" />,
      field: 'promo_id',
      width: '20%',
      lookup: all.promos,
      initialEditValue: 1,
      validate: rowData => (rowData.promo_id ? true : false),
      editComponent: props => {
        return (
          <Autocomplete
            options={Object.values(rooms.promos)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={props.rowData['promo_id'] !== '' ? rooms.promos[props.rowData['promo_id']] : ''}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        );
      },
    },
  ];
  return (
    <MaterialTable
      columns={tableColumns}
      data={room ? room : {}}
      title=""
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 20, 30, 40],
        tableLayout: 'fixed',
        actionsColumnIndex: -1,
        search: false,
        filtering: true,
        addRowPosition: 'first',
      }}
      actions={[
        isAuthAdd
          ? undefined
          : {
              icon: 'add',
              disabled: true,
              position: 'toolbar',
              tooltip: 'You are not authorized',
            },
      ]}
      components={{
        EditRow: props => {
          return (
            <MTableEditRow
              {...props}
              onKeyDown={e => {
                if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                  e.preventDefault();
                }
              }}
              onEditingCanceled={(mode, rowData) => {
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (mode == 'update') {
                      Swal.fire({
                        title: intl.formatMessage({ id: 'are.you.sure?' }),
                        text: intl.formatMessage({ id: 'do.you.want.to.cancel.the.changes' }),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#41C329',
                        allowOutsideClick: false,
                        cancelButtonColor: '#d33',
                        confirmButtonText: intl.formatMessage({ id: 'yes' }),
                        cancelButtonText: intl.formatMessage({ id: 'no' }),
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == 'add') {
                      Swal.fire({
                        title: intl.formatMessage({ id: 'are.you.sure?' }),
                        text: intl.formatMessage({ id: 'do.you.want.to.cancel.the.changes' }),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#41C329',
                        allowOutsideClick: false,
                        cancelButtonColor: '#d33',
                        confirmButtonText: intl.formatMessage({ id: 'yes' }),
                        cancelButtonText: intl.formatMessage({ id: 'no' }),
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode, rowData);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == 'delete') {
                      props.onEditingCanceled(mode, rowData);
                    }
                  });
                });
              }}
            />
          );
        },
        onRowAdd: props => (
          <MTableEditRow
            {...props}
            onKeyDown={e => {
              if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
              }
            }}
          />
        ),
      }}
      icons={{
        Add: props => (
          <ThemeProvider theme={theme}>
            <ColorButton variant="contained" color="backgroundColor" startIcon={<AddBoxIcon />}>
              <IntlMessages id="add" />
            </ColorButton>
          </ThemeProvider>
        ),
      }}
      editable={{
        deleteTooltip: row => (isAuthDelete ? 'Delete' : 'You are not authorized'),
        editTooltip: row => (isAuthUpdate ? 'Update' : 'You are not authorized'),
        isDeletable: row => (isAuthDelete ? true : false),
        isEditable: row => (isAuthUpdate && hasRoomtype(row.id) ? true : false),

        onRowAdd: isAuthAdd
          ? newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  var code = '';
                  keysForRoomState.forEach((element, key) => {
                    if (newData[element] !== 0 && newData[element] !== 'code') {
                      code = code.concat(rooms[keysForRoomCode[key]][newData[element]].code);
                    }
                  });
                  newData['code'] = code;
                  if (
                    !newData.type_id ||
                    !newData.grade_id ||
                    !newData.base_id ||
                    !newData.view_id ||
                    !newData.sub_type_id ||
                    !newData.promo_id
                  ) {
                    MySwal.fire('Oops...', intl.formatMessage({ id: 'please.fill.in.all.fields' }), 'error');
                    reject();
                  } else if (
                    Object.values(roomType).filter(
                      room =>
                        room.base_id === newData.base_id &&
                        room.type_id === newData.type_id &&
                        room.grade_id === newData.grade_id &&
                        room.view_id === newData.view_id &&
                        room.sub_type_id === newData.sub_type_id &&
                        room.promo_id === newData.promo_id,
                    ).length > 0
                  ) {
                    MySwal.fire(
                      'Oops...',
                      intl.formatMessage({ id: 'room.type' }) + intl.formatMessage({ id: 'is.already.exists' }),
                      'error',
                    );
                    reject();
                  } else {
                    dispatch(addRoomService(newData)).then(res => {
                      if (res === 201) {
                        MySwal.fire({
                          icon: 'success',
                          text: intl.formatMessage({ id: 'room.type' }) + intl.formatMessage({ id: 'created.successfully' }),
                        });
                      }
                    });
                    resolve();
                  }
                }, 1000);
              })
          : undefined,
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              {
                const selectedTypes = {
                  type_id: newData.type_id,
                  grade_id: newData.grade_id,
                  base_id: newData.base_id,
                  view_id: newData.view_id,
                  sub_type_id: newData.sub_type_id,
                  promo_id: newData.promo_id,
                };
                var code = '';
                keysForRoomState.forEach((element, key) => {
                  if (newData[element] !== 0 && newData[element] !== 'code') {
                    code = code.concat(rooms[keysForRoomCode[key]][newData[element]].code);
                  }
                });
              }
              newData['code'] = code;
              dispatch(updateRoomService(newData, oldData)).then(res => {
                if (res === 201) {
                  MySwal.fire({
                    icon: 'success',
                    text: intl.formatMessage({ id: 'room.type' }) + intl.formatMessage({ id: 'updated.successfully' }),
                  });
                }
              });
              resolve();
            }, 1000);
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              //delete patlıyor olabilir siliyor ama stateten silemiyor sonra ayarlanacak.
              if (hasRoomtype(oldData.id)) {
                //roomtype var mı diye kontrol ediyoruz
                dispatch(deleteRoomService(oldData.id)).then(res => {
                  if (res === 201) {
                    MySwal.fire({
                      icon: 'success',
                      text: intl.formatMessage({ id: 'room.type' }) + intl.formatMessage({ id: 'deleted.successfully' }),
                    });
                  }
                });
                resolve();
              } else {
                MySwal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: intl.formatMessage({ id: 'room.type' }) + intl.formatMessage({ id: 'is.in.use' }),
                });
              }
            }, 1000);
          }),
      }}
    />
  );
}
