import { normalizeTasks } from 'redux/schemas/contract';
import {
  GET_CONTRACT,
  ADD_CONTRACT,
  UPDATE_CONTRACT,
  DELETE_CONTRACT,
  GET_CONTRACT_ROOM,
  ADD_CONTRACT_ROOM,
  UPDATE_CONTRACT_ROOM,
  DELETE_CONTRACT_ROOM,
  IS_SAVED_CONTRACT,
  CHANGE_CONTRACT_STATUS,
  COPY_CONTRACT,
} from './types';

export const getContract = res => dispatch => {
  const normalizeData = normalizeTasks(res.data.data);
  dispatch({
    type: GET_CONTRACT,
    payload: normalizeData.entities,
  });
};

export const addContract = res => dispatch =>
  dispatch({
    type: ADD_CONTRACT,
    payload: res.data.data,
  });
export const isSavedConract = res => dispatch => {
  dispatch({
    type: IS_SAVED_CONTRACT,
    payload: res,
  });
};

export const updateContract = res => dispatch => {
  dispatch({
    type: UPDATE_CONTRACT,
    payload: res.data.data,
  });
};

export const deleteContract = () => dispatch =>
  dispatch({
    type: DELETE_CONTRACT,
  });

export const getContractRoom = res => dispatch => {
  //  const normalizeData = normalizeTasks(res.data.data);
  dispatch({
    type: GET_CONTRACT_ROOM,
    // payload: res.data.data,
    payload: res.data.data,
  });
};
export const addCotractRoom = res => dispatch => {
  /* dispatch({
    type: ADD_ROOM,
    payload: res.data.room,
  }); */
  dispatch({
    type: ADD_CONTRACT_ROOM,
    payload: res.data.data,
  });
};
export const updateCotractRoom = res => dispatch => {
  dispatch({
    type: UPDATE_CONTRACT_ROOM,
    payload: res.data.data,
  });
};
export const deleteCotractRoom = res => dispatch => {
  dispatch({
    type: DELETE_CONTRACT_ROOM,
    payload: res.data.data,
  });
};

export const changeContractStatus = res => dispatch => {
  dispatch({
    type: CHANGE_CONTRACT_STATUS,
    payload: res.data.data,
  });
};

export const copyContract = res => dispatch => {
  dispatch({
    type: COPY_CONTRACT,
    payload: res.data.data,
  });
};
