import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useLocation } from 'react-router';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';

import { sidebarNavs, masterSidebarNavs, sidebarNavsOfficeUser } from '../menus';
import { authUserMemo } from 'redux/selector/companyHasOfficeSelector';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    paddingBottom: '8rem',
    transition: 'all 0.3s ease',
    // '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
    //   height: 'calc(80% - 16px)',
    // },
    '.Cmt-modernLayout &': {
      height: 'calc(80% - 7px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(80% - 9px)',
    },
    // '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
    //   height: 'calc(80% - 16px)',
    // },
    // overflow: 'visible!important',
    // '&:hover': {
    //   overflow: 'hidden!important',
    // },
    'MuiCollapse-root makeStyles-navCollapseItem-511 MuiCollapse-entered &': {
      overflow: 'visible!important',
    },
  },
}));

const SideBar = () => {
  const location = useLocation();
  const classes = useStyles();
  const authUserState = useSelector(authUserMemo);

  const isMaster = () => {
    if (location.pathname.includes('/master')) {
      return masterSidebarNavs;
    }
    if (authUserState === 30) {
      return sidebarNavsOfficeUser;
    } else {
      return sidebarNavs;
    }
  };
  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={isMaster()} />
    </PerfectScrollbar>
  );
};

export default SideBar;
