import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { addMasterCompanyService, updateMasterCompanyService } from 'services/company';
import {
  getMasterCountriesService,
  getMasterRegionsService,
  getMasterDivisionsService,
  getMasterCitiesService,
  getMasterDivisionCitiesService,
  getMasterSubCitiesService,
  getSelectedCompanyLocation,
  getSelectedCompanyRegions,
  getSelectedCompanyCountries,
  getSelectedCompanyCities,
  getSelectedCompanyDivisions,
  getSelectedCompanySubCities,
} from 'services/destinations';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@material-ui/core';
import { emailNotValid, requiredMessage } from '../../../../constants/ErrorMessages';
import AppSelectBox from '../../../Common/formElements/AppSelectBox';
import AppTextInput from '../../../Common/formElements/AppTextInput';
import GridContainer from '../../../GridContainer';
import { isValidEmail } from '../../../../utils/commonHelper';
import { PhoneFormatCustom } from '../../../Common/NumberFormat/NumberFormat';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';

const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  option: {
    fontSize: 15,
  },
  form: {
    '& .MuiFormHelperText-contained': {
      color: '#f44336',
    },
    '& .MuiFormHelperText-root': {
      color: '#f44336',
    },
    '& .MuiInputBase-root': {
      color: 'rgb(0 0 0 / 87%)',
    },
  },
}));

const isEnterprise = [
  { title: 'Enterprise', slug: true },
  { title: 'Company', slug: false },
];

function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    } else if (!value) {
      setPhoneNumber('');
    }
  }, [phoneNumber, value]);

  const onNumberChange = number => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return <NumberFormat {...other} onValueChange={onNumberChange} value={phoneNumber} format="(###) ###-####" />;
}

const AddEditCompany = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { currentMasterCompany } = useSelector(({ company }) => company);
  const { regions, countries, cities, subCities, divisions } = useSelector(({ destinations }) => destinations);

  const [isEnterpriseValue, setIsEnterpriseValue] = useState(true);

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    website: '',
    officialTitle: '',
    taxOffice: '',
    taxNo: '',
  });

  const [errorsData, setErrorsData] = useState({
    name: '',
    email: '',
    phone: '',
    website: '',
    officialTitle: '',
    taxOffice: '',
    taxNo: '',
  });

  const [locationData, setLocationData] = useState({
    region: null,
    country: null,
    city: null,
    division: null,
    subcity: null,
    district: null,
  });

  const dispatch = useDispatch();

  // Website Validation
  const websiteExpression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  const websiteRegex = new RegExp(websiteExpression);

  useEffect(() => {
    if (currentMasterCompany) {
      setUserData({
        name: currentMasterCompany.name,
        email: currentMasterCompany.email,
        phone: currentMasterCompany.phone,
        website: currentMasterCompany.website,
        officialTitle: currentMasterCompany.official_title,
        taxOffice: currentMasterCompany.tax_office,
        taxNo: currentMasterCompany.tax_no,
      });

      setIsEnterpriseValue(!!currentMasterCompany.is_enterprise);

      const adress = async () => {
        const location = {
          region: currentMasterCompany.region_id,
          country: currentMasterCompany.country_id,
          city: currentMasterCompany.city_id,
          division: currentMasterCompany.division_id ? currentMasterCompany.division_id : '',
          subcity: currentMasterCompany.subcity_id,
        };
        const { data: selectedLocation } = await getSelectedCompanyLocation(location);
        setLocationData({
          ...locationData,
          region: selectedLocation.data.regions.find(item => item.id === currentMasterCompany.region_id),
          country: selectedLocation.data.countries.find(item => item.id === currentMasterCompany.country_id),
          city: selectedLocation.data.cities.find(item => item.id === currentMasterCompany.city_id),
          division: selectedLocation.data.divisions
            ? selectedLocation.data.divisions.find(item => item.id == currentMasterCompany.division_id)
            : null,
          subcity: selectedLocation.data.subcities
            ? selectedLocation.data.subcities.find(item => item.id === currentMasterCompany.subcity_id)
            : null,
        });
      };

      adress();
    }
  }, [currentMasterCompany]);

  useEffect(() => {
    dispatch(getMasterRegionsService());
  }, []);

  useEffect(() => {
    if (locationData.region) {
      dispatch(getMasterCountriesService(locationData.region));
    }
  }, [locationData.region]);

  useEffect(() => {
    if (locationData.country && locationData.country.has_division !== 1) {
      dispatch(getMasterCitiesService(locationData.country));
    } else if (locationData.country && locationData.country.has_division == 1) {
      dispatch(getMasterDivisionsService(locationData.country));
    }
  }, [locationData.country]);

  useEffect(() => {
    if (locationData.division) {
      dispatch(getMasterDivisionCitiesService(locationData.division));
    }
  }, [locationData.division]);

  useEffect(() => {
    if (locationData.city) {
      dispatch(getMasterSubCitiesService(locationData.city));
    }
  }, [locationData.city]);

  const onSubmitClick = () => {
    if (!userData.email) {
      setErrorsData({ ...errorsData, emailError: requiredMessage });
    } else if (!isValidEmail(userData.email)) {
      setErrorsData({ ...errorsData, emailError: emailNotValid });
    }
    if (!userData.officialTitle) {
      setErrorsData({ ...errorsData, officialTitleError: requiredMessage });
    }
    if (!userData.taxOffice) {
      setErrorsData({ ...errorsData, taxOfficeError: requiredMessage });
    }
    if (!userData.taxNo) {
      setErrorsData({ ...errorsData, taxNoError: requiredMessage });
    }
    if (!userData.name) {
      setErrorsData({ ...errorsData, nameError: requiredMessage });
    }

    if (!userData.website || !userData.website.match(websiteRegex)) {
      setErrorsData({ ...errorsData, websiteError: 'Please enter valid website adress' });
    }
    if (!userData.phone || userData.phone.length < 18) {
      setErrorsData({ ...errorsData, phoneError: 'Please enter valid phone number' });
    }
    if (
      userData.name &&
      userData.email &&
      isValidEmail(userData.email) &&
      userData.officialTitle &&
      userData.taxOffice &&
      userData.taxNo &&
      userData.website &&
      userData.phone &&
      userData.phone.length >= 18 &&
      userData.website.match(websiteRegex)
    ) {
      const companyInstance = {
        name: userData.name,
        phone: userData.phone,
        email: userData.email,
        website: userData.website,
        region_id: locationData.region ? locationData.region.id : null,
        country_id: locationData.country ? locationData.country.id : null,
        city_id: locationData.city ? locationData.city.id : null,
        division_id: locationData.division ? locationData.division.id : null,
        subcity_id: locationData.subcity ? locationData.subcity.id : null,
        district: locationData.district,
        is_enterprise: isEnterpriseValue,
        tax_no: userData.taxNo,
        tax_office: userData.taxOffice,
        official_title: userData.officialTitle,
      };
      if (currentMasterCompany) {
        companyInstance.id = currentMasterCompany.id;

        dispatch(
          updateMasterCompanyService(companyInstance, () => {
            onCloseDialog();
          }),
        );
      } else {
        dispatch(
          addMasterCompanyService(companyInstance, () => {
            onCloseDialog();
          }),
        );
      }
    } else {
      MySwal.fire('Oops...', `Please fill the required fields !`, 'error');
    }
  };

  const handleChange = (e, name) => {
    setUserData({ ...userData, [name]: e.target.value });
    setErrorsData({ ...errorsData, [name]: '' });
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot} maxWidth="sm" fullWidth="on">
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentMasterCompany ? <IntlMessages id="edit.company.details" /> : <IntlMessages id="create.new.company" />}
      </DialogTitle>
      <DialogContent dividers>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            variant="outlined"
            label={<IntlMessages id="company.name" />}
            required
            value={userData.name}
            onChange={e => {
              handleChange(e, 'name');
            }}
            helperText={errorsData.name}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            variant="outlined"
            label={<IntlMessages id="email" />}
            autoComplete="new-password"
            value={userData.email}
            required
            onChange={e => {
              handleChange(e, 'email');
            }}
            helperText={errorsData.email}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <TextField
            label={<IntlMessages id="phone" />}
            fullWidth
            variant="outlined"
            size="small"
            required
            placeholder="+xx (xxx) xxx-xxxx"
            // placeholder={intl.formatMessage({ id: 'phone' })}
            className={classes.form}
            value={userData.phone ? userData.phone : ''}
            onChange={e => {
              handleChange(e, 'phone');
            }}
            helperText={errorsData.phone}
            name="tel"
            id="outlined-required"
            autoComplete="nope"
            InputProps={{
              inputComponent: PhoneFormatCustom,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            variant="outlined"
            label={<IntlMessages id="website" />}
            value={userData.website}
            required
            autoComplete="nope"
            onChange={e => {
              handleChange(e, 'website');
            }}
            helperText={errorsData.website}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            variant="outlined"
            label={<IntlMessages id="official.title" />}
            value={userData.officialTitle}
            required
            onChange={e => {
              handleChange(e, 'officialTitle');
            }}
            helperText={errorsData.officialTitle}
          />
        </Box>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              variant="outlined"
              required
              label={<IntlMessages id="tax.office" />}
              value={userData.taxOffice}
              onChange={e => {
                handleChange(e, 'taxOffice');
              }}
              helperText={errorsData.taxOffice}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              variant="outlined"
              required
              label={<IntlMessages id="tax.no" />}
              value={userData.taxNo}
              onChange={e => {
                handleChange(e, 'taxNo');
              }}
              helperText={errorsData.taxNo}
            />
          </Grid>
        </GridContainer>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppSelectBox
            fullWidth
            data={isEnterprise}
            label={<IntlMessages id="company/enterprise" />}
            valueKey="slug"
            variant="outlined"
            labelKey="title"
            value={isEnterpriseValue}
            onChange={e => setIsEnterpriseValue(e.target.value)}
          />
        </Box>
        {/* <CmtList
          Tax No
          Tax Office
          data={phone}
          renderRow={(item, index) => (
            <GridContainer style={{ marginBottom: 2 }} key={index}>
              <Grid item xs={12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Phone"
                  onChange={number => onPhoneNoAdd(number, index)}
                  helperText={phoneError}
                  InputProps={{
                    inputComponent: PhoneNumberInput,
                    inputProps: { value: item.phone },
                  }}
                />
              </Grid>
            </GridContainer>
          )}
        /> */}
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              autoHighlight
              classes={{
                option: classes.option,
              }}
              options={regions}
              getOptionLabel={option => option.name}
              value={locationData.region}
              renderInput={params => (
                <AppTextInput
                  {...params}
                  label={<IntlMessages id="choose.a.region" />}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
              onChange={(event, value) => {
                setLocationData({ ...locationData, region: value, country: null, city: null, division: null });
              }}
            />
          </Grid>
          {locationData.region && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                autoHighlight
                classes={{
                  option: classes.option,
                }}
                options={countries}
                value={locationData.country}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <AppTextInput
                    {...params}
                    label={<IntlMessages id="choose.a.country" />}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
                onChange={(event, value) => {
                  setLocationData({ ...locationData, country: value, city: null, division: null, subcity: null });
                }}
              />
            </Grid>
          )}
        </GridContainer>
        <GridContainer style={{ marginBottom: 12 }}>
          {locationData.country && divisions?.length > 0 && locationData.country.has_division == 1 && (
            <>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  autoHighlight
                  classes={{
                    option: classes.option,
                  }}
                  options={divisions}
                  getOptionLabel={option => option.name}
                  value={locationData.division}
                  renderInput={params => (
                    <AppTextInput
                      {...params}
                      label={<IntlMessages id="choose.a.state" />}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                  onChange={(event, value) => {
                    setLocationData({ ...locationData, city: null, division: value, subcity: null });
                  }}
                />
              </Grid>
              {locationData.division && cities.length > 0 && (
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    autoHighlight
                    classes={{
                      option: classes.option,
                    }}
                    options={locationData.division ? cities : []}
                    value={locationData.city}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <AppTextInput
                        {...params}
                        label={<IntlMessages id="choose.a.city" />}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                    onChange={(event, value) => {
                      setLocationData({ ...locationData, city: value, subcity: null });
                    }}
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12} sm={6}>
            {locationData.country && cities.length > 0 && !locationData.division && locationData.country.has_division !== 1 && (
              <Autocomplete
                autoHighlight
                classes={{
                  option: classes.option,
                }}
                options={cities}
                value={locationData.city}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <AppTextInput
                    {...params}
                    label={<IntlMessages id="choose.a.city" />}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
                onChange={(event, value) => {
                  setLocationData({ ...locationData, city: value, subcity: null });
                }}
              />
            )}
          </Grid>

          {locationData.city && subCities.length > 0 && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                autoHighlight
                classes={{
                  option: classes.option,
                }}
                options={subCities}
                getOptionLabel={option => option.name}
                value={locationData.subcity}
                renderInput={params => (
                  <AppTextInput {...params} label={<IntlMessages id="choose.a.sub.city" />} variant="outlined" />
                )}
                onChange={(event, value) => {
                  setLocationData({ ...locationData, subcity: value });
                }}
              />
            </Grid>
          )}
        </GridContainer>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            {locationData.region && (
              <TextField
                fullWidth
                label={<IntlMessages id="district" />}
                valueKey="slug"
                variant="outlined"
                labelKey="title"
                value={locationData.district}
                onChange={e => setLocationData({ ...locationData, district: e.target.value })}
              />
            )}
          </Grid>
        </GridContainer>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>
            <IntlMessages id="cancel" />
          </Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={onSubmitClick}>
              <IntlMessages id="save" />
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditCompany;

AddEditCompany.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
