import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import GridContainer from '@jumbo/components/GridContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { Box, Button, Divider, makeStyles } from '@material-ui/core';
import { toInteger } from 'lodash';
import RoomSystem from './RoomSystem';
import CustomerTab from './CustomerTab';
import HotelDetais from './HotelDetails';
import CmtCardFooter from '@coremat/CmtCard/CmtCardFooter';
import { useDispatch, useSelector } from 'react-redux';
import { addReservationService } from 'services/reservation';
import ContractSelection from './ContractSelection';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CardHeader from '@mui/material/CardHeader';
import { useIntl } from 'react-intl';
import message from '../MessageStyle/messageStyle';
import calculate from '../NewReservationCalculateStyle/calculate';
import multiple from 'redux/reducers/multiple';

const MySwal = withReactContent(Swal);
const useStyles = makeStyles(theme => ({
  greenButton: {
    backgroundColor: '#4caf50',
    color: '#ffffff',
    // you can add your specific CSS here.
  },
}));

const AddReservation = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [whatsapp, setWhatsapp] = React.useState(false);

  const dispatch = useDispatch();
  const intl = useIntl();
  const [roomCount, setRoomCount] = useState(1);
  const creator_fullname = useSelector(state => state.auth.authUser.fullname);
  const contracts_selector = useSelector(state => {
    let newContracts = [];
    state.onSaleContract.onSaleContract.map(contract => {
      newContracts.push(
        Object.values(state.contract.contracts).find(contractDetails => contractDetails.id === contract.contract_id),
      );
    });
    return newContracts;
  });

  const seasons = useSelector(state => state.seasons.seasons);
  const markets = useSelector(state => state.markets.markets);
  const today = new Date();
  const [roomTab, setRoomTab] = useState(0);
  const reservation_initial_state = {
    hotel_id: '',
    operator_id: '',
    creator_name: creator_fullname,
    selling_date: `${today.getFullYear()}-${parseInt(today.getMonth() + 1)}-${
      today.getDate() < 10 ? '0' + today.getDate() : today.getDate()
    }`,
    rooms: [],
    contract_id: '',
  };
  const [availableContracts, setAvailableContracts] = useState([]);
  const [selectTypeDialog, setSelectTypeDialog] = useState(false);

  const [reservationDetail, setReservationDetail] = useState(reservation_initial_state);

  const handleClickOpen = () => {
    setSelectTypeDialog(true);
  };

  const [selectedContract, setSelectedContract] = useState(null);
  const onSubmit = reservation => {
    //Get message input value from Reservation Message Section

    const message = textMessage.current.value;

    //Reservation message length validation here

    if (!reservation.contract_id) {
      reservation.contract_id = 0;
    }

    reservation.message = message;

    //Send all reservation data to backend (with redux)

    dispatch(addReservationService(reservation));
    setReservationDetail(reservation_initial_state);

    //set null reservation value

    textMessage.current.value = '';

    MySwal.fire({
      icon: 'success',
      title: 'Success',
      text: `Reservation successfully created `,
      timerProgressBar: true,
    });
  };
  const handleClose = type => {
    var adult_num = 0;
    var child_num = 0;
    var infant_num = 0;
    Object.keys(reservationDetail.rooms).forEach(key => {
      adult_num += Number(reservationDetail.rooms[key].adult);
      child_num += Number(reservationDetail.rooms[key].child);
      infant_num += Number(reservationDetail.rooms[key].infant);
    });
    var reservation = {
      ...reservationDetail,
      type: type,
      lead_name: reservationDetail.rooms['1'].customers[0].name,
      adult_num: adult_num,
      child_num: child_num,
      infant_num: infant_num,
    };
    onSubmit(reservation);
    setSelectTypeDialog(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;

    // Set values
    if (name === 'contract_id') {
      setReservationDetail(prev => ({
        ...prev,
        [name]: Number(value.split('-')[0]),
      }));
    } else {
      setReservationDetail(prev => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (
      reservationDetail.operator_id &&
      reservationDetail.hotel_id &&
      reservationDetail.start_date &&
      reservationDetail.end_date
    ) {
      let available_contracts = contracts_selector.filter(
        contract =>
          reservationDetail.hotel_id === contract?.hotel_id &&
          contract.operators.includes(reservationDetail.operator_id) &&
          new Date(reservationDetail.start_date) <= new Date(contract.end_date) &&
          new Date(reservationDetail.start_date) >= new Date(contract.start_date),
      );

      setAvailableContracts(available_contracts);
    }
  }, [reservationDetail.operator_id, reservationDetail.hotel_id, reservationDetail.start_date, reservationDetail.end_date]);

  useEffect(() => {
    setRoomTab(Object.keys(reservationDetail.rooms).length);
  }, [reservationDetail.rooms]);

  const handleSubmitControl = () => {
    let unGenderedPersons = [];

    Object.values(reservationDetail.rooms).forEach(room => {
      unGenderedPersons = [...room.customers.filter(person => person.gender === '')];
    });

    if (unGenderedPersons.length == 0) {
      if (Object.keys(reservationDetail.rooms).length == 1) {
        handleClose('mono');
      } else {
        handleClickOpen();
      }
    } else {
      MySwal.fire({
        icon: 'error',
        title: 'Pax not defined',
        text: 'Undefined pax exists please define pax',
        timerProgressBar: true,
      });
    }
  };

  const handleAddRoomReservation = (no, room) => {
    const maxCustomer = toInteger(room.adult) + toInteger(room.child) + toInteger(room.infant);

    setReservationDetail(prev => ({
      ...prev,
    }));
    const customersArray = [];
    for (let i = 0; i < maxCustomer; i++) {
      customersArray.push({
        gender: '',
        name: '',
        surname: '',
        birthday: '',
        age: '',
      });
    }
    room.customers = customersArray;
    setReservationDetail(prev => ({
      ...prev,
      rooms: { ...prev.rooms, [no]: room },
    }));
  };

  /********************ROOM DETAILS COMPONENT TURNS HERE **********************/
  const roomList = () => {
    const rooms = [];

    for (let i = 0; i < roomCount; i++) {
      rooms.push(
        <RoomSystem
          no={i + 1}
          handleRoomAdd={handleAddRoomReservation}
          setRoomCount={setRoomCount}
          setReservationDetail={setReservationDetail}
          reservationDetail={reservationDetail}
          setRoomTab={setRoomTab}
          rooms={reservationDetail.rooms}
          selectedContract={selectedContract}
        />,
      );
    }

    return rooms;
  };
  {
    /***************************************************************************/
  }

  const CmtCardStyle = {
    cardheader: {
      paddingLeft: '0px',
    },
  };

  const buttonStyle = {
    saveBtn: {
      marginRight: '10px',
      width: '104px',
    },
  };

  const dividerStyle = {
    marginBottom: {
      margin: '0px 0px 24px 0',
    },
    margin: {
      margin: '34px 0px 24px 0',
    },
  };

  /**************************** MESSAGE AREA JS SECTION **************************/

  let toggle = false;
  const textMessage = useRef();
  const clear = () => {
    textMessage.current.value = '';
  };
  let multipleRoom = useRef();
  /*******************************************************************************/

  return (
    <GridContainer>
      {/********************************WHATSUPP COMPONENT***********************/}
      {/* <NotificationDialog
        func={"I N F O"}
        open={whatsapp}
        setOpen={setWhatsapp}
        messageText={"Contact with us for any issue"}
      /> */}
      {/************************************************************************/}

      <Grid item xs={12}>
        <CmtCard>
          <CardHeader
            style={{ marginLeft: 1 + 'rem' }}
            title={intl.formatMessage({ id: 'new.reservation' })}
            sx={{ color: '#33658a' }}
          />

          <Divider />

          <CmtCardContent>
            <GridContainer>
              {/***** FIRST COMPONENT (HOTEL) ******/}
              <Grid item xs={12}>
                <HotelDetais handleChange={handleChange} reservationDetail={reservationDetail} />
              </Grid>
              {/****************************/}

              {/********SECOND COMPONENT (CONTRACT)*******************/}
              <Grid style={{ marginTop: '4rem' }} item xs={12}>
                {reservationDetail.operator_id &&
                reservationDetail.hotel_id &&
                reservationDetail.selling_date &&
                reservationDetail.start_date &&
                reservationDetail.end_date ? (
                  <>
                    {' '}
                    <CardHeader
                      title={intl.formatMessage({ id: 'contract.selection' })}
                      sx={{ color: '#33658a', marginRight: '4rem' }}
                    />
                    <Divider style={dividerStyle.marginBottom} />
                    <ContractSelection
                      handleChange={handleChange}
                      reservationDetail={reservationDetail}
                      contracts={availableContracts}
                      setReservationDetail={setReservationDetail}
                      setSelectedContract={setSelectedContract}
                    />{' '}
                  </>
                ) : (
                  ''
                )}
              </Grid>

              {/*********************************************************/}

              {/**************ROOM DETAILS COMPONENT*********************/}
              <Grid style={{ marginTop: '4rem' }} item xs={12}>
                {/*  {reservationDetail.operator_id &&
                reservationDetail.hotel_id &&
                reservationDetail.selling_date &&
                reservationDetail.start_date &&
                reservationDetail.end_date &&
                reservationDetail.board_id ? ( */}
                <>
                  <CardHeader
                    title={intl.formatMessage({
                      id: 'room.details',
                    })}
                    sx={{ color: '#33658a', marginRight: '4rem' }}
                  />

                  <Divider style={dividerStyle.marginBottom} />
                  {roomList()}
                  <Divider style={dividerStyle.margin} />
                </>
                {/*  ) : (
                  ''
                )} */}
              </Grid>

              <Grid container justifyContent="flex-end" alignItems="center">
                {selectedContract !== null && reservationDetail.board_id !== undefined ? (
                  <>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}>
                      <label>{intl.formatMessage({ id: 'number.for.multiple.room' })}</label>
                      <Box
                        style={{
                          display: 'flex',
                          justifiyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '1rem',
                          flexDirection: 'row',
                        }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            console.log(multipleRoom.current.value);
                            setRoomCount(prevCount =>
                              Number(multipleRoom.current.value) > 1
                                ? prevCount + Number(multipleRoom.current.value)
                                : prevCount + 1,
                            );
                          }}>
                          <IntlMessages id="add.room" />
                        </Button>
                        <input
                          ref={multipleRoom}
                          type="number"
                          style={{
                            border: 'none',
                            boxShadow: '0 0 3px black',
                            outline: 'none',
                            marginLeft: '1rem',
                            borderRadius: '5px',
                            width: '3rem',
                            height: '1.8rem',
                            textAlign: 'center',
                          }}
                          min="1"
                          max="30"
                        />
                      </Box>
                    </Box>
                  </>
                ) : (
                  ''
                )}
              </Grid>
              {/***********************************************************/}

              {/*********************CUSTOMER TABLE ***********************/}
              <Grid item xs={12}>
                <CustomerTab
                  roomLength={roomTab}
                  reservationDetail={reservationDetail}
                  setCustomer={setReservationDetail}
                  selectedContract={selectedContract}
                />
              </Grid>
              {/***********************************************************/}

              {/****************** RESERVATION MESSAGE ********************/}
              {reservationDetail.operator_id &&
              reservationDetail.hotel_id &&
              reservationDetail.selling_date &&
              reservationDetail.start_date &&
              reservationDetail.end_date &&
              Object.values(reservationDetail.rooms).length > 0 &&
              reservationDetail.board_id ? (
                <Grid item xs={12}>
                  <Box style={message.layout}>
                    <CardHeader
                      title={intl.formatMessage({ id: 'new.reservation.message' })}
                      sx={{ color: '#33658a', marginRight: '4rem', marginTop: '2rem' }}
                    />
                    <Box style={message.head}>
                      <input type="text" ref={textMessage} maxLength="300" style={message.text} />
                      <Button onClick={clear} style={message.clear}>
                        Clear
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              ) : (
                ''
              )}
              {/***********************************************************/}

              {/*********************RESERVATİON CALCULATE SECTION***********/}
              {reservationDetail.operator_id &&
              reservationDetail.hotel_id &&
              reservationDetail.selling_date &&
              reservationDetail.start_date &&
              reservationDetail.end_date &&
              reservationDetail.board_id ? (
                <Grid item xs={12}>
                  <Box style={calculate.layout}>
                    <CardHeader
                      title={intl.formatMessage({ id: 'new.reservation.calculate.result' })}
                      sx={{ color: '#33658a', marginRight: '4rem' }}
                    />
                    <Divider style={{ marginTop: '1rem' }} />
                    <Box style={calculate.content}>
                      <Box style={calculate.contentSection1}>
                        <Box style={calculate.cell}>
                          <label style={calculate.cellLabel} htmlFor="hotelName">
                            Hotel Name
                          </label>
                          <input readOnly style={calculate.cellInput} type="text" />
                        </Box>
                        <Box style={calculate.cell}>
                          <label style={calculate.cellLabel} htmlFor="region">
                            Region
                          </label>
                          <input readOnly style={calculate.cellInput} type="text" />
                        </Box>
                        <Box style={calculate.cell}>
                          <label style={calculate.cellLabel} htmlFor="country">
                            Country
                          </label>
                          <input readOnly style={calculate.cellInput} type="text" />
                        </Box>
                      </Box>
                      <Box style={calculate.contentSection2}>
                        <Box style={calculate.cell}>
                          <label style={calculate.cellLabel} htmlFor="room">
                            Room
                          </label>
                          <input readOnly style={calculate.cellInput} type="text" />
                        </Box>
                        <Box style={calculate.cell}>
                          <label style={calculate.cellLabel} htmlFor="totalPrice">
                            Total Price
                          </label>
                          <input readOnly style={calculate.cellInput} type="text" />
                        </Box>
                        <Box style={calculate.cell}>
                          <label style={calculate.cellLabel} htmlFor="netPrice">
                            Net Price
                          </label>
                          <input readOnly style={calculate.cellInput} type="text" />
                        </Box>
                        <Box style={calculate.cell}>
                          <label style={calculate.cellLabel} htmlFor="discount">
                            Discount
                          </label>
                          <input readOnly style={calculate.cellInput} type="text" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ) : (
                ''
              )}
              {/********************************************************************/}
            </GridContainer>
          </CmtCardContent>
          <Divider />

          {/***********************FOOTER********************************/}
          <CmtCardFooter>
            <Button
              style={buttonStyle.saveBtn}
              variant="contained"
              className={classes.greenButton}
              disabled={reservationDetail.rooms.length === 0 ? true : false}
              onClick={() => handleSubmitControl()}>
              <IntlMessages id="save" />
            </Button>
          </CmtCardFooter>
          {/*************************************************************/}
        </CmtCard>
      </Grid>
    </GridContainer>
  );
};

export default AddReservation;
