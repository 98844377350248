import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { TableBody, TableCell, TableRow, TablePagination, TableHead } from '@material-ui/core';
import AddUserDialog from './AdminUserDialog/AddUserDialog';
import UpdateUserDialog from './AdminUserDialog/UpdateUserDialog';
import CmtAvatarGroup from '../../../@coremat/CmtAvatarGroup';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import CmtObjectSummary from '../../../@coremat/CmtObjectSummary';
import CmtList from '../../../@coremat/CmtList';
import { userMemo, officeMemo, loginUser } from 'redux/selector/companyHasOfficeSelector';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Swal from 'sweetalert2';
import { deleteAdminUserService } from 'services/user';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { getAdminCompaniesService } from '../../../services/company';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';
import axios from 'services/axios';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const CustomTablePagination = props => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };
  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

CustomTablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function Users() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const officesObject = useSelector(state => state.companyHasOffice.entities.offices);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const userData = useSelector(userMemo);
  const loginUserData = useSelector(loginUser);
  const permissionStateSelector = useSelector(state => state.companyHasOffice.entities.get_permission);

  const authority_name = [{ 10: 'Enterprise', 11: 'Company', 20: 'Company', 30: 'Office' }];

  const moreItemsTooltip = data => <CmtList data={data} renderRow={(item, index) => <Box key={index}>{item.name}</Box>} />;
  const newData = {};

  const hasPermission = element => {
    const permissionControl = Object.values(permissionStateSelector)
      .filter(permission => permission.user_id == element.id)
      .reduce((acc, curr) => {
        curr.get == 1 || curr.post == 1 || curr.put == 1 || curr.delete == 1 ? acc.push(true) : acc.push(false);
        return acc;
      }, []);

    return permissionControl.every(element => element == false);
  };

  useEffect(() => {
    dispatch(getAdminCompaniesService());
  }, [dispatch]);

  userData.map(element => {
    element.pivot_office.map(officeID => {
      if (newData[element.id]) {
        newData[element.id].push({
          name: officesObject[officeID].name,
          email: officesObject[officeID].email,
        });
      } else {
        newData[element.id] = [
          {
            name: officesObject[officeID].name,
            email: officesObject[officeID].email,
          },
        ];
      }
    });
  });

  const deleteFunction = deleteUser => {
    Swal.fire({
      title: intl.formatMessage({ id: 'are.you.sure?' }),
      text: intl.formatMessage(
        {
          id: 'you.want.to.delete.user',
        },
        { name: deleteUser.fullname },
      ) /* 'You want to delete ' + deleteUser.fullname + ' user ?', */,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({ id: 'cancel' }),
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: intl.formatMessage({ id: 'are.you.sure?' }),
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteAdminUserService(deleteUser));
      }
    });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const resetPassw = userEmail => {
    axios.post('reset-password', userEmail).then(response => {
      MySwal.fire('Report', `<h3 style="color:rgb(34 65 88); letter-spacing:3px">${response.data.message}</h3>`, 'warning');
    });
  };

  return (
    <div>
      {openAddDialog ? <AddUserDialog open={openAddDialog} setOpen={setOpenAddDialog} /> : <div />}
      {openUpdateDialog ? (
        <UpdateUserDialog
          open={openUpdateDialog}
          setOpen={setOpenUpdateDialog}
          userData={updateData}
          setUserData={setUpdateData}
          officeData={officesObject}
        />
      ) : (
        <div />
      )}

      <MaterialTable
        actions={[
          {
            icon: () => (
              <Button style={{ backgroundColor: 'transparent' }} endIcon={<GroupAddOutlinedIcon />}>
                <IntlMessages id="add.user" />
                {/* <IconButton>
                <AddBusinessOutlinedIcon />
              </IconButton> */}
              </Button>
            ),
            title: 'add',
            tooltip: 'Add User',
            hidden: loginUserData.authority_level == 20,
            isFreeAction: true,
            onClick: (event, rowData) => {
              setOpenAddDialog(true);
            },
          },
        ]}
        components={{
          Header: props => (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <IntlMessages id="name" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="email" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="authority.level" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="office" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="permissions" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="actions" />
                  </TableCell>
                </TableRow>
              </TableHead>
            </>
          ),
          Body: props => (
            <>
              <TableBody>
                {(rowsPerPage > 0 ? userData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : userData).map(
                  element => {
                    return (
                      <TableRow>
                        <TableCell>{element.fullname}</TableCell>
                        <TableCell>{element.email}</TableCell>
                        <TableCell>
                          {authority_name[0][element.authority_level] ? authority_name[0][element.authority_level] : '---'}
                        </TableCell>
                        <TableCell>
                          <CmtAvatarGroup
                            items={newData[element.id]}
                            srcKey=""
                            spacing={6}
                            max={3}
                            avatarSize={24}
                            titleKey="name"
                            moreVisibleOn="click"
                            tooltipProps={{ classes: { tooltip: classes.tooltip } }}
                            renderItemSummary={(item, index) => (
                              <Box p={1}>
                                <CmtObjectSummary
                                  key={index}
                                  avatar={<HomeWorkOutlinedIcon size={30} src="" />}
                                  title={item.name}
                                  subTitle={item.email}
                                  align={'horizontal'}
                                />
                              </Box>
                            )}
                            renderMore={moreItemsTooltip}
                          />
                        </TableCell>
                        <TableCell>
                          {hasPermission(element) && (
                            <Tooltip title={intl.formatMessage({ id: 'user.has.no.permission' })}>
                              <IconButton>
                                <WarningAmberIcon style={{ color: '#ff9800' }} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => resetPassw(element.email)}>
                            {' '}
                            <IntlMessages id="reset.password" />
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={(event, rowData) => {
                              setUpdateData(element);
                              setOpenUpdateDialog(true);
                            }}>
                            <CreateOutlinedIcon />
                          </Button>
                          {element.get_contract.length == 0 &&
                            loginUserData.authority_level != 20 &&
                            loginUserData.id != element.id && (
                              <Button
                                onClick={(event, rowData) => {
                                  deleteFunction(element);
                                }}>
                                <DeleteOutlineOutlinedIcon />
                              </Button>
                            )}
                        </TableCell>
                      </TableRow>
                    );
                  },
                )}
              </TableBody>
            </>
          ),
          Pagination: props => (
            <>
              <TablePagination
                style={{ display: 'flex', justifyContent: 'right' }}
                rowsPerPageOptions={[8, 15, 20, { label: 'All', value: -1 }]}
                colSpan={3}
                count={userData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={CustomTablePagination}
              />
            </>
          ),
        }}
        options={{
          showTitle: false,
          showSelectAllCheckbox: false,
          showTextRowsSelected: false,
          search: false,
          filtering: false,
          paging: true,
          addRowPosition: 'first',
        }}
      />
    </div>
  );
}
