import { addService, deleteService, getServices, updateService } from 'redux/actions/services';
import axios from './axios';
import { fetchStart, fetchError, fetchSuccess } from '../redux/actions/Common';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/**PROXOLAB LOGGER **/
import sendLogService from './proxolabLogger/Logger.js';
/**PROXOLAB LOGGER **/

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: '',
    text: text,
  });
};
export const getServiceService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('service')
    .then(res => {
      dispatch(getServices(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addServiceService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('service', data)
    .then(res => {
      dispatch(addService(res));
      dispatch(fetchSuccess());
      sendLogService.sendNewServiceLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      sweetAlerts('error', 'Something went wrong!');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateServiceService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());

  return axios
    .put('service', data)
    .then(res => {
      dispatch(updateService(res));
      dispatch(fetchSuccess());
      sendLogService.sendNewServiceLog(res, data);
      return res.data.code;
    })
    .catch(e => {
      sweetAlerts('error', 'Something went wrong!');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const deleteServiceService = id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`service/${id}`)
    .then(res => {
      dispatch(deleteService(res));
      dispatch(fetchSuccess());
      sendLogService.sendNewServiceLog(res, id);
      return res.data.code;
    })
    .catch(e => {
      sweetAlerts('error', 'Something went wrong!');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
