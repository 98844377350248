import React, { useState } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Box, Collapse, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CmtCardContent from '../CmtCard/CmtCardContent';
import CmtCardActions from '../CmtCard/CmtCardActions';
import CmtSearch from '../../@coremat/CmtSearch';
import Grid from '@material-ui/core/Grid';
import GridContainer from '../../@jumbo/components/GridContainer';
const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    zIndex: 1,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  searchAction: {
    position: 'relative',
    width: 15,
    height: 38,
  },
  searchActionBar: {
    position: 'absolute',
    right: 0,
    top: 2,
    zIndex: 1,
  },
}));

const CmtCardExpendableContent = ({ actionsComponent, className, actionsClasses, contentClass, children, ...rest }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box className={clsx(classes.root, className)} {...rest}>
      <CmtCardActions classes={actionsClasses} disableSpacing>
        <GridContainer justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={11}>
            {actionsComponent}
          </Grid>
          <Grid item xs={12} sm={1}>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more">
              <ExpandMoreIcon />
            </IconButton>
          </Grid>
        </GridContainer>
      </CmtCardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CmtCardContent className={contentClass}>{children}</CmtCardContent>
      </Collapse>
    </Box>
  );
};

CmtCardExpendableContent.propTypes = {
  actionsComponent: PropTypes.node,
  actionsClasses: PropTypes.object,
  contentClass: PropTypes.string,
};

CmtCardExpendableContent.defaultProps = {
  actionsClasses: {},
  contentClass: '',
};

export default CmtCardExpendableContent;
