import React, { useEffect, useState } from 'react';
import { getOperatorService } from 'services/operator';
import { getMarketService } from 'services/market';
import { getPaymentMethodsService } from 'services/paymentMethods';
import { getExtraService } from 'services/extras';
import { getHotelService } from 'services/hotels';
import { getContractService } from 'services/contract';
import { useDispatch, useSelector } from 'react-redux';
import Loading from './Loading';
import Definitons from './Definitions';
import { getRoomService } from 'services/rooms';
import { getAllCountriesService, getCountriesService } from 'services/destinations';
import { getBoardsService } from 'services/boards';
import { getAdminCompaniesService } from 'services/company';
import { getStarRatesService } from 'services/starRate';
import { getProductTypesService } from 'services/productTypes';
import { getRegionsService } from 'services/destinations';
import { getServiceService } from 'services/services';

const DefinitionsLoading = () => {
  const dispatch = useDispatch();
  const [isFinished, setIsFinished] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);
  const { paymentMethods } = useSelector(({ paymentMethods }) => paymentMethods);
  useEffect(() => {
    const firstLoader = async () => {
      if (paymentMethods.length === 0) {
        await Promise.all([
          dispatch(getContractService(authUser.authority_level, localStorage.getItem('selectedCompany'))),
          dispatch(getPaymentMethodsService()),
          dispatch(getBoardsService()),
          dispatch(getAdminCompaniesService()),
          dispatch(getHotelService()),
          dispatch(getProductTypesService()),
          dispatch(getRoomService()),
          dispatch(getMarketService()),
          dispatch(getOperatorService()),
          dispatch(getCountriesService(['', 0])),
          dispatch(getExtraService()),
          dispatch(getStarRatesService()),
          dispatch(getRegionsService()),
          dispatch(getProductTypesService()),
          dispatch(getServiceService()),
          dispatch(getRegionsService()),
          dispatch(getAllCountriesService()),
        ]);
      }
    };
    firstLoader();
    setIsFinished(true);
  }, []);

  return <React.Fragment>{isFinished && <Definitons />}</React.Fragment>;
};

export default DefinitionsLoading;
