import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import Routes from './routes';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@jumbo/components/Error/ErrorFallback';

// export const { store, persistor } = configureStore();
export const store = configureStore();

const App = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
    <Provider store={store}>
      {/* <PersistGate loading={<div>Loading</div>} persistor={persistor}> */}
      <ConnectedRouter history={history}>
        <AppContextProvider>
          <AppWrapper>
            <Switch>
              <Routes />
            </Switch>
          </AppWrapper>
        </AppContextProvider>
      </ConnectedRouter>
      {/* </PersistGate> */}
    </Provider>
  </ErrorBoundary>
);

export default App;
