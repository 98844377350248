import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button, FormControl, Select, Grid, MenuItem } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import { addAllotmentUpdateService, getAllotmentUpdateService } from 'services/allotmentUpdate';
import AllotmentUpdateTable from './AllotmentUpdateTable';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Autocomplete from '@mui/material/Autocomplete';
import { getHotelService } from 'services/hotels';
import { getContractService } from 'services/contract';
import InputLabel from '@material-ui/core/InputLabel';
import { getOperatorService } from 'services/operator';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getRoomService } from 'services/rooms';
import moment from 'moment';
// import SelectAll from '../Contract/SelectAll';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';
import { updateRatePlanAllotmentUpdateService } from 'services/ratePlan';
import GridContainer from '../GridContainer';
import { MultiSelect } from 'react-multi-select-component';
import { multiDataSetter } from '@jumbo/utils/commonHelper';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  gridStyle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  cmtButton: {
    marginRight: theme.spacing(3),
  },
  container: {
    padding: theme.spacing(5),
  },
}));
const MySwal = withReactContent(Swal);

export default function AllotmentUpdate() {
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(async () => {
    await dispatch(getContractService());
    await dispatch(getRoomService());
    await dispatch(getHotelService());
    await dispatch(getAllotmentUpdateService());
    await dispatch(getOperatorService());
  }, [dispatch]);

  const { hotels } = useSelector(({ hotels }) => hotels);
  const { contracts } = useSelector(({ contract }) => contract);
  const { contract_room } = useSelector(({ contract }) => contract);
  const { operators } = useSelector(({ operators }) => operators);

  const {
    rooms: { roomType },
  } = useSelector(({ rooms }) => rooms);

  const [tableFlag, setTableFlag] = useState(false);
  const classes = useStyles();

  //For keyboard date picker
  const date = new Date();
  const today = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  let isAuthAdd = permissionsByAuthUser.some(permission => permission.route_id == 3 && permission.post === 1);

  //For alert messages function
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: '',
      text: text,
    });
  };
  //For save button
  const theme = createTheme({
    palette: {
      primary: green,
    },
  });

  const [values, setValues] = useState({
    contractOnSale: [],
    sameContracts: [],
    hotel: [],
    roomOptions: [],
    operatorOptions: [],
    flag: false,
    navbarHotel: [],
  });
  const [selected, setSelected] = useState({
    hotelId: '',
    contractId: '',
    startDate: '',
    endDate: '',
    allocationType: '',
    seasonCode: '',
    rooms: [],
    operators: [],
  });

  //Navbar hotels => Hotels which belongs to onsale contracts
  useEffect(() => {
    let onsaleContracts = Object.values(contracts).filter(item => item.contract_statuses_id == 4);
    let onSaleHotels = [];
    Object.values(hotels)
      .filter(hotel => onsaleContracts.some(osc => osc.hotel_id === hotel.id))
      .map(elem => onSaleHotels.push({ id: elem.id, label: elem.name }));
    setValues({ ...values, navbarHotel: onSaleHotels });
  }, [hotels]);
  //Find contracts according to hotel, start and end date change
  useEffect(() => {
    let contractFilter = {};
    if (selected.hotelId && selected.startDate && selected.endDate) {
      contractFilter = Object.values(contracts).filter(
        item =>
          item.hotel_id == selected.hotelId &&
          moment(selected.startDate).isSameOrAfter(moment(item.start_date).format('MM/DD/YYYY')) &&
          moment(selected.endDate).isSameOrBefore(moment(item.end_date).format('MM/DD/YYYY')),
      );
      setValues({ ...values, sameContracts: contractFilter, flag: true });
      if (contractFilter.length < 1) {
        sweetAlerts('warning', `Contract not defined between your dates !`);
      }
    }
  }, [selected.hotelId, selected.startDate, selected.endDate]);

  //Find room and operators according to contract
  useEffect(() => {
    let roomOption = [];
    let operatorOption = [];
    let contractRoomIds = [];
    let operatorIds = [];
    let selectedContractObj = values.sameContracts.find(elem => elem.id == selected.contractId);

    if (selectedContractObj) {
      //NORMALIZE CONTRACT ROOM => ROOM OPTINOS
      contractRoomIds = selectedContractObj.contract_room;
      contractRoomIds.map(item => {
        const newRoom = roomType[contract_room[item].room_id];
        // const referanceRoom = contract_room[item].allotment_reference_id;
        // const roomCode =
        //   referanceRoom == item || referanceRoom == null
        //     ? newRoom.code
        //     : newRoom.code + ' (' + roomType[contract_room[referanceRoom]?.room_id]?.code + ')';
        roomOption = [
          ...roomOption,
          {
            id: item,
            label: newRoom.code,
            code: newRoom.code,
          },
        ];
      });
      //NORMALIZE CONTRACT OPERATOR => OPERATOR OPTIONS
      operatorIds = selectedContractObj.operators;
      operatorIds.map(id => {
        operatorOption = [...operatorOption, { id: operators[id].id, label: operators[id].code }];
      });
    }
    setValues({
      ...values,
      roomOptions: roomOption,
      operatorOptions: operatorOption,
    });
  }, [selected.contractId, contract_room, operators]);

  const allocationControl = event => {
    if (event.target.value < 0) {
      sweetAlerts('warning', `Allocation can't be smaller than 0 !`);
    } else {
      setSelected({ ...selected, allocation: { ...selected.allocation, [event.target.name]: event.target.value } });
    }
  };

  const saveAll = () => {
    let selectedOpId = [];
    let selectedRoomCode = [];
    let selectedRoomIds = [];
    let control = true;
    selected.operators.map(elem => {
      selectedOpId.push(elem.id);
    });
    selected.rooms.map(elem => {
      selectedRoomCode.push(elem.code);
      selectedRoomIds.push(elem.id);
      if (control == true && selected.allocation['allocation' + elem.id] == '') {
        control = false;
      }
      //selectedRoomCode.push({ code: elem.code, id: elem.id });
    });

    //Data which is send database
    let allotmentUpdate = {
      allocation: selected.allocation,
      actionType: selected.allocationType,
      contract_id: selected.contractId,
      hotelId: selected.hotelId,
      startDate: selected.startDate,
      endDate: selected.endDate,
      roomType: selectedRoomCode,
      extraNet: selectedOpId,
      selectedRoomIds: selectedRoomIds,
    };
    //Control empty values
    if (
      selected.allocation !== '' &&
      selected.allocationType !== '' &&
      selected.contractId !== '' &&
      selected.hotelId !== '' &&
      selected.startDate !== '' &&
      selected.startDate != 'Invalid date' &&
      selected.endDate !== '' &&
      selected.endDate != 'Invalid date' &&
      selected.rooms.length !== 0 &&
      selected.operators.length !== 0 &&
      control == true
    ) {
      if (moment(selected.startDate).isBefore(selected.endDate)) {
        dispatch(addAllotmentUpdateService(allotmentUpdate));
        let errorMessagge = '';
        dispatch(updateRatePlanAllotmentUpdateService(allotmentUpdate)).then(res => {
          Object.values(res).forEach(rate_plan => {
            if (rate_plan.hasOwnProperty('mesaj')) {
              if (errorMessagge.search(rate_plan.mesaj) < 0) {
                errorMessagge = errorMessagge.concat(rate_plan.mesaj + '\r\n');
              }
            }
          });
          if (errorMessagge === '') {
            sweetAlerts(
              'success',
              intl.formatMessage({ id: 'allotment.update' }) + intl.formatMessage({ id: 'updated.successfully' }),
            );
          } else {
            sweetAlerts('error', errorMessagge);
          }
        });
      } else {
        sweetAlerts('warning', intl.formatMessage({ id: 'dates.are.invalid' }));
      }

      //Reset the old values
      setSelected({
        hotelId: selected.hotelId,
        contractId: '',
        allocation: '',
        seasonCode: '',
        startDate: null,
        endDate: null,
        rooms: [],
        operators: [],
      });
      setValues({ ...values, sameContracts: [] });
    } else {
      sweetAlerts('warning', intl.formatMessage({ id: 'please.fill.in.all.fields' }));
    }
  };

  return (
    <div>
      <Grid container spacing={3} className={classes.container}>
        <Typography variant="h3" gutterBottom>
          <IntlMessages id="allotment.update" />
        </Typography>
        <Grid item xs={12}>
          <Autocomplete
            freeSolo
            id="selectedHotel"
            values={selected.hotelId}
            options={values.navbarHotel}
            getOptionLabel={option => option.label ?? option}
            renderInput={params => <TextField {...params} label={<IntlMessages id="hotels" />} />}
            onChange={(event, newValue) => {
              setSelected({ ...selected, hotelId: newValue.id });
              setTableFlag({ tableFlag: true });
            }}
          />
        </Grid>

        <Grid className={classes.gridStyle} item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              name="startDate"
              format="dd/MM/yyyy"
              autoOk="true"
              minDate={today}
              value={moment(selected.startDate).format('YYYY-MM-DD')}
              onChange={date => setSelected({ ...selected, startDate: moment(date).format('YYYY-MM-DD') })}
              label={<IntlMessages id="start.date" />}
              animateYearScrolling
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid className={classes.gridStyle} item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              name="endDate"
              format="dd/MM/yyyy"
              autoOk="true"
              minDate={selected.startDate ? selected.startDate : today}
              value={moment(selected.endDate).format('YYYY-MM-DD')}
              onChange={date => setSelected({ ...selected, endDate: moment(date).format('YYYY-MM-DD') })}
              label={<IntlMessages id="end.date" />}
              animateYearScrolling
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid className={classes.gridStyle} item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>
              <IntlMessages id="contract" />
            </InputLabel>
            <Select
              label={<IntlMessages id="contract" />}
              value={selected.contractId}
              onChange={event => setSelected({ ...selected, contractId: event.target.value })}>
              {values.sameContracts
                ? values.sameContracts.map((item, i) => <MenuItem value={item.id}>{item.contract_name}</MenuItem>)
                : null}
            </Select>
          </FormControl>
        </Grid>

        <Grid className={classes.gridStyle} item xs={6}>
          <MultiSelect
            options={multiDataSetter(
              'label',
              'id',
              values.roomOptions.sort((a, b) => (a.label > b.label ? 1 : -1)),
            )}
            value={multiDataSetter('label', 'id', selected.rooms)}
            onChange={event => setSelected({ ...selected, rooms: event })}
            labelledBy="Select"
            overrideStrings={{ selectSomeItems: 'Select Rooms' }}
          />

          {/* <SelectAll
            optionLabel={'label'}
            optionValue={'label'}
            optionId={'id'}
            options={values.roomOptions.sort((a, b) => (a.label > b.label ? 1 : -1))}
            onChange={val => {
              const c = val.map(k => {
                if (k?.value === undefined) {
                  return k;
                } else {
                  return k.value;
                }
              });
              setSelected({ ...selected, rooms: c });
            }}
            value={selected.rooms}
            placeholder={intl.formatMessage({ id: 'rooms' })}
          /> */}
        </Grid>
        <Grid className={classes.gridStyle} item xs={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>
              <IntlMessages id="action.type" />
            </InputLabel>
            <Select
              label={<IntlMessages id="action.type" />}
              value={selected.allocationType}
              onChange={event => setSelected({ ...selected, allocationType: event.target.value })}>
              <MenuItem value="remaining">Remaining</MenuItem>
              <MenuItem value="increase">Increase</MenuItem>
              <MenuItem value="decrease">Decrease</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid className={classes.gridStyle} item xs={6}>
          {/* <SelectAll
            optionLabel={'label'}
            optionValue={'label'}
            optionId={'id'}
            options={values.operatorOptions.sort((a, b) => (a.label > b.label ? 1 : -1))}
            onChange={val => {
              const c = val.map(k => {
                if (k?.value === undefined) {
                  return k;
                } else {
                  return k.value;
                }
              });
              setSelected({ ...selected, operators: c });
            }}
            value={selected.operators}
            placeholder={intl.formatMessage({ id: 'operators' })}
          /> */}

          <MultiSelect
            options={multiDataSetter(
              'label',
              'id',
              values.operatorOptions.sort((a, b) => (a.label > b.label ? 1 : -1)),
            )}
            value={multiDataSetter('label', 'id', selected.operators)}
            onChange={event => setSelected({ ...selected, operators: event })}
            labelledBy="Select"
            overrideStrings={{ selectSomeItems: 'Select Operators' }}
          />
        </Grid>

        <GridContainer>
          {selected.rooms?.map(room => (
            <>
              <Grid className={classes.gridStyle} item xs={3}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  variant="outlined"
                  name="allocation"
                  value={room.code}
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid className={classes.gridStyle} item xs={3}>
                <TextField
                  id="outlined-basic"
                  label={<IntlMessages id="allocation" />}
                  fullWidth
                  variant="outlined"
                  name={'allocation' + room.id}
                  value={selected['allocation' + room.id]}
                  onChange={event => allocationControl(event)}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Grid>
            </>
          ))}
        </GridContainer>
        <Grid item container xs={12} justifyContent="flex-end">
          <ThemeProvider theme={theme}>
            <Button onClick={saveAll} variant="contained" color="primary" disabled={!isAuthAdd} startIcon={<SaveIcon />}>
              <IntlMessages id="update" />
            </Button>
          </ThemeProvider>
        </Grid>
        <Grid item xs={12}>
          {tableFlag !== false ? (
            <AllotmentUpdateTable selected={selected} setSelected={setSelected} setTableFlag={tableFlag} />
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </div>
  );
}
