import React, { useState, useRef } from 'react';
import { Button, TextField } from '@material-ui/core';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { Container, Row } from 'react-grid-system';
import { Grid, CardContent } from '@material-ui/core';
import CmtCard from '@coremat/CmtCard';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import axios from 'services/axios';
import { useIntl } from 'react-intl';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  selectBoxRoot: {
    marginBottom: 20,
    '& .MuiOutlinedInput-input': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400],
    },
  },
  button: {
    height: '40px !important',
  },
}));

export default function HotelReport() {
  const classes = useStyles();
  const intl = useIntl();
  const [filterState, setFilterState] = useState({
    hotel_name: '',
    country: '',
    city: '',
    giata_id: '',
  });

  const [hotelData, setHotelData] = useState([]);
  const filterHandleChange = e => {
    const { name, value } = e.target;
    setFilterState(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  const filterSelected = () => {
    const url = 'http://localhost/api/searchGiata';
    const token = localStorage.getItem('token');
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios({
      method: 'post',
      data: filterState,
      url: url,
    }).then(res => {
      setHotelData(res.data.data);
    });
  };
  const tableColumns = [
    {
      title: <IntlMessages id="product.name" />,
      field: 'hotel_name',
    },

    {
      title: <IntlMessages id="giata.code" />,
      field: 'giata_id',
    },
    {
      title: <IntlMessages id="country" />,
      field: 'country',
    },
    {
      title: <IntlMessages id="city" />,
      field: 'city',
    },
  ];
  const tableRef = useRef();
  const downloadCsv = (data, fileName) => {
    const finalFileName = fileName.endsWith('.csv') ? fileName : `Contract Report.csv`;
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
    a.setAttribute('download', finalFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <div className={classes.selectBoxRoot}>
      <CmtCard>
        <CardContent>
          <PageContainer>
            <Container>
              <Row xs="fixed">
                <Grid container spacing={10}>
                  <Grid item sm={2}>
                    <TextField
                      required
                      placeholder={intl.formatMessage({ id: 'product.name' })}
                      name="hotel_name"
                      onChange={e => {
                        filterHandleChange(e);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <TextField
                      required
                      placeholder={intl.formatMessage({ id: 'country' })}
                      disabled={filterState.country_name ? true : false}
                      name="country"
                      onChange={e => {
                        filterHandleChange(e);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <TextField
                      required
                      placeholder={intl.formatMessage({ id: 'city' })}
                      name="city"
                      onChange={e => {
                        filterHandleChange(e);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <TextField
                      required
                      placeholder={intl.formatMessage({ id: 'giata.code' })}
                      name="giata_id"
                      onChange={e => {
                        filterHandleChange(e);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <Button variant="outlined" className={classes.button} onClick={() => filterSelected()}>
                      <IntlMessages id="filter" />
                    </Button>
                  </Grid>
                </Grid>
                <Grid item sm={12}>
                  <MaterialTable
                    tableRef={tableRef}
                    columns={tableColumns}
                    data={hotelData}
                    title=""
                    options={{
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 30, 40],
                      actionsColumnIndex: -1,
                      tableLayout: 'auto',
                      search: false,
                      editCellStyle: '10px',
                      addRowPosition: 'first',
                      exportButton: {
                        csv: true,
                        pdf: false,
                      },
                      exportCsv: (columns, data) => {
                        const headerRow = columns.map(col => {
                          if (typeof col.title === 'object') {
                            return col.title.props.text;
                          }
                          return col.title;
                        });
                        const dataRows = data.map(({ tableData, ...row }) => [
                          row.hotel_name,
                          row.giata_id,
                          row.country,
                          row.city,
                        ]);
                        const { exportDelimiter } = tableRef.current.props.options;
                        const delimiter = exportDelimiter ? exportDelimiter : ',';
                        const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join('\n');
                        const csvFileName = tableRef.current.props.title;
                        downloadCsv(csvContent, csvFileName);
                      },
                    }}
                  />
                </Grid>
              </Row>
            </Container>
          </PageContainer>
        </CardContent>
      </CmtCard>
    </div>
  );
}
