import {
  GET_CANCELLATION_POLICIES,
  ADD_CANCELLATION_POLICIES,
  UPDATE_CANCELLATION_POLICIES,
  DELETE_CANCELLATION_POLICIES,
  ADD_CANCELLATION_POLICIES_DETAIL,
  UPDATE_CANCELLATION_POLICIES_DETAIL,
  DELETE_CANCELLATION_POLICIES_DETAIL,
  GET_CONTRACT_CANCELLATION_POLICIES,
  ADD_CONTRACT_CANCELLATION_POLICIES,
  UPDATE_CONTRACT_CANCELLATION_POLICIES,
  DELETE_CONTRACT_CANCELLATION_POLICIES,
} from '../actions/types';
import { normalizeTasks } from 'redux/schemas/cancellationPoliciesDetail';

const initialState = {
  cancellationPolicies: [],
  cancellationPoliciesDetail: [],
  contractCancellationPolicies: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    //Cancelation Policies
    case GET_CANCELLATION_POLICIES:
      const datas = [];
      datas['cancellation_policy'] = action.payload;
      const normalizeDatas = normalizeTasks([datas]);
      const entities = normalizeDatas.entities;
      return {
        ...state,
        cancellationPolicies: entities.cancellation_policy ? entities.cancellation_policy : state.cancellationPolicies,
        cancellationPoliciesDetail: entities.cancellation_policies_detail
          ? entities.cancellation_policies_detail
          : state.cancellationPoliciesDetail,
      };

    case ADD_CANCELLATION_POLICIES:
      const data = [];
      data['add_cancellation_policies'] = [action.payload];
      data['state_cancellation_policies'] = state.cancellationPolicies;
      const normalizeData = normalizeTasks([data]);
      return {
        ...state,
        cancellationPolicies: normalizeData.entities.cancellation_policy
          ? normalizeData.entities.cancellation_policy
          : state.cancellationPolicies,
      };

    case UPDATE_CANCELLATION_POLICIES:
      const updatedData = [];
      updatedData['updated_cancellation_policies'] = [action.payload];
      const UpdatedNormalizeData = normalizeTasks([updatedData]);
      const updatedCanPolDetId = UpdatedNormalizeData.result[0].updated_cancellation_policies[0];

      return {
        ...state,
        cancellationPolicies: {
          ...state.cancellationPolicies,
          [updatedCanPolDetId]: UpdatedNormalizeData.entities.cancellation_policy[updatedCanPolDetId],
        },
      };

    case DELETE_CANCELLATION_POLICIES:
      /*  const deletedCanPolicy = Object.keys(state.cancellationPolicies)
        .filter(id => id !== action.payload.id)
        .reduce((obj, key) => {
          obj[key] = state.cancellationPolicies[key];
          return obj;
        }, {}); */

      state.cancellationPolicies[action.payload.id].cancellation_policies_detail.forEach(element => {
        delete state.cancellationPoliciesDetail[element];
      });
      delete state.cancellationPolicies[action.payload.id];
      return {
        ...state,
        cancellationPolicies: state.cancellationPolicies,
        // cancellationPoliciesDetail: deletedCanPolicyDetail,
      };

    //Cancellation Policies Detail
    case ADD_CANCELLATION_POLICIES_DETAIL:
      const addCanPolDetData = [];
      addCanPolDetData['addCanPolDetail'] = [action.payload];
      addCanPolDetData['stateCanPolDetail'] = state.cancellationPoliciesDetail;
      const addCanPolDetNormalize = normalizeTasks([addCanPolDetData]);
      const addCanPolDetId = addCanPolDetNormalize.result[0].addCanPolDetail[0];
      const addCanPolId =
        addCanPolDetNormalize.entities.cancellation_policies_detail[addCanPolDetId].cancellation_policies_id;
      state.cancellationPolicies[addCanPolId].cancellation_policies_detail.push(addCanPolDetId);
      return {
        ...state,
        cancellationPoliciesDetail: addCanPolDetNormalize.entities.cancellation_policies_detail
          ? addCanPolDetNormalize.entities.cancellation_policies_detail
          : state.cancellationPoliciesDetail,
      };

    case UPDATE_CANCELLATION_POLICIES_DETAIL:
      const updatedCanPolDetData = [];
      updatedCanPolDetData['updateCanPolDetail'] = action.payload;
      updatedCanPolDetData['stateCanPolDetail'] = state.cancellationPoliciesDetail;
      const updatedCanPolDetNormalize = normalizeTasks([updatedCanPolDetData]);
      return {
        ...state,
        cancellationPoliciesDetail: updatedCanPolDetNormalize.entities.cancellation_policies_detail,
      };

    case DELETE_CANCELLATION_POLICIES_DETAIL:
      const deletedCanPolDetData = [];
      deletedCanPolDetData['deletedCanPolDetail'] = [action.payload];
      const deletedCanPolDetNormalize = normalizeTasks([deletedCanPolDetData]);
      const deletedCanPolDetId = deletedCanPolDetNormalize.result[0].deletedCanPolDetail[0];
      const deletedCanPolDetIndex = state.cancellationPolicies[
        action.payload.cancellation_policies_id
      ].cancellation_policies_detail.findIndex(x => x == deletedCanPolDetId);
      state.cancellationPolicies[[action.payload.cancellation_policies_id]].cancellation_policies_detail.splice(
        deletedCanPolDetIndex,
        1,
      );

      delete state.cancellationPoliciesDetail[action.payload.id];
      return {
        ...state,
        cancellationPoliciesDetail: state.cancellationPoliciesDetail,
      };

    case GET_CONTRACT_CANCELLATION_POLICIES:
      return { ...state, contractCancellationPolicies: action.payload };

    case ADD_CONTRACT_CANCELLATION_POLICIES:
      const deneme = state.contractCancellationPolicies.findIndex(pol => pol.id == action.payload.id);

      return {
        ...state,
        contractCancellationPolicies: [...state.contractCancellationPolicies, action.payload],
      };

    case UPDATE_CONTRACT_CANCELLATION_POLICIES:
      const newIndex = state.contractCancellationPolicies.findIndex(pol => pol.id === action.payload.id);
      const newContractCanPol = [...state.contractCancellationPolicies];
      newContractCanPol[newIndex] = action.payload;
      return {
        ...state,
        contractCancellationPolicies: newContractCanPol,
      };

    case DELETE_CONTRACT_CANCELLATION_POLICIES:
      const deleteContractCancellationPolicies = state.contractCancellationPolicies.filter(
        pol => pol.id !== action.payload.id,
      );

      return {
        ...state,
        contractCancellationPolicies: deleteContractCancellationPolicies,
      };
    default:
      return state;
  }
};
