import React, { useState, useEffect, Suspense, lazy } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import { useDispatch, useSelector } from "react-redux";
import {
  getMarketService,
  addMarketService,
  deleteMarketService,
  updateMarketService
} from "services/market";
import {
  getRegionsService,
  getAllCountriesService,
  getCountriesService
} from "services/destinations";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import GridContainer from "@jumbo/components/GridContainer";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { blue, orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import CmtSearch from "@coremat/CmtSearch";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useIntl } from "react-intl";
import { multiDataSetter } from "@jumbo/utils/commonHelper";
import { MultiSelect } from "react-multi-select-component";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Modal } from "@material-ui/core";

const theme = createTheme({
  palette: {
    backgroundColor: orange[400]
  }
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500]
    }
  }
}))(Button);

const InfoButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blue[400]),
    backgroundColor: blue[400],
    "&:hover": {
      backgroundColor: blue[500]
    }
  }
}))(Button);

const CountryModalStyles = makeStyles((theme) => ({
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "1px solid #000",
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    padding: 30,
    overflowY: "scroll",
    maxHeight: "500px",
    width: "500px"
  }
}));

const MySwal = withReactContent(Swal);
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto"
  },
  cardHeader: {
    padding: theme.spacing(2, 4)
  },
  list: {
    width: 300,
    height: 330,
    backgroundColor: theme.palette.background.default,
    overflow: "auto"
  },
  button: {
    margin: theme.spacing(1, 0)
  }
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function Market({ isAuthAdd, isAuthUpdate, isAuthDelete }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    if (Object.values(markets).length === 0) {
      dispatch(getMarketService());
    }
    dispatch(getRegionsService());
    dispatch(getAllCountriesService());
  }, [dispatch]);

  const [showCountryModal, setShowCountryModal] = useState(false);

  const [marketCountries, setMarketCountries] = useState([]);
  const countriesState = useSelector((state) => state.destinations.countries);
  const { markets } = useSelector(({ markets }) => markets);
  const { contracts } = useSelector(({ contract }) => contract);

  const checkCountryHandler = (data) => {
    setMarketCountries(data);
    setShowCountryModal(!showCountryModal);
  };
  const getCountryNames = (countryIds) => {
    return countryIds?.reduce((acc, curr) => {
      return acc.concat(
        countriesState.find((country) => country.id === curr)?.name
      );
    }, []);
  };

  const customValueRenderer = (selected, _options) => {
    return selected.length
      ? selected.map(({ label }) => "✔️ " + label)
      : "😶 No Items Selected";
  };

  const countryModalClasses = CountryModalStyles();

  const [columnsMarket, setColumnsBaseMarket] = useState([
    {
      title: intl.formatMessage({ id: "code" }),
      align: "center",
      field: "code",
      validate: (rowData) => (rowData.code ? true : false)
    },
    {
      title: intl.formatMessage({ id: "market.name" }),
      align: "center",
      field: "name",
      validate: (rowData) => (rowData.name ? true : false)
    },
    {
      title: intl.formatMessage({ id: "countries" }),
      field: "countries",
      type: "string",
      //cellStyle: { wordBreak: 'break-all' },
      validate: (rowData) =>
        rowData.countries && rowData.countries.length !== 0 ? true : false,
      editComponent: ({ onChange, value, rowData }) => {
        const countryArray = [];
        if (
          value !== undefined &&
          Array.isArray(value) &&
          typeof value[0] === "number"
        ) {
          value.forEach((countryId) => {
            countryArray.push(
              countriesState.find((country) => country.id === countryId)
            );
          });
        }
        return (
          countriesState.length > 0 && (
            <MultiSelect
            
              options={multiDataSetter("name", "id", countriesState)}
              value={multiDataSetter(
                "name",
                "id",
                countryArray.length > 0 ? countryArray : value
              )}
              onChange={(e) => onChange(e)}
              labelledBy="Select"
              overrideStrings={{ selectSomeItems: "Select countries" }}
            />
            // <SelectAllTest
            //   options={countriesState}
            //   labelKey={"name"}
            //   valueKey={"id"}
            //   // data={data.rowData.countries}
            //   placeholder={"Countries"}
            // />
          )
        );
      },
      render: (rowData) => {
        rowData.countries = rowData.countries ? rowData.countries : [];
        if (rowData?.countries?.length === 0) {
          return "No Country Selected";
        }

        if (rowData?.countries?.length > 4) {
          return (
            <InfoButton
              onClick={() =>
                checkCountryHandler(getCountryNames(rowData.countries))
              }
              variant="contained"
              color="backgroundColor"
              startIcon={<InfoIcon />}
            >
              <IntlMessages id="check.countries" style={{ color: "#fff" }} />
            </InfoButton>
          );
        } else {
          let countryArr = [];
          getCountryNames(rowData.countries).map((item, idx) => {
            if (idx !== rowData.countries.length - 1) {
              countryArr.push(item, ", ");
            } else {
              countryArr.push(item);
            }
          });
          return countryArr;
        }
      }
    }
  ]);

  const hasUseMarket = (market_id) => {
    var contractMarkets = [];
    var has = true;

    Object.values(contracts).forEach((contract) => {
      contract.market.forEach((market) => {
        if (markets[market].id == market_id) {
          has = false;
        }
      });
    });
    Object.values(contracts).forEach((contract) => {
      contractMarkets = [...contractMarkets, ...contract.market];
    });
    if (contractMarkets.find((market) => market.id == market_id)) {
      has = false;
    }
    return has;
  };
  return (
    <>
      <MaterialTable
        columns={columnsMarket}
        data={Object.values(markets)}
        title=""
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40],
          search: false,
          addRowPosition: "first"
        }}
        actions={[
          isAuthAdd
            ? undefined
            : {
                icon: "add",
                disabled: true,
                position: "toolbar",
                tooltip: "You are not authorized"
              }
        ]}
        components={{
          EditRow: (props) => {
            return (
              <MTableEditRow
                {...props}
                onKeyDown={(e) => {
                  if (
                    e.keyCode === 27 ||
                    e.keyCode === 109 ||
                    e.keyCode === 189
                  ) {
                    e.preventDefault();
                  }
                }}
                onEditingCanceled={(mode, rowData) => {
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      if (mode == "update") {
                        Swal.fire({
                          title: intl.formatMessage({ id: "are.you.sure?" }),
                          text: intl.formatMessage({
                            id: "do.you.want.to.cancel.the.changes"
                          }),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#41C329",
                          allowOutsideClick: false,
                          cancelButtonColor: "#d33",
                          confirmButtonText: intl.formatMessage({ id: "yes" }),
                          cancelButtonText: intl.formatMessage({ id: "no" })
                        }).then((result) => {
                          if (result.isConfirmed) {
                            props.onEditingCanceled(mode);
                            resolve();
                          } else if (result.isDenied) {
                            reject();
                          }
                        });
                      }
                      if (mode == "add") {
                        Swal.fire({
                          title: intl.formatMessage({ id: "are.you.sure?" }),
                          text: intl.formatMessage({
                            id: "do.you.want.to.cancel.the.changes"
                          }),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#41C329",
                          allowOutsideClick: false,
                          cancelButtonColor: "#d33",
                          confirmButtonText: intl.formatMessage({ id: "yes" }),
                          cancelButtonText: intl.formatMessage({ id: "no" })
                        }).then((result) => {
                          if (result.isConfirmed) {
                            props.onEditingCanceled(mode, rowData);
                            resolve();
                          } else if (result.isDenied) {
                            reject();
                          }
                        });
                      }
                      if (mode == "delete") {
                        props.onEditingCanceled(mode, rowData);
                      }
                    });
                  });
                }}
              />
            );
          },
          onRowAdd: (props) => (
            <MTableEditRow
              {...props}
              onKeyDown={(e) => {
                if (
                  e.keyCode === 27 ||
                  e.keyCode === 109 ||
                  e.keyCode === 189
                ) {
                  e.preventDefault();
                }
              }}
            />
          )
        }}
        icons={{
          Add: (props) => (
            <ThemeProvider theme={theme}>
              <ColorButton
                variant="contained"
                color="backgroundColor"
                startIcon={<AddBoxIcon />}
              >
                <IntlMessages id="add" />
              </ColorButton>
            </ThemeProvider>
          )
        }}
        editable={{
          deleteTooltip: (row) =>
            isAuthDelete ? "Delete" : "You are not authorized",
          editTooltip: (row) =>
            isAuthUpdate ? "Update" : "You are not authorized",
          isDeletable: (row) => (isAuthDelete ? true : false),
          isEditable: (row) => (isAuthUpdate ? true : false),
          onRowAdd: isAuthAdd
            ? (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (newData.code && newData.name && newData.countries && newData.countries.length > 0) {
                      if (
                        Object.values(markets).filter(
                          (market) => market.code === newData.code.toUpperCase()
                        ).length == 0 &&
                        Object.values(markets).filter(
                          (market) => market.name === newData.name.toUpperCase()
                        ).length == 0
                      ) {
                        newData.code = newData.code.toUpperCase();
                        newData.name = newData.name.toUpperCase();
                        dispatch(addMarketService(newData));
                        resolve();
                      } else {
                        MySwal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: intl.formatMessage({
                            id: "market.already.exists"
                          })
                        });
                        reject();
                      }
                    } else {
                      MySwal.fire(
                        "Oops...",
                        intl.formatMessage({ id: "please.fill.all.fields" }),
                        "error"
                      );
                      reject();
                    }
                  }, 1000);
                })
            : undefined,
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                if (newData.code && newData.name) {
                  const data = {
                    id: newData.id,
                    code: newData.code.toUpperCase(),
                    name: newData.name.toUpperCase(),
                    countries: newData.countries
                  };
                  dispatch(updateMarketService(data));
                  resolve();
                } else {
                  MySwal.fire(
                    "Oops...",
                    intl.formatMessage({ id: "please.fill.all.fields" }),
                    "error"
                  );
                  reject();
                }
              }, 1000);
            }),

          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                if (hasUseMarket(oldData.id)) {
                  dispatch(deleteMarketService(oldData.id));
                } else {
                  MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Market in use!"
                  });
                }

                resolve();
              }, 1000);
            })
        }}
      />
      <Modal
        open={showCountryModal}
        onClose={() => setShowCountryModal(!showCountryModal)}
        aria-labelledby="country-detail-modal-title"
        aria-describedby="country-detail-modal-description"
      >
        <Box className={countryModalClasses.content}>
          <Typography
            id="country-detail-modal-title"
            variant="h6"
            component="h2"
            style={{ marginBottom: "30px" }}
          >
            MARKET COUNTRIES
          </Typography>
          {marketCountries.map((country, idx) => (
            <Typography
              key={idx}
              className="country-detail-modal-description"
              sx={{ mt: 2 }}
              style={{ marginBottom: "6px" }}
            >
              {country}
            </Typography>
          ))}
        </Box>
      </Modal>
    </>
  );
}

export default Market;
