import { GET_PERMISSIONS_BY_AUTH_USER, ADD_USER_PERMISSION } from '@jumbo/constants/ActionTypes';

const INITIAL_STATE = {
  permissionsByAuthUser: [],
};

const permissionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PERMISSIONS_BY_AUTH_USER:
      return { ...state, permissionsByAuthUser: action.payload };
    case ADD_USER_PERMISSION:
      return { ...state, permissionsByAuthUser: action.payload };

    default:
      return state;
  }
};

export default permissionReducer;
