const calculate = {
  layout: {
    marginTop: 2 + 'rem',
    width: window.innerWidth > 768 ? '70%' : '90%',
  },
  headerText: {
    color: '#33658a',
    fontSize: '1.2rem',
    fontFamily: 'arial',
  },
  content: {
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: window.innerWidth > 800 ? 'row' : 'column',
  },
  contentSection1: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    width: window.innerWidth > 800 ? '50%' : '90%',
    borderRight: window.innerWidth > 800 ? '1px solid rgba(0,0,0,.2)' : '',
    paddingRight: window.innerWidth > 800 ? '30px' : '',
  },
  contentSection2: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    width: window.innerWidth > 800 ? '50%' : '90%',
    marginLeft: window.innerWidth > 800 ? '2rem' : '',
  },
  cell: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 1 + 'rem',
  },
  cellInput: {
    border: 'none',
    outline: 'none',
    boxShadow: '0 0 2px black',
    borderRadius: 2.5 + 'px',
    padding: 5 + 'px',
    textAlign: 'center',
  },
  cellLabel: {
    fontSize: 1 + 'rem',
    color: '#33658a',
    border: '1px solid #33658a',
    padding: 5 + 'px',
    width: 7 + 'rem ',
    textAlign: 'center',
    borderRadius: 5 + 'px',
    backgroundColor: '#F7F7F7',
  },
};

export default calculate;
