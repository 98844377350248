import React, { useEffect, useState, useRef } from 'react';
import { Button, TextField } from '@material-ui/core';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { Container, Row } from 'react-grid-system';
import { Grid, CardContent } from '@material-ui/core';
import CmtCard from '@coremat/CmtCard';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, Space } from 'antd';
import { getHotelService } from 'services/hotels';
import { getContractService } from 'services/contract';
import { getOperatorService } from 'services/operator';
import { getMarketService } from 'services/market';
import { getBoardsService } from 'services/boards';
import { getReservationsService } from 'services/reservation';
import { getCurrenciesService } from 'services/currency';
import { getRoomService } from 'services/rooms';
import MaterialTable from 'material-table';
import moment from 'moment';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  selectBoxRoot: {
    marginBottom: 20,
    '& .MuiOutlinedInput-input': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400],
    },
  },
  button: {
    height: '40px !important',
  },
}));

export default function ContractReport() {
  const { authUser } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const [bookingStatus, setBookingStatus] = useState([
    { name: 'NEW', id: 1 },
    { name: 'UPDATED', id: 2 },
    { name: 'CANCELLED', id: 3 },
    { name: 'NO SHOW', id: 4 },
  ]);
  const [communicationStatus, setCommunicationStatus] = useState([
    { name: 'SENT', id: 1 },
    { name: 'PENDING SENDING', id: 2 },
  ]);
  const [hotelStatus, setHotelStatus] = useState([
    { name: 'NOT SENT', id: 1 },
    { name: 'WAITING', id: 2 },
    { name: 'CANCELLED', id: 3 },
    { name: 'CONFIRMED', id: 4 },
  ]);
  const [filterState, setFilterState] = useState({
    hotel_id: '',
    operator_id: '',
    voucher_no: '',
    hotel_status: '',
    user_id: '',
    selling_date: '',
    start_date: '',
    end_date: '',
  });
  useEffect(async () => {
    await dispatch(getContractService(authUser.authority_level, localStorage.getItem('selectedCompany')));
    await dispatch(getBoardsService());
    await dispatch(getHotelService());
    await dispatch(getOperatorService());
    await dispatch(getMarketService());
    await dispatch(getReservationsService());
    await dispatch(getCurrenciesService());
    await dispatch(getRoomService());
  }, [dispatch]);
  const dispatch = useDispatch();
  const intl = useIntl();
  const { contracts } = useSelector(({ contract }) => contract);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { operators } = useSelector(({ operators }) => operators);
  const { markets } = useSelector(({ markets }) => markets);
  const { contract_has_board } = useSelector(({ contract }) => contract);
  const { boards } = useSelector(({ boards }) => boards);
  const { currencies } = useSelector(({ currencies }) => currencies);
  const { contract_room } = useSelector(({ contract }) => contract);

  const {
    rooms: { roomType },
  } = useSelector(({ rooms }) => rooms);
  const reservations = useSelector(state => state.reservation?.entities?.reservations);
  const reservation_rooms = useSelector(state => state.reservation?.entities?.rooms);
  const [reservationData, setReserationData] = useState([]);

  const filterHandleChange = e => {
    const { name, value } = e;
    setFilterState(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  const filterSelected = () => {
    if (reservations !== undefined || null) {
      let allReservations = Object.values(reservations)?.map(reservation => {
        let resHotel = hotels[reservation.hotel_id];
        let newReservation = {
          ...reservation,
          hotelStatus:
            reservation.hotel_status === 1
              ? 'NOT SENT'
              : reservation.hotel_status === 2
              ? 'WAITING'
              : reservation.hotel_status === 3
              ? 'CANCELLED'
              : 'CONFIRMED',
          bookingStatus:
            reservation.booking_status === 1
              ? 'NEW'
              : reservation.booking_status === 2
              ? 'UPDATED'
              : reservation.booking_status === 3
              ? 'CANCELLED'
              : 'NO SHOW',
          communicationStatus: reservation.communication_status === 1 ? 'SENT' : 'PENDING SENDING',
          operator_name: operators[reservation.operator_id]?.code,
          tourOpNo: '',
          voucher_no: reservation.voucher_no,
          lead_name: reservation.lead_name,
          product_name: resHotel?.name,
          checkInDate: reservation.start_date,
          checkOutDate: reservation.end_date,
          selling_date: reservation.selling_date,
          noOfNights: (Date.parse(reservation.end_date) - Date.parse(reservation.start_date)) / (1000 * 60 * 60 * 24),
          room_type: (function() {
            let room_string = '';
            reservation.rooms.forEach(room => {
              room_string += roomType[contract_room[reservation_rooms[room]?.contract_room_id]?.room_id]?.code + ' ';
            });
            return room_string;
          })(),
          board_name: boards[contract_has_board[reservation.board_id]?.board_id]?.code,
          adult_num: reservation.adult_num,
          child_num: reservation.child_num,
          infant_num: reservation.infant_num,
          amount: '',
          currency_name: currencies[contracts[reservation.contract_id]?.currency]?.code,
          contract_name: contracts[reservation.contract_id]?.contract_name,
          market_name: (function() {
            let market_string = '';
            contracts[reservation.contract_id].market.forEach(market => {
              market_string += markets[market]?.code + ' ';
            });
            return market_string;
          })(),
        };
        return newReservation;
      });
      Object.keys(filterState).forEach(filterKey => {
        if (filterState[filterKey] !== '') {
          if (filterKey !== 'start_date' && filterKey !== 'end_date' && filterKey !== 'selling_date') {
            allReservations = allReservations.filter(res => res[filterKey] == filterState[filterKey]);
          } else {
            allReservations = allReservations.filter(res =>
              moment(res[filterKey]).isBetween(filterState[filterKey].from, filterState[filterKey].to, undefined, '[]'),
            );
          }
        }
      });
      setReserationData(allReservations);
    }
  };
  const handleChangeStart = (name, value) => {
    setFilterState({ ...filterState, start_date: { from: value[0], to: value[1] } });
  };
  const handleChangeEnd = (name, value) => {
    setFilterState({ ...filterState, end_date: { from: value[0], to: value[1] } });
  };
  const handleChangeSelling = (name, value) => {
    setFilterState({ ...filterState, selling_date: { from: value[0], to: value[1] } });
  };
  const tableColumns = [
    {
      title: <IntlMessages id="hotel.status" />,
      field: 'hotelStatus',
    },
    {
      title: <IntlMessages id="booking.status" />,
      field: 'bookingStatus',
    },
    {
      title: <IntlMessages id="communication.status" />,
      field: 'communicationStatus',
    },
    {
      title: <IntlMessages id="tour.operator" />,
      field: 'operator_name',
    },
    {
      title: <IntlMessages id="tour.op.booking.no" />,
      field: 'tourOpNo',
    },
    {
      title: <IntlMessages id="proxo.booking.no" />,
      field: 'voucher_no',
    },
    {
      title: <IntlMessages id="lead.name" />,
      field: 'lead_name',
    },
    {
      title: <IntlMessages id="hotel.name" />,
      field: 'product_name',
    },
    {
      title: <IntlMessages id="check.in.date" />,
      field: 'start_date',
    },
    {
      title: <IntlMessages id="check.out.date" />,
      field: 'end_date',
    },
    {
      title: <IntlMessages id="selling.date" />,
      field: 'selling_date',
    },
    {
      title: <IntlMessages id="stay.nights" />,
      field: 'stayNights',
    },
    {
      title: <IntlMessages id="room.type" />,
      field: 'room_type',
    },
    {
      title: <IntlMessages id="board.type" />,
      field: 'board_name',
    },
    {
      title: <IntlMessages id="number.of.adult" />,
      field: 'adult_num',
    },
    {
      title: <IntlMessages id="number.of.child" />,
      field: 'child_num',
    },
    {
      title: <IntlMessages id="number.of.infant" />,
      field: 'infant_num',
    },
    {
      title: <IntlMessages id="amount" />,
      field: 'amount',
    },
    {
      title: <IntlMessages id="currency" />,
      field: 'currency_name',
    },
    {
      title: <IntlMessages id="contract" />,
      field: 'contract_name',
    },
    {
      title: <IntlMessages id="market" />,
      field: 'market_name',
    },
  ];
  const { RangePicker } = DatePicker;
  const tableRef = useRef();
  const downloadCsv = (data, fileName) => {
    const finalFileName = fileName.endsWith('.csv') ? fileName : `Reservation Report.csv`;
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
    a.setAttribute('download', finalFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <div className={classes.selectBoxRoot}>
      <CmtCard>
        <CardContent>
          <PageContainer>
            <Container>
              <Row xs="fixed">
                <Grid container spacing={10}>
                  <Grid item sm={3}>
                    <IntlMessages id="check.in.date.range" />
                    <Space direction="vertical" size={12}>
                      <RangePicker placeholder={['from', 'to']} onChange={handleChangeStart} />
                    </Space>
                  </Grid>
                  <Grid item sm={3}>
                    <IntlMessages id="check.out.date.range" />
                    <Space direction="vertical" size={12}>
                      <RangePicker placeholder={['from', 'to']} onChange={handleChangeEnd} />
                    </Space>
                  </Grid>

                  <Grid item sm={3}>
                    <IntlMessages id="selling.date.range" />
                    <Space direction="vertical" size={12}>
                      <RangePicker placeholder={['from', 'to']} onChange={handleChangeSelling} />
                    </Space>
                  </Grid>
                  <Grid item sm={2}>
                    <TextField
                      id="outlined-basic"
                      name="voucher_no"
                      label={<IntlMessages id="proxo.booking.no" />}
                      variant="outlined"
                      onChange={(e, value) => {
                        let event = {
                          name: 'voucher_no',
                          value: e.target.value,
                        };
                        filterHandleChange(event);
                      }}
                    />
                  </Grid>
                  <Grid container spacing={10}>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(operators)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'operator_id',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({ id: 'operator' })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(hotels)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'hotel_id',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({ id: 'product' })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item sm={3}>
                      <Autocomplete
                        options={hotelStatus}
                        onChange={(e, value) => {
                          let event = {
                            name: 'hotel_status',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({ id: 'confirm.status' })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {/* <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(hotels)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'opBookNo',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.code}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder="Select Opeator Booking No"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(hotels)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'hotelConfirmeNo',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder="Select Hotel Confirmation No"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid> */}
                    <Grid item sm={3}>
                      <Button variant="outlined" className={classes.button} onClick={() => filterSelected()}>
                        <IntlMessages id="filter" />
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item sm={12}>
                    <MaterialTable
                      tableRef={tableRef}
                      columns={tableColumns}
                      data={reservationData}
                      title=""
                      options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30, 40],
                        actionsColumnIndex: -1,
                        tableLayout: 'auto',
                        search: false,
                        editCellStyle: '10px',
                        addRowPosition: 'first',
                        exportButton: {
                          csv: true,
                          pdf: false,
                        },
                        exportCsv: (columns, data) => {
                          const headerRow = columns.map(col => {
                            if (typeof col.title === 'object') {
                              return col.title.props.text;
                            }
                            return col.title;
                          });
                          const dataRows = data.map(({ tableData, ...row }) => [
                            row.hotelStatus,
                            row.bookingStatus,
                            row.communicationStatus,
                            row.operator_name,
                            row.tourOpNo,
                            row.voucher_no,
                            row.lead_name,
                            row.product_name,
                            row.checkInDate,
                            row.checkOutDate,
                            row.selling_date,
                            row.noOfNights,
                            row.room_type,
                            row.board_name,
                            row.adult_num,
                            row.child_num,
                            row.infant_num,
                            row.amount,
                            row.currency_name,
                            row.contract_name,
                            row.market_name,
                          ]);
                          const { exportDelimiter } = tableRef.current.props.options;
                          const delimiter = exportDelimiter ? exportDelimiter : ',';
                          const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join('\n');

                          const csvFileName = tableRef.current.props.title;

                          downloadCsv(csvContent, csvFileName);
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Row>
            </Container>
          </PageContainer>
        </CardContent>
      </CmtCard>
    </div>
  );
}
