import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import CmtSearch from '../../../../../@coremat/CmtSearch';
import useStyles from './index.style';
import IntlMessages from '@jumbo/utils/IntlMessages';

const UserTableToolbar = ({ onUserAdd, searchTerm, setSearchTerm }) => {
  const classes = useStyles();
  return (
    <div>
      <Toolbar>
        <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
          <IntlMessages id="users" />
          <Button color="primary" onClick={() => onUserAdd(true)}>
            <IntlMessages id="create.new.user" />
          </Button>
        </Typography>

        <div>
          <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
        </div>
      </Toolbar>
    </div>
  );
};

export default React.memo(UserTableToolbar);
