import React, { useState, useEffect, Fragment } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { Divider, FormControl, Grid, TextField, Button } from '@material-ui/core';
import { addTermsConditionsService, updateTermsConditionsService } from 'services/termsConditions';
import { green, purple, red } from '@material-ui/core/colors';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import UpdateIcon from '@mui/icons-material/Update';
import SaveIcon from '@mui/icons-material/Save';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: '',
    text: text,
  });
};
let Editor = () => <Fragment />;
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function TermConditionsModal(props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  var contentDataState = ContentState.createFromBlockArray(convertFromHTML('<u>' + props.termsData.termCon + '</u>'));
  var editorDataState = EditorState.createWithContent(contentDataState);

  const [editorState, setEditorState] = useState(editorDataState);

  const onEditorStateChange = editorStateData => {
    setEditorState(editorStateData);
  };

  const theme = createTheme({
    palette: {
      primary: green,
      secondary: purple,
    },
  });
  const themeCancel = createTheme({
    palette: {
      primary: red,
    },
  });
  useEffect(() => {
    Editor = require('react-draft-wysiwyg').Editor;
    setEditorState(EditorState.createWithContent(contentDataState));
  }, []);

  const [values, setValues] = useState({ termName: '', termCon: '' });
  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  const handleChangeEditor = event => {
    setValues({ ...values, termCon: draftToHtml(convertToRaw(editorState.getCurrentContent())) });
  };

  useEffect(() => {
    setValues(props.termsData);
    contentDataState = ContentState.createFromBlockArray(
      convertFromHTML(props.termsData.termCon ? '<u>' + props.termsData.termCon + '</u>' : '<u>' + '' + '</u>'),
    );
    editorDataState = EditorState.createWithContent(contentDataState);
    onEditorStateChange(editorDataState);
  }, [props.termsData]);

  const saveAll = () => {
    if (
      (values.termName !== undefined && values.termCon !== undefined) ||
      (values.id !== undefined && values.termName !== ' ' && values.termCon !== ' ')
    ) {
      dispatch(addTermsConditionsService(values)).then(res => {
        if (res === 201) {
          sweetAlerts(
            'success',
            intl.formatMessage({ id: 'terms.and.conditions' }) + intl.formatMessage({ id: 'created.successfully' }),
          );
        }
      });
      props.dialogClose();
    } else {
      sweetAlerts('warning', intl.formatMessage({ id: 'please.fill.in.all.fields' }));
    }
  };
  const updateAll = () => {
    if (values.termName !== '' && (values.termCon !== '' || undefined)) {
      dispatch(updateTermsConditionsService(values)).then(res => {
        if (res === 201) {
          sweetAlerts(
            'success',
            intl.formatMessage({ id: 'terms.and.conditions' }) + intl.formatMessage({ id: 'updated.successfully' }),
          );
        }
      });
      props.dialogClose();
    }
  };
  const useStyles = makeStyles(theme => ({
    root: {
      minWidth: '100%',
      minHeight: '100%',
      backgroundColor: theme.palette.background.paper,
      color: localStorage.getItem('theme-type') === 'dark' ? 'white' : 'black',
    },
    formControl: {
      margin: theme.spacing(2),
      minWidth: '45%',
      backgroundColor: theme.palette.background.paper,
    },
    margin: {
      margin: theme.spacing(1),
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      backgroundColor: theme.palette.background.paper,
    },
    button: {
      float: 'right',
      margin: theme.spacing(2),
      fontSize: '12px!important',
      maxWidth: '90%',
      backgroundColor: theme.palette.background.paper,
    },
  }));
  const classes = useStyles();
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={props.dialogStatus}
      TransitionComponent={Transition}
      keepMounted
      onBackdropClick="false"
      onClose={props.dialogClose}
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle className={classes.root}>
        <IntlMessages id="terms.and.conditions" />
      </DialogTitle>
      <DialogContent className={classes.root}>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <FormControl>
              <TextField
                name="termName"
                label={<IntlMessages id="name" />}
                fullWidth
                placeholder={intl.formatMessage({ id: 'name' })}
                value={values.termName !== undefined ? values.termName : ''}
                onChange={handleChange}
                inputProps={{
                  disableUnderline: false,
                  style: {
                    color: localStorage.getItem('theme-type') === 'dark' ? 'white' : 'black',
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Fragment>
              <Editor
                editorStyle={{
                  width: '100%',
                  maxHeight: 200,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: 'lightgray',
                  color: localStorage.getItem('theme-type') === 'dark' ? 'white' : 'black',
                }}
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                onChange={handleChangeEditor}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
              />
            </Fragment>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
          <Divider />
          <Grid xs={1}>
            <FormControl className={classes.button}>
              <ThemeProvider theme={themeCancel}>
                <Button variant="contained" color="primary" onClick={props.dialogClose}>
                  <IntlMessages id="cancel" />
                </Button>
              </ThemeProvider>
            </FormControl>
          </Grid>
          {props.termsData.termName === undefined && props.termsData.termCon === undefined ? (
            <Grid xs={1}>
              <FormControl className={classes.button}>
                <ThemeProvider theme={theme}>
                  <Button onClick={() => saveAll()} startIcon={<SaveIcon />} variant="contained" color="primary">
                    <IntlMessages id="save" />
                  </Button>
                </ThemeProvider>
              </FormControl>
            </Grid>
          ) : (
            <Grid xs={1}>
              <FormControl className={classes.button}>
                <ThemeProvider theme={theme}>
                  <Button onClick={() => updateAll()} variant="contained" color="secondary">
                    <IntlMessages id="update" />
                  </Button>
                </ThemeProvider>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
