import {
  GET_BOARD_SUPPLAMENT,
  ADD_BOARD_SUPPLAMENT,
  UPDATE_BOARD_SUPPLAMENT,
  DELETE_BOARD_SUPPLAMENT,
  ADD_BOARD_SUPPLAMENT_REDUCTION,
  UPDATE_BOARD_SUPPLAMENT_REDUCTION,
  DELETE_BOARD_SUPPLAMENT_REDUCTION,
  SPECIAL_REDUCTION_RESET,
} from '../actions/types';
import { normalizeBoardSupplaments } from 'redux/schemas/boardSupplament';

const initialState = {
  boardSupplaments: [],
  reduction: [],
  special_reduction: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BOARD_SUPPLAMENT:
      const data = [];
      data['boardSupplaments'] = action.payload.board;
      data['reduction'] = action.payload.reduction;
      const normalizedData = normalizeBoardSupplaments([data]);
      return {
        ...state,
        boardSupplaments: normalizedData.entities.boardSupplaments ? normalizedData.entities.boardSupplaments : [],
        reduction: normalizedData.entities.reduction ? normalizedData.entities.reduction : [],
      };

    case ADD_BOARD_SUPPLAMENT:
      let addData = [];
      addData['addBoardSupplament'] = [action.payload.board];
      addData['stateBoardSupplament'] = state.boardSupplaments;
      const normalizedAddData = normalizeBoardSupplaments([addData]);
      return {
        ...state,
        boardSupplaments: normalizedAddData.entities.boardSupplaments
          ? normalizedAddData.entities.boardSupplaments
          : state.boardSupplaments,
        reduction: state.reduction,
      };

    case UPDATE_BOARD_SUPPLAMENT:
      const updateData = [];
      updateData['updateBoardSupplament'] = [action.payload.board];
      updateData['stateBoardSupplament'] = state.boardSupplaments;
      const normalizedUpdateData = normalizeBoardSupplaments([updateData]);

      return {
        ...state,
        boardSupplaments: normalizedUpdateData.entities.boardSupplaments
          ? normalizedUpdateData.entities.boardSupplaments
          : state.boardSupplaments,
        reduction: state.reduction,
      };

    case DELETE_BOARD_SUPPLAMENT:
      let deleteBoardSupplament = state.boardSupplaments;
      delete deleteBoardSupplament[action.payload.board];
      let deleteReduction = state.reduction;
      delete deleteReduction[action.payload.reduction];

      return { ...state, reduction: deleteReduction, boardSupplaments: deleteBoardSupplament };

    case ADD_BOARD_SUPPLAMENT_REDUCTION:
      let addReductionData = [];
      let state_special_reduction = state.special_reduction;
      let indexAdd = action.payload.reduction;
      addReductionData['addReduction'] = [indexAdd];
      addReductionData['stateReduction'] = state.reduction;
      const normalizedAddReduction = normalizeBoardSupplaments([addReductionData]);
      if (!action.payload.id && !action.payload.reduction) {
        let casperID =
          Object.keys(state.special_reduction).length > 0
            ? Object.values(state.special_reduction).slice(-1)[0].id + 1
            : Object.values(state.reduction).length > 0
            ? Object.values(state.reduction).slice(-1)[0].id + 1
            : 1;
        action.payload['casperID'] = casperID;
        state_special_reduction = { ...state.special_reduction, [casperID]: action.payload };
      }
      return {
        ...state,
        boardSupplaments: state.boardSupplaments,
        reduction: normalizedAddReduction.entities.reduction ? normalizedAddReduction.entities.reduction : state.reduction,
        special_reduction: state_special_reduction ? state_special_reduction : [],
      };

    case UPDATE_BOARD_SUPPLAMENT_REDUCTION:
      let updateReductionData = [];
      let state_special_reduction_edit = state.special_reduction;
      let indexUpdate = action.payload.reduction;
      updateReductionData['updateReduction'] = [indexUpdate];
      updateReductionData['stateReduction'] = state.reduction;
      const normalizedUpdateReduction = normalizeBoardSupplaments([updateReductionData]);
      if (!action.payload.reduction) {
        if (!action.payload.id) {
          state_special_reduction_edit = { ...state.special_reduction, [action.payload.casperID]: action.payload };
        } else {
          state_special_reduction_edit = { ...state.special_reduction, [action.payload.id]: action.payload };
        }
      }
      return {
        ...state,
        boardSupplaments: state.boardSupplaments,
        reduction: normalizedUpdateReduction.entities.reduction
          ? normalizedUpdateReduction.entities.reduction
          : state.reduction,
        special_reduction: state_special_reduction_edit,
      };

    case DELETE_BOARD_SUPPLAMENT_REDUCTION:
      let deleteJustReduction = state.reduction;
      if (action.payload.reduction) {
        let indexDelete = action.payload.reduction;
        delete deleteJustReduction[indexDelete];
      } else {
        delete state.special_reduction[action.payload];
      }
      return {
        ...state,
        boardSupplaments: state.boardSupplaments,
        reduction: deleteJustReduction,
        special_reduction: state.special_reduction,
      };
    case SPECIAL_REDUCTION_RESET:
      return { ...state, special_reduction: [] };
    default:
      return state;
  }
};
