import React, { useState, useEffect, lazy, Suspense } from 'react';
import MaterialTable, { MTableEditRow } from 'material-table';
import { getHotelService, deleteHotelService } from 'services/hotels';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedHotel } from 'redux/actions/hotels';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Button, Grid } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { companyHasOfficeMemo } from 'redux/selector/companyHasOfficeSelector';
import { getAdminCompaniesService } from 'services/company';
import _ from 'lodash';
import { useIntl } from 'react-intl';

const NewHotel = lazy(() => import('./NewHotel'));

const MySwal = withReactContent(Swal);

const theme = createTheme({
  palette: {
    backgroundColor: orange[400],
  },
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    '&:hover': {
      backgroundColor: orange[500],
    },
  },
}))(Button);
export default function HotelTable({ isAuthAdd, isAuthUpdate, isAuthDelete }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    if (stateCompanyHasOffice.length === 0) {
      dispatch(getAdminCompaniesService());
    }
    if (Object.values(hotels).length === 0) {
      dispatch(getHotelService());
    }
  }, [dispatch]);

  const stateCompanyHasOffice = useSelector(companyHasOfficeMemo);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { contracts } = useSelector(({ contract }) => contract);
  const { authUser } = useSelector(({ auth }) => auth);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let companyDestination = {};
    let mergeCompany = {};
    let filteredHotel = [];

    if (hotels.length != 0) {
      //Enterprise user ve Company companynin company level userı bütün otelleri görüntüleyebilir
      if (authUser.authority_level == 10 || authUser.authority_level == 11) {
        if (Object.values(stateCompanyHasOffice.offices).length != 0) {
          setFilteredData(Object.values(hotels));
        }
      }
      //Enterprise companynin , company seviyesindeki user seçmiş olduğu company (sideBarda yazılı olan) sadece onun destinationa ait kayıtlı olan hotelleri görebilir
      else if (authUser.authority_level == 20) {
        if (stateCompanyHasOffice.users[authUser.id]) {
          stateCompanyHasOffice.companies[parseInt(localStorage.getItem('selectedCompany'))].offices.map(element => {
            if (stateCompanyHasOffice.users[authUser.id].pivot_office.includes(element)) {
              if (companyDestination[0]) {
                mergeCompany = _.merge(
                  companyDestination[0],
                  JSON.parse(stateCompanyHasOffice.offices[element].get_destination.destinations),
                );
                companyDestination[0] = mergeCompany;
              } else {
                companyDestination[0] = JSON.parse(stateCompanyHasOffice.offices[element].get_destination.destinations);
              }
            }
          });

          filteredHotel = Object.values(hotels).filter(
            element =>
              companyDestination[0]['regionID'][element.region_id] &&
              companyDestination[0]['regionID'][element.region_id]['countryID'][element.country_id],
          );
          setFilteredData(filteredHotel);
        }
      }
      //Office seviyesindeki userın seçmiş olduğu officein destinatonlarına ait olan hoteller gelecek
      else if (authUser.authority_level === 30) {
        if (stateCompanyHasOffice.users[authUser.id]) {
          companyDestination[0] = JSON.parse(
            stateCompanyHasOffice.offices[parseInt(localStorage.getItem('selectedOffice'))]['get_destination'][
              'destinations'
            ],
          );
          filteredHotel = Object.values(hotels).filter(
            element =>
              companyDestination[0]['regionID'][element.region_id] &&
              companyDestination[0]['regionID'][element.region_id]['countryID'][element.country_id],
          );
          setFilteredData(filteredHotel);
        }
      }
    }
  }, [hotels, stateCompanyHasOffice]);

  const onRowUpdate = rowData => {
    setOpenModal(true);
    dispatch(setSelectedHotel(rowData.id));
  };

  const [openModal, setOpenModal] = useState(false);
  //To activate the add button
  const addButtonActive = () => {
    setOpenModal(true);
    dispatch(setSelectedHotel([]));
  };

  const hasHotel = hotel_id => {
    //To check if the hotel is in the contract
    var has = true;

    Object.values(contracts).map(contract => {
      if (contract.hotel_id === hotel_id) {
        has = false;
      }
    });
    return has;
  };

  const tableColumns = [
    {
      title: <IntlMessages id="product.name" />,
      field: 'name',
    },
    {
      title: <IntlMessages id="product.type" />,
      field: 'product_type.name',
    },
    {
      title: <IntlMessages id="product.code" />,
      field: 'hotel_code',
    },
    {
      title: <IntlMessages id="company" />,
      field: 'company',
    },
    {
      title: <IntlMessages id="tax.no" />,
      field: 'tax_no',
    },
    {
      title: <IntlMessages id="tax.office" />,
      field: 'tax_office',
    },
    {
      title: <IntlMessages id="region" />,
      field: 'region.name',
    },
    {
      title: <IntlMessages id="country" />,
      field: 'country.name',
    },
    {
      title: <IntlMessages id="city" />,
      field: 'city.name',
    },
    {
      title: <IntlMessages id="sub.city" />,
      field: 'sub_city.name',
    },
    {
      title: <IntlMessages id="district" />,
      field: 'district',
    },
    {
      title: <IntlMessages id="phone" />,
      field: 'tel',
    },
    {
      title: <IntlMessages id="fax" />,
      field: 'fax',
    },
    {
      title: <IntlMessages id="email" />,
      field: 'e_mail',
    },
    {
      title: <IntlMessages id="website" />,
      field: 'web_page',
    },
    {
      title: <IntlMessages id="star.rate" />,
      field: 'star_rate.value',
    },
    {
      title: <IntlMessages id="giata.code" />,
      field: 'giata_codes.giata_id',
      render: rowData => {
        return rowData.giata_codes.hotel_name + '(' + rowData.giata_codes.giata_id + ')';
      },
    },
    {
      title: <IntlMessages id="airport.code" />,
      field: 'airport_codes.code',
    },
  ];
  return (
    <Grid>
      <Suspense fallback={<div>Loading...</div>}>
        <NewHotel openModal={openModal} setOpenModal={setOpenModal} />
      </Suspense>
      <MaterialTable
        columns={tableColumns}
        data={filteredData}
        title=""
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40],
          search: false,
          actionsColumnIndex: -1,
          addRowPosition: 'first',
        }}
        actions={[
          {
            icon: 'edit',
            tooltip: isAuthUpdate ? 'Edit Row' : 'You are not authorized',
            disabled: isAuthUpdate ? false : true,
            onClick: (event, rowData) => {
              onRowUpdate(rowData);
            },
          },

          {
            icon: () => (
              <ThemeProvider theme={theme}>
                <ColorButton variant="contained" color="backgroundColor" startIcon={<AddBoxIcon />}>
                  <IntlMessages id="add" />
                </ColorButton>
              </ThemeProvider>
            ),
            tooltip: isAuthAdd ? 'Add Row' : 'You are not authorized',
            disabled: isAuthAdd ? false : true,
            position: 'toolbar',
            onClick: () => {
              addButtonActive();
            },
          },
        ]}
        editable={{
          deleteTooltip: row => (isAuthDelete ? 'Delete' : 'You are not authorized'),
          isDeletable: row => (isAuthDelete ? true : false),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                if (hasHotel(oldData.id)) {
                  dispatch(deleteHotelService(oldData.id)).then(res => {
                    if (res == 201) {
                      MySwal.fire({
                        icon: 'success',
                        text: intl.formatMessage({ id: 'hotel' }) + intl.formatMessage({ id: 'deleted.successfully' }),
                      });
                    }
                  });
                } else {
                  MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: intl.formatMessage({ id: 'hotel' }) + intl.formatMessage({ id: 'is.in.use' }),
                  });
                }
                resolve();
              }, 1000);
            }),
        }}
      />
    </Grid>
  );
}
