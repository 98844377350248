import CmtAvatar from '@coremat/CmtAvatar';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCardFooter from '@coremat/CmtCard/CmtCardFooter';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import { Box, Button, Grid } from '@material-ui/core';
import { Typography } from 'antd';
import React from 'react';

function CalculatedResCard({ calculatedObj }) {
  return (
    <Grid container spacing={12} style={{ marginTop: 15 }}>
      <Grid item xs={12}>
        <CmtCard>
          <CmtCardHeader
            avatar={<CmtAvatar src={'/images/avatar/avatar3.jpg'} size="large" alt="Avatar" />}
            title={'Calculated Reservation'}
            subTitle={'April 27, 2022'}></CmtCardHeader>
          <CmtCardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              Hotel Name : {calculatedObj.hotel_name} // Region : {calculatedObj.region_name}
              // Country :{calculatedObj.country_name} // Room : Single Standart Room
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Net Price : {calculatedObj.net_price + ' ' + 'EUR'} // Gross Price : {calculatedObj.gross_price + ' ' + 'EUR'}{' '}
              // Discount : {calculatedObj.discount + ' ' + 'EUR'}
            </Typography>
          </CmtCardContent>
          <CmtCardFooter separator={{ color: '#bdbdbd', borderWidth: 1, borderStyle: 'solid' }}>
            <Box display="flex" alignItems="center" width={1}>
              <Box mr={4}>
                <Button variant="contained" color="primary">
                  Show Other Rooms
                </Button>
              </Box>
            </Box>
          </CmtCardFooter>
        </CmtCard>
      </Grid>
    </Grid>
  );
}

export default CalculatedResCard;
