import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Grid } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { addUserPermissionService } from 'services/userPermission';
import { userMemo, authUserMemo } from 'redux/selector/companyHasOfficeSelector';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';

const MySwal = withReactContent(Swal);

function Row(props) {
  const intl = useIntl();
  const authortiyLevel = useSelector(authUserMemo);
  const permissionStateSelector = useSelector(state => state.companyHasOffice.entities.get_permission);

  const filterPermissionState = Object.values(permissionStateSelector).filter(
    suppRed => suppRed.user_id == props.rowData.id,
  );

  const dispatch = useDispatch();

  const [permissionState, setPermissionState] = useState(filterPermissionState);
  const [total, setTotal] = useState({ 0: 1, 1: 1, 2: 1, 3: 1, 4: 1 });

  useEffect(() => {
    let getTotal = { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1 };
    let sub = 1;
    filterPermissionState.map(element => {
      sub = 1;
      if (element.delete) {
        sub++;
      }
      if (element.put) {
        sub++;
      }
      if (element.post) {
        sub++;
      }
      getTotal[element.route_id - 1] = sub;
    });
    setTotal(getTotal);
  }, [permissionStateSelector]);

  const routes = {
    1: { name: 'accounting', placeholder: <IntlMessages id="accounting" /> },
    2: { name: 'contract', placeholder: <IntlMessages id="contracting" /> },
    3: { name: 'product', placeholder: <IntlMessages id="product" /> },
    4: { name: 'reservation', placeholder: <IntlMessages id="reservation" /> },
    5: { name: 'yield', placeholder: <IntlMessages id="yield" /> },
  };

  const handleChange = event => {
    let keys = event.target.name;
    let ids = event.target.id - 1;

    if (keys !== 'get' && keys !== 'all') {
      if (event.target.checked) {
        setTotal({ ...total, [ids]: total[ids] + 1 });
      } else {
        setTotal({ ...total, [ids]: total[ids] - 1 });
      }
      setPermissionState({
        ...permissionState,
        [ids]: {
          ...permissionState[ids],
          [keys]: event.target.checked ? 1 : 0,
          ['get']: permissionState[ids]['get'] ? permissionState[ids]['get'] : event.target.checked ? 1 : 0,
        },
      });
    } else if (keys == 'all') {
      if (event.target.checked) {
        setTotal({ ...total, [ids]: 4 });
      } else {
        setTotal({ ...total, [ids]: 1 });
      }
      setPermissionState({
        ...permissionState,
        [ids]: {
          ...permissionState[ids],
          ['get']: event.target.checked ? 1 : 0,
          ['post']: event.target.checked ? 1 : 0,
          ['put']: event.target.checked ? 1 : 0,
          ['delete']: event.target.checked ? 1 : 0,
        },
      });
    } else {
      if (event.target.checked) {
        setPermissionState({
          ...permissionState,
          [ids]: { ...permissionState[ids], [keys]: 1 },
        });
      } else {
        setTotal({ ...total, [ids]: 1 });
        setPermissionState({
          ...permissionState,
          [ids]: {
            ...permissionState[ids],
            [keys]: event.target.checked ? 1 : 0,
            ['post']: 0,
            ['put']: 0,
            ['delete']: 0,
          },
        });
      }
    }
    // setPermissionState([ ...permissionState, [ids]=>[ ...permissionState[ids], [keys] => [] event.target.checked ] ]);
  };

  const submitChanges = () => {
    dispatch(addUserPermissionService(permissionState));
  };

  return (
    <>
      <Box pt={5} pb={5} xs={{ flexGrow: 1, display: 'flex' }}>
        <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
          <Grid item xs={1}></Grid>
          {filterPermissionState.map(elements => {
            return (
              <Grid item xs={2}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {routes[elements.route_id]?.placeholder}{' '}
                    <FormControlLabel
                      disabled={authortiyLevel == 20}
                      control={
                        <Switch
                          id={elements.route_id}
                          checked={total[elements.route_id - 1] == 4}
                          onChange={handleChange}
                          name="all"
                          color="primary"
                        />
                      }
                      label={<IntlMessages id="all" />}
                    />
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      disabled={authortiyLevel == 20}
                      control={
                        <Switch
                          id={elements.route_id}
                          checked={permissionState[elements.route_id - 1].get}
                          onChange={handleChange}
                          name="get"
                          color="primary"
                        />
                      }
                      label={<IntlMessages id="view.permissions" />}
                    />
                    <FormControlLabel
                      disabled={authortiyLevel == 20}
                      control={
                        <Switch
                          id={elements.route_id}
                          checked={permissionState[elements.route_id - 1].post}
                          onChange={handleChange}
                          name="post"
                          color="primary"
                        />
                      }
                      label={<IntlMessages id="add.permissions" />}
                    />
                    <FormControlLabel
                      disabled={authortiyLevel == 20}
                      control={
                        <Switch
                          id={elements.route_id}
                          checked={permissionState[elements.route_id - 1].put}
                          onChange={handleChange}
                          name="put"
                          color="primary"
                        />
                      }
                      label={<IntlMessages id="update.permissions" />}
                    />
                    <FormControlLabel
                      disabled={authortiyLevel == 20}
                      control={
                        <Switch
                          id={elements.route_id}
                          checked={permissionState[elements.route_id - 1].delete}
                          onChange={handleChange}
                          name="delete"
                          color="primary"
                        />
                      }
                      label={<IntlMessages id="delete.permissions" />}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            );
          })}

          <Grid item xs={1}>
            <Box pt={15}>
              <Button
                hidden={authortiyLevel == 20}
                variant="contained"
                color="primary"
                onClick={submitChanges}
                startIcon={<SaveIcon />}>
                <IntlMessages id="save" />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default function UserPermission() {
  const userData = useSelector(userMemo);

  const [columnsBoard, setColumnsBoard] = useState([
    {
      title: <IntlMessages id="name" />,
      field: 'fullname',
      type: 'string',
      validate: rowData => (rowData.fullname ? true : false),
    },
    {
      title: <IntlMessages id="email" />,
      field: 'email',
      type: 'mail',
      validate: rowData => (rowData.email ? true : false),
    },
  ]);

  return (
    <MaterialTable
      columns={columnsBoard}
      data={userData}
      detailPanel={rowData => <Row rowData={rowData} />}
      options={{
        showTitle: false,
        pageSize: 10,
        pageSizeOptions: [10, 20, 30, 40],
        addRowPosition: 'first',
      }}
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
    />
  );
}
